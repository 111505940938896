export function toString(str: any, fallback: string = ""): string {
  if (!str) return fallback;
  if (typeof str === "string") return str;
  return str.toString();
}

export function truncate(
  str: string | undefined | null,
  chars: number = 100
): string {
  str = toString(str);

  if (str.length > chars) {
    return str.substring(0, chars) + "...";
  }

  return str;
}

export function pluralize(
  singular: string,
  plural: string,
  count: number
): string {
  return count === 1 ? singular : plural;
}
