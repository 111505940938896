import * as Sentry from "@sentry/react";
import { lazy, Suspense } from "react";
import { Navigate, Route, Routes } from "react-router-dom";

import { Dashboard } from "./components/Dashboard";
import { Fallback } from "./components/Fallback";
import { PrivateRoute } from "./components/PrivateRoute";

const ListCompaniesRoute = lazy(() => import("./routes/ListCompaniesRoute"));
const ListOrdersRoute = lazy(() => import("./routes/ListOrdersRoute"));
const LoginRoute = lazy(() => import("./routes/LoginRoute"));
const LogoutRoute = lazy(() => import("./routes/LogoutRoute"));
const NewCompanyRoute = lazy(() => import("./routes/NewCompanyRoute"));
const NewOrderRoute = lazy(() => import("./routes/NewOrderRoute"));
const EditOrderRoute = lazy(() => import("./routes/EditOrderRoute"));
const PrintOrderRoute = lazy(() => import("./routes/PrintOrderRoute"));
const ShedsRoute = lazy(() => import("./routes/ShedsRoute"));
const EditCompanyRoute = lazy(() => import("./routes/EditCompanyRoute"));

const App = () => {
  return (
    <Suspense fallback={<Fallback />}>
      <Routes>
        <Route path="/login" element={<LoginRoute />} />
        <Route path="/logout" element={<LogoutRoute />} />
        <PrivateRoute path="/orders/:id/pdf" element={<PrintOrderRoute />} />
        <PrivateRoute path="/orders/:id/print" element={<PrintOrderRoute />} />
        <PrivateRoute path="*" element={<Dashboard />}>
          <Route path="/orders">
            <Route element={<ListOrdersRoute />} />
            <Route path=":id/edit" element={<EditOrderRoute />} />
            <Route path="new" element={<NewOrderRoute />} />
          </Route>
          <Route path="/sheds" element={<ShedsRoute />} />
          <Route path="/companies">
            <Route element={<ListCompaniesRoute />} />
            <Route path="new" element={<NewCompanyRoute />} />
            <Route path=":id/edit" element={<EditCompanyRoute />} />
          </Route>

          <Route element={<Navigate to="/orders" />} />
        </PrivateRoute>
      </Routes>
    </Suspense>
  );
};

export default Sentry.withProfiler(App);
