import { FormControl } from "baseui/form-control";
import { Input } from "baseui/input";
import { useDebounce } from "use-debounce";
import { useState } from "react";

import { useFormContext } from "./FormContext";
import { useSkipFirstRunEffect } from "../../hooks/useSkipFirstRunEffect";

export const UnitsField: React.FC = () => {
  const { order, update } = useFormContext();
  const [value, setValue] = useState<string | number>(() => order.units ?? "");
  const [debouncedValue] = useDebounce(value, 1000);

  useSkipFirstRunEffect(() => {
    const units = Number(debouncedValue);

    if (isNaN(units)) return;

    update({
      variables: {
        data: { units: { set: units } },
        where: { id: order.id },
      },
      optimisticResponse: {
        order: {
          ...order,
          units,
        },
      },
    });
  }, [debouncedValue]);

  return (
    <FormControl label="Cantidad de unidades">
      <Input
        inputMode="numeric"
        min={0}
        name="units"
        onChange={(e) => setValue(e.currentTarget.value)}
        step={1}
        type="number"
        value={value}
      />
    </FormControl>
  );
};
