import { EffectCallback, useEffect, useRef } from "react";

export const useSkipFirstRunEffect = (
  callback: EffectCallback,
  deps: any[]
) => {
  const firstRun = useRef(true);

  useEffect(() => {
    if (firstRun.current) {
      firstRun.current = false;
      return;
    }
    callback();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, deps);
};
