import { FormControl } from "baseui/form-control";
import { Textarea } from "baseui/textarea";
import { useDebounce } from "use-debounce/lib";
import { useState } from "react";

import { useFormContext } from "./FormContext";
import { useSkipFirstRunEffect } from "../../hooks/useSkipFirstRunEffect";

export const ReceiptsField: React.FC = () => {
  const { order, update } = useFormContext();
  const [value, setValue] = useState<string>(() => order.receipts.join("\n"));
  const [debounced] = useDebounce(value, 1000);

  useSkipFirstRunEffect(() => {
    console.log("Updating receipts");

    update({
      variables: {
        data: { receipts: debounced.split("\n") },
        where: { id: order.id },
      },
      optimisticResponse: {
        order: {
          ...order,
          receipts: debounced.split("\n"),
        },
      },
    });
  }, [debounced]);

  return (
    <FormControl
      label="Remitos de carga"
      caption="Cada remito debe ir en una linea nueva"
    >
      <Textarea
        value={value}
        onChange={(e) => setValue(e.currentTarget.value)}
      />
    </FormControl>
  );
};
