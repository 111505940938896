import { OrderStatus, ShedFragment } from "../generated/graphql";

export function computeDistance(directions: google.maps.DirectionsResult) {
  const distance = directions.routes[0].legs.reduce(
    (acc, leg) => acc + leg.distance.value,
    0
  );

  return Math.round(distance / 1000); // in kilometers
}

export function getDirections(
  request: google.maps.DirectionsRequest
): Promise<google.maps.DirectionsResult> {
  return new Promise((resolve, reject) => {
    const service = new google.maps.DirectionsService();
    service.route(request, (directions, status) => {
      if (status !== google.maps.DirectionsStatus.OK) {
        return reject();
      }
      resolve(directions);
    });
  });
}

export function shedToLatLng(shed: ShedFragment) {
  return new google.maps.LatLng(shed.latitude, shed.longitude);
}

export function getWaypointsFromDirections(
  directions?: google.maps.DirectionsResult
) {
  const latlng: google.maps.DirectionsWaypoint[] = [];
  const waypoints = (directions as any)?.request.waypoints;

  if (Array.isArray(waypoints)) {
    waypoints.forEach(({ location, stopover }: any) => {
      if ("location" in location) {
        location = location.location;
      }

      const lat = location.lat();
      const lng = location.lng();

      latlng.push({ location: new google.maps.LatLng(lat, lng), stopover });
    });
  }

  return latlng;
}

export function numOrUndef(value: any) {
  if (!value) return undefined;

  if (typeof value === "number") {
    return value;
  }

  if (typeof value === "string") {
    value = Number(value);
  }

  return isNaN(value) ? undefined : value;
}

export function translateOrderStatus(status?: OrderStatus | null): string {
  if (!status) return "Desconocido";

  switch (status) {
    case OrderStatus.Assigned:
      return "En tránsito";
    case OrderStatus.Completed:
      return "Completado";
    case OrderStatus.Pending:
      return "Pendiente";
    case OrderStatus.Canceled:
      return "Anulado";
  }

  return "Desconocido";
}
