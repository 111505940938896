import "./index.css";

import * as Sentry from "@sentry/react";
import { ApolloProvider } from "@apollo/client";
import { BaseProvider, LightTheme, LocaleProvider } from "baseui";
import { BrowserRouter } from "react-router-dom";
import { Integrations } from "@sentry/tracing";
import { Client as Styletron } from "styletron-engine-atomic";
import { Provider as StyletronProvider } from "styletron-react";
import { render } from "react-dom";

import App from "./App";
import locale from "./locale";
import reportWebVitals from "./reportWebVitals";
import { AuthProvider } from "./AuthContext";
import { DEV } from "./constants";
import { client } from "./apollo";

if (!DEV) {
  Sentry.init({
    dsn:
      "https://0b8996d828cb4e31be96bb9297ef9251@o73874.ingest.sentry.io/5604200",
    autoSessionTracking: true,
    integrations: [new Integrations.BrowserTracing()],

    // We recommend adjusting this value in production, or using tracesSampler
    // for finer control
    tracesSampleRate: 1.0,
  });
}

const engine = new Styletron();

render(
  <BrowserRouter>
    <ApolloProvider client={client}>
      <StyletronProvider value={engine}>
        <BaseProvider theme={LightTheme}>
          <LocaleProvider locale={locale}>
            <AuthProvider>
              <App />
            </AuthProvider>
          </LocaleProvider>
        </BaseProvider>
      </StyletronProvider>
    </ApolloProvider>
  </BrowserRouter>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
