import { Navigate, Route, useLocation } from "react-router-dom";

import { useAuth } from "../AuthContext";

export interface PrivateRouteProps {
  caseSensitive?: boolean;
  children?: React.ReactNode;
  element?: React.ReactElement | null;
  path?: string;
}

export const PrivateRoute: React.FC<PrivateRouteProps> = (props) => {
  const { isLoggedIn } = useAuth();
  const location = useLocation();

  if (!isLoggedIn) {
    return (
      <Navigate
        to="/login"
        state={{ redirectTo: `${location.pathname}${location.search}` }}
      />
    );
  }

  return <Route {...props} />;
};
