import md5 from "blueimp-md5";
import { Link, NavLink } from "react-router-dom";
import { useMemo } from "react";

import s from "./Sidebar.module.css";
import { ReactComponent as SvgBriefcase } from "../icons/briefcase.svg";
import { ReactComponent as SvgLogout } from "../icons/logout.svg";
import { ReactComponent as SvgMap } from "../icons/map.svg";
import { ReactComponent as SvgShoppingBag } from "../icons/shopping-bag.svg";
import { toString } from "../../lib/string";
import { useAuth } from "../../AuthContext";

export const Sidebar: React.FC = () => {
  const { currentUser } = useAuth();

  const hash = useMemo(() => {
    const email = toString(currentUser?.email, "");
    return md5(email);
  }, [currentUser?.email]);

  return (
    <aside className={s.sidebar}>
      <nav className={s.nav}>
        <NavLink activeClassName={s.active} to="/orders">
          <SvgShoppingBag />
          Viajes
        </NavLink>

        <NavLink activeClassName={s.active} to="/companies">
          <SvgBriefcase />
          Empresas
        </NavLink>

        <NavLink activeClassName={s.active} to="/sheds">
          <SvgMap />
          Galpones
        </NavLink>
      </nav>

      <ul className={s.quickfilter}>
        <li>Filtro rápido</li>
        <li>
          <Link to="/orders/?status=CANCELED">Anulados</Link>
        </li>
        <li>
          <Link to="/orders/?status=PENDING">Pendientes</Link>
        </li>
        <li>
          <Link to="/orders/?status=ASSIGNED">En Tránsito</Link>
        </li>
        <li>
          <Link to="/orders/?status=COMPLETED">Completados</Link>
        </li>
      </ul>

      {currentUser && (
        <footer className={s.footer}>
          <div className={s.user}>
            <img
              src={`https://www.gravatar.com/avatar/${hash}`}
              className={s.avatar}
              alt="Avatar"
            />
            <span className={s.email}>{currentUser.email}</span>
          </div>
          <Link className={s.logout} to="/logout">
            <SvgLogout />
          </Link>
        </footer>
      )}
    </aside>
  );
};
