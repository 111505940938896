import {
  CategoricalColumn,
  DatetimeColumn,
  NumericalColumn,
  RowActionT,
  StatefulDataTable,
  StringColumn,
} from "baseui/data-table";
import {
  Modal,
  ModalBody,
  ModalButton,
  ModalFooter,
  ModalHeader,
  ROLE,
  SIZE,
} from "baseui/modal";
import { SVGProps, useEffect, useState } from "react";

import { Fallback } from "../components/Fallback";
import { FormContext } from "./OrderForm/FormContext";
import {
  OrderAddedDocument,
  OrderChangedDocument,
  OrderFragment,
  OrderStatus,
  SortOrder,
  useFindManyOrderQuery,
} from "../generated/graphql";
import { ReceiptsField } from "./OrderForm/ReceiptsField";
import { ReactComponent as SvgPencil } from "../components/icons/pencil-alt.svg";
import { ReactComponent as SvgPrinter } from "../components/icons/printer.svg";
import { UnitsField } from "./OrderForm/UnitsField";
import { WeightField } from "./OrderForm/WeightField";
import { translateOrderStatus } from "../lib/helpers";

const wrapIcon = (Icon: React.FunctionComponent<SVGProps<SVGSVGElement>>) => ({
  size,
  color,
}: {
  size: number | string;
  color: string;
}) => {
  return <Icon style={{ width: size, height: size, color }} />;
};

const columns = [
  StringColumn({
    title: "ID",
    mapDataToValue: (data: OrderFragment) => "#" + String(data.id),
  }),
  CategoricalColumn({
    title: "Estado",
    mapDataToValue: (data: OrderFragment) => translateOrderStatus(data.status),
  }),
  DatetimeColumn({
    title: "Fecha",
    mapDataToValue: (data: OrderFragment) => new Date(data.requestedAt),
  }),
  StringColumn({
    title: "Chofer",
    fillWidth: true,
    mapDataToValue: (data: OrderFragment) =>
      data.carriedBy?.name ?? "Sin Asignar",
  }),
  CategoricalColumn({
    title: "T. Camión",
    mapDataToValue: (data: OrderFragment) => data.truckType ?? "Sin Asignar",
  }),
  CategoricalColumn({
    title: "T. Carga",
    mapDataToValue: (data: OrderFragment) => data.cargoType ?? "Sin Asignar",
  }),
  CategoricalColumn({
    title: "Origen",
    fillWidth: true,
    mapDataToValue: (data: OrderFragment) => data.origin?.name ?? "Sin Asignar",
  }),
  CategoricalColumn({
    title: "Destino",
    fillWidth: true,
    mapDataToValue: (data: OrderFragment) =>
      data.destination?.name ?? "Sin Asignar",
  }),
  NumericalColumn({
    title: "Distancia",
    mapDataToValue: (data: OrderFragment) => data.distance ?? 0,
  }),
  CategoricalColumn({
    title: "Rango",
    fillWidth: true,
    mapDataToValue: (data: OrderFragment) => data.range ?? 0,
  }),
  NumericalColumn({
    title: "Unidades",
    mapDataToValue: (data: OrderFragment) => data.units ?? 0,
  }),
  NumericalColumn({
    title: "Peso",
    mapDataToValue: (data: OrderFragment) => data.weight ?? 0,
  }),
  StringColumn({
    title: "Remitos de carga",
    mapDataToValue: (data: OrderFragment) => data.receipts?.join(" | "),
  }),
  StringColumn({
    title: "Comentarios",
    mapDataToValue: (data: OrderFragment) => data.comments ?? "",
  }),
  CategoricalColumn({
    title: "Cliente",
    mapDataToValue: (data: OrderFragment) =>
      data.requestedBy?.name ?? "Sin Asignar",
  }),
];

function makeRows(
  orders?: Array<OrderFragment> | null
): Array<{ data: OrderFragment; id: number }> {
  if (!orders) return [];
  return orders.map((data) => ({
    id: data.id,
    data,
  }));
}

export const SlimDashboard = () => {
  const [editOrder, setEditOrder] = useState<OrderFragment | null>(null);

  const { data, loading, subscribeToMore } = useFindManyOrderQuery({
    variables: {
      where: { status: { in: [OrderStatus.Assigned, OrderStatus.Completed] } },
      orderBy: { id: SortOrder.Desc },
    },
  });

  useEffect(() => {
    subscribeToMore({
      document: OrderAddedDocument,
      updateQuery(prev, { subscriptionData }: any) {
        if (subscriptionData.data.orderAdded) {
          return {
            ...prev,
            orders: [subscriptionData.data.orderAdded, ...prev.orders],
          };
        }
        return prev;
      },
    });

    subscribeToMore({
      document: OrderChangedDocument,
      updateQuery(prev, { subscriptionData }: any) {
        if (subscriptionData.data.orderChanged) {
          if (subscriptionData.data.orderChanged?.id === editOrder?.id) {
            return prev;
          }

          const newOrder = subscriptionData.data.orderChanged as OrderFragment;
          return {
            ...prev,
            orders: prev.orders.map((order) =>
              order.id === newOrder.id ? newOrder : order
            ),
          };
        }
        return prev;
      },
    });
  }, [subscribeToMore, editOrder]);

  if (loading) {
    return <Fallback />;
  }

  const rowActions: RowActionT[] = [
    {
      label: "Editar",
      onClick: ({ row }) => {
        setEditOrder(row.data);
      },
      renderIcon: wrapIcon(SvgPencil),
    },
    {
      label: "Imprimir",
      onClick: ({ row }) => {
        const { data } = row;
        window.open(`/orders/${data.id}/pdf`, "_blank");
      },
      renderIcon: wrapIcon(SvgPrinter),
    },
  ];

  return (
    <>
      <div className="w-screen h-screen overflow-hidden">
        <div className="h-full p-8 space-y-8">
          <div style={{ height: "100%" }}>
            <StatefulDataTable
              loading={loading}
              columns={columns}
              rows={makeRows(data?.orders)}
              rowActions={rowActions}
            />
          </div>
        </div>
      </div>
      <Modal
        onClose={() => setEditOrder(null)}
        closeable
        isOpen={Boolean(editOrder)}
        animate
        autoFocus
        size={SIZE.default}
        role={ROLE.dialog}
        unstable_ModalBackdropScroll
      >
        <ModalHeader>Editar orden</ModalHeader>
        <ModalBody>
          <UpdateForm order={editOrder} />
        </ModalBody>
        <ModalFooter>
          <ModalButton onClick={() => setEditOrder(null)}>Listo</ModalButton>
        </ModalFooter>
      </Modal>
    </>
  );
};

const UpdateForm: React.FC<{
  order: OrderFragment | null;
}> = ({ order }) => {
  return (
    <FormContext.Provider value={order as OrderFragment}>
      <div className="space-y-4">
        <div className="grid grid-cols-2 gap-4">
          <div>
            <UnitsField />
          </div>

          <div>
            <WeightField />
          </div>
        </div>

        <ReceiptsField />
      </div>
    </FormContext.Provider>
  );
};
