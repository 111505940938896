import { createContext, useContext, useMemo } from "react";

import { Fallback } from "./components/Fallback";
import { UserFragment, useMeQuery } from "./generated/graphql";

const ONE_MINUTE = 60 * 1000;

type AuthContextValue =
  | {
      isLoggedIn: true;
      currentUser: UserFragment;
    }
  | {
      isLoggedIn: false;
      currentUser: null;
    };

export const AuthContext = createContext<AuthContextValue>({
  isLoggedIn: false,
  currentUser: null,
});

export const AuthProvider: React.FC = ({ children }) => {
  const { data, loading } = useMeQuery({
    pollInterval: ONE_MINUTE,
    fetchPolicy: "network-only",
  });

  const value: AuthContextValue = useMemo(() => {
    return data?.me
      ? { isLoggedIn: true, currentUser: data.me }
      : { isLoggedIn: false, currentUser: null };
  }, [data]);

  if (loading) {
    return <Fallback />;
  }

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};

export function useAuth() {
  return useContext(AuthContext);
}
