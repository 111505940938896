import { createContext, useContext } from "react";

import {
  OrderFragment,
  useUpdateOneOrderMutation,
} from "../../generated/graphql";

export const FormContext = createContext<OrderFragment>({
  id: -1,
} as OrderFragment);

export const useFormContext = () => {
  const order = useContext(FormContext);
  const [update] = useUpdateOneOrderMutation();
  return { order, update };
};
