import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  /** A field whose value conforms to the standard internet email address format as specified in RFC822: https://www.w3.org/Protocols/rfc822/. */
  EmailAddress: any;
  /** Json custom scalar type */
  Json: any;
  /** Decimal custom scalar type */
  Decimal: any;
  /** Date custom scalar type */
  DateTime: any;
};

export type Bill = {
  __typename?: 'Bill';
  id: Scalars['Int'];
  date: Scalars['DateTime'];
  items: Array<Invoice>;
  companyId: Scalars['Int'];
  company: Company;
  amount: Scalars['Float'];
  vat: Scalars['Float'];
  payments: Array<Payment>;
};


export type BillItemsArgs = {
  where?: Maybe<InvoiceWhereInput>;
  orderBy?: Maybe<InvoiceOrderByInput>;
  cursor?: Maybe<InvoiceWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<InvoiceScalarFieldEnum>;
};


export type BillPaymentsArgs = {
  where?: Maybe<PaymentWhereInput>;
  orderBy?: Maybe<PaymentOrderByInput>;
  cursor?: Maybe<PaymentWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<PaymentScalarFieldEnum>;
};

export type Company = {
  __typename?: 'Company';
  id: Scalars['Int'];
  name: Scalars['String'];
  cuit: Scalars['String'];
  bills: Array<Bill>;
  invoices: Array<Invoice>;
  employees: Array<Employee>;
  requested: Array<Order>;
  assigned: Array<Order>;
  payments: Array<Payment>;
  isCustomer: Scalars['Boolean'];
  isSupplier: Scalars['Boolean'];
  type: CompanyType;
};


export type CompanyEmployeesArgs = {
  where?: Maybe<EmployeeWhereInput>;
  orderBy?: Maybe<EmployeeOrderByInput>;
  cursor?: Maybe<EmployeeWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<EmployeeScalarFieldEnum>;
};


export type CompanyRequestedArgs = {
  where?: Maybe<OrderWhereInput>;
  orderBy?: Maybe<OrderOrderByInput>;
  cursor?: Maybe<OrderWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<OrderScalarFieldEnum>;
};


export type CompanyAssignedArgs = {
  where?: Maybe<OrderWhereInput>;
  orderBy?: Maybe<OrderOrderByInput>;
  cursor?: Maybe<OrderWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<OrderScalarFieldEnum>;
};


export type CompanyPaymentsArgs = {
  where?: Maybe<PaymentWhereInput>;
  orderBy?: Maybe<PaymentOrderByInput>;
  cursor?: Maybe<PaymentWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<PaymentScalarFieldEnum>;
};

export type Employee = {
  __typename?: 'Employee';
  id: Scalars['Int'];
  name: Scalars['String'];
  dni: Scalars['String'];
  worksFor: Company;
  companyId: Scalars['Int'];
  assigned: Array<Order>;
  status: EmployeeStatus;
};


export type EmployeeAssignedArgs = {
  where?: Maybe<OrderWhereInput>;
  orderBy?: Maybe<OrderOrderByInput>;
  cursor?: Maybe<OrderWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<OrderScalarFieldEnum>;
};

export type Invoice = {
  __typename?: 'Invoice';
  id: Scalars['Int'];
  date: Scalars['DateTime'];
  items: Array<Order>;
  total: Scalars['Float'];
  companyId: Scalars['Int'];
  company: Company;
  bill?: Maybe<Bill>;
  billId?: Maybe<Scalars['Int']>;
};


export type InvoiceItemsArgs = {
  where?: Maybe<OrderWhereInput>;
  orderBy?: Maybe<OrderOrderByInput>;
  cursor?: Maybe<OrderWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<OrderScalarFieldEnum>;
};

export type Order = {
  __typename?: 'Order';
  id: Scalars['Int'];
  assignedAt?: Maybe<Scalars['DateTime']>;
  cargoType?: Maybe<CargoType>;
  comments?: Maybe<Scalars['String']>;
  completedAt?: Maybe<Scalars['DateTime']>;
  distance?: Maybe<Scalars['Int']>;
  ourRate?: Maybe<Scalars['Float']>;
  range?: Maybe<Scalars['String']>;
  ourTotal?: Maybe<Scalars['Float']>;
  theirTotal?: Maybe<Scalars['Float']>;
  receipts: Array<Scalars['String']>;
  requestedAt?: Maybe<Scalars['DateTime']>;
  status?: Maybe<OrderStatus>;
  theirRate?: Maybe<Scalars['Float']>;
  truckType?: Maybe<TruckType>;
  units?: Maybe<Scalars['Float']>;
  waypoints?: Maybe<Array<Waypoint>>;
  weight?: Maybe<Scalars['Float']>;
  assignedTo?: Maybe<Company>;
  assignedToId?: Maybe<Scalars['Int']>;
  carriedBy?: Maybe<Employee>;
  carriedById?: Maybe<Scalars['Int']>;
  destination?: Maybe<Shed>;
  destinationId?: Maybe<Scalars['Int']>;
  invoices: Array<Invoice>;
  origin?: Maybe<Shed>;
  originId?: Maybe<Scalars['Int']>;
  requestedBy?: Maybe<Company>;
  requestedById?: Maybe<Scalars['Int']>;
};

export type Payment = {
  __typename?: 'Payment';
  id: Scalars['Int'];
  date: Scalars['DateTime'];
  source: Company;
  sourceId: Scalars['Int'];
  amount: Scalars['Float'];
  reference?: Maybe<Bill>;
  referenceId?: Maybe<Scalars['Int']>;
};


export type Shed = {
  __typename?: 'Shed';
  id: Scalars['Int'];
  name: Scalars['String'];
  latitude: Scalars['Float'];
  longitude: Scalars['Float'];
  asOrigin: Array<Order>;
  asDestination: Array<Order>;
};


export type ShedAsOriginArgs = {
  where?: Maybe<OrderWhereInput>;
  orderBy?: Maybe<OrderOrderByInput>;
  cursor?: Maybe<OrderWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<OrderScalarFieldEnum>;
};


export type ShedAsDestinationArgs = {
  where?: Maybe<OrderWhereInput>;
  orderBy?: Maybe<OrderOrderByInput>;
  cursor?: Maybe<OrderWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<OrderScalarFieldEnum>;
};

export type User = {
  __typename?: 'User';
  id: Scalars['String'];
  email: Scalars['String'];
  password: Scalars['String'];
  role: Role;
  status: Status;
};

export type Waypoint = {
  __typename?: 'Waypoint';
  stopover?: Maybe<Scalars['Boolean']>;
  location: Location;
};

export type Location = {
  __typename?: 'Location';
  lat: Scalars['Float'];
  lng: Scalars['Float'];
};

export type BatchPayload = {
  __typename?: 'BatchPayload';
  count: Scalars['Int'];
};




export enum UserScalarFieldEnum {
  Id = 'id',
  Email = 'email',
  Password = 'password',
  Role = 'role',
  Status = 'status'
}

export enum CompanyScalarFieldEnum {
  Id = 'id',
  Name = 'name',
  Cuit = 'cuit',
  IsCustomer = 'isCustomer',
  IsSupplier = 'isSupplier',
  Type = 'type'
}

export enum EmployeeScalarFieldEnum {
  Id = 'id',
  Name = 'name',
  Dni = 'dni',
  CompanyId = 'companyId',
  Status = 'status'
}

export enum OrderScalarFieldEnum {
  Id = 'id',
  AssignedAt = 'assignedAt',
  CargoType = 'cargoType',
  Comments = 'comments',
  CompletedAt = 'completedAt',
  Distance = 'distance',
  OurRate = 'ourRate',
  Range = 'range',
  Receipts = 'receipts',
  RequestedAt = 'requestedAt',
  Status = 'status',
  TheirRate = 'theirRate',
  TruckType = 'truckType',
  Units = 'units',
  Waypoints = 'waypoints',
  Weight = 'weight',
  AssignedToId = 'assignedToId',
  CarriedById = 'carriedById',
  DestinationId = 'destinationId',
  OriginId = 'originId',
  RequestedById = 'requestedById'
}

export enum InvoiceScalarFieldEnum {
  Id = 'id',
  Date = 'date',
  CompanyId = 'companyId',
  Total = 'total',
  BillId = 'billId'
}

export enum BillScalarFieldEnum {
  Id = 'id',
  Date = 'date',
  CompanyId = 'companyId',
  Amount = 'amount',
  Vat = 'vat'
}

export enum PaymentScalarFieldEnum {
  Id = 'id',
  Date = 'date',
  SourceId = 'sourceId',
  Amount = 'amount',
  ReferenceId = 'referenceId'
}

export enum ShedScalarFieldEnum {
  Id = 'id',
  Name = 'name',
  Latitude = 'latitude',
  Longitude = 'longitude'
}

export enum SortOrder {
  Asc = 'asc',
  Desc = 'desc'
}

export enum QueryMode {
  Default = 'default',
  Insensitive = 'insensitive'
}

export enum Role {
  Admin = 'ADMIN',
  User = 'USER'
}

export enum Status {
  Active = 'ACTIVE',
  Suspended = 'SUSPENDED'
}

export enum CompanyType {
  Cliente = 'CLIENTE',
  Proveedor = 'PROVEEDOR'
}

export enum EmployeeStatus {
  Activo = 'ACTIVO',
  Inactivo = 'INACTIVO'
}

export enum CargoType {
  Bins = 'BINS',
  Embalado = 'EMBALADO',
  Fruta = 'FRUTA',
  Material = 'MATERIAL'
}

export enum OrderStatus {
  Pending = 'PENDING',
  Assigned = 'ASSIGNED',
  Completed = 'COMPLETED',
  Unknown = 'UNKNOWN',
  Canceled = 'CANCELED'
}

export enum TruckType {
  Acoplado = 'ACOPLADO',
  Balancin = 'BALANCIN',
  Chasis = 'CHASIS',
  Semiremolque = 'SEMIREMOLQUE'
}

export type UserWhereInput = {
  AND?: Maybe<Array<Maybe<UserWhereInput>>>;
  OR?: Maybe<Array<Maybe<UserWhereInput>>>;
  NOT?: Maybe<Array<Maybe<UserWhereInput>>>;
  id?: Maybe<StringFilter>;
  email?: Maybe<StringFilter>;
  password?: Maybe<StringFilter>;
  role?: Maybe<EnumRoleFilter>;
  status?: Maybe<EnumStatusFilter>;
};

export type UserOrderByInput = {
  id?: Maybe<SortOrder>;
  email?: Maybe<SortOrder>;
  password?: Maybe<SortOrder>;
  role?: Maybe<SortOrder>;
  status?: Maybe<SortOrder>;
};

export type UserWhereUniqueInput = {
  id?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
};

export type UserScalarWhereWithAggregatesInput = {
  AND?: Maybe<Array<Maybe<UserScalarWhereWithAggregatesInput>>>;
  OR?: Maybe<Array<Maybe<UserScalarWhereWithAggregatesInput>>>;
  NOT?: Maybe<Array<Maybe<UserScalarWhereWithAggregatesInput>>>;
  id?: Maybe<StringWithAggregatesFilter>;
  email?: Maybe<StringWithAggregatesFilter>;
  password?: Maybe<StringWithAggregatesFilter>;
  role?: Maybe<EnumRoleWithAggregatesFilter>;
  status?: Maybe<EnumStatusWithAggregatesFilter>;
};

export type CompanyWhereInput = {
  AND?: Maybe<Array<Maybe<CompanyWhereInput>>>;
  OR?: Maybe<Array<Maybe<CompanyWhereInput>>>;
  NOT?: Maybe<Array<Maybe<CompanyWhereInput>>>;
  id?: Maybe<IntFilter>;
  name?: Maybe<StringFilter>;
  cuit?: Maybe<StringFilter>;
  employees?: Maybe<EmployeeListRelationFilter>;
  requested?: Maybe<OrderListRelationFilter>;
  assigned?: Maybe<OrderListRelationFilter>;
  payments?: Maybe<PaymentListRelationFilter>;
  isCustomer?: Maybe<BoolFilter>;
  isSupplier?: Maybe<BoolFilter>;
  invoices?: Maybe<InvoiceListRelationFilter>;
  bills?: Maybe<BillListRelationFilter>;
  type?: Maybe<EnumCompanyTypeFilter>;
};

export type CompanyOrderByInput = {
  id?: Maybe<SortOrder>;
  name?: Maybe<SortOrder>;
  cuit?: Maybe<SortOrder>;
  isCustomer?: Maybe<SortOrder>;
  isSupplier?: Maybe<SortOrder>;
  type?: Maybe<SortOrder>;
};

export type CompanyWhereUniqueInput = {
  id?: Maybe<Scalars['Int']>;
  cuit?: Maybe<Scalars['String']>;
};

export type CompanyScalarWhereWithAggregatesInput = {
  AND?: Maybe<Array<Maybe<CompanyScalarWhereWithAggregatesInput>>>;
  OR?: Maybe<Array<Maybe<CompanyScalarWhereWithAggregatesInput>>>;
  NOT?: Maybe<Array<Maybe<CompanyScalarWhereWithAggregatesInput>>>;
  id?: Maybe<IntWithAggregatesFilter>;
  name?: Maybe<StringWithAggregatesFilter>;
  cuit?: Maybe<StringWithAggregatesFilter>;
  isCustomer?: Maybe<BoolWithAggregatesFilter>;
  isSupplier?: Maybe<BoolWithAggregatesFilter>;
  type?: Maybe<EnumCompanyTypeWithAggregatesFilter>;
};

export type EmployeeWhereInput = {
  AND?: Maybe<Array<Maybe<EmployeeWhereInput>>>;
  OR?: Maybe<Array<Maybe<EmployeeWhereInput>>>;
  NOT?: Maybe<Array<Maybe<EmployeeWhereInput>>>;
  id?: Maybe<IntFilter>;
  name?: Maybe<StringFilter>;
  dni?: Maybe<StringFilter>;
  worksFor?: Maybe<CompanyWhereInput>;
  companyId?: Maybe<IntFilter>;
  assigned?: Maybe<OrderListRelationFilter>;
  status?: Maybe<EnumEmployeeStatusFilter>;
};

export type EmployeeOrderByInput = {
  id?: Maybe<SortOrder>;
  name?: Maybe<SortOrder>;
  dni?: Maybe<SortOrder>;
  companyId?: Maybe<SortOrder>;
  status?: Maybe<SortOrder>;
};

export type EmployeeWhereUniqueInput = {
  id?: Maybe<Scalars['Int']>;
  dni?: Maybe<Scalars['String']>;
};

export type EmployeeScalarWhereWithAggregatesInput = {
  AND?: Maybe<Array<Maybe<EmployeeScalarWhereWithAggregatesInput>>>;
  OR?: Maybe<Array<Maybe<EmployeeScalarWhereWithAggregatesInput>>>;
  NOT?: Maybe<Array<Maybe<EmployeeScalarWhereWithAggregatesInput>>>;
  id?: Maybe<IntWithAggregatesFilter>;
  name?: Maybe<StringWithAggregatesFilter>;
  dni?: Maybe<StringWithAggregatesFilter>;
  companyId?: Maybe<IntWithAggregatesFilter>;
  status?: Maybe<EnumEmployeeStatusWithAggregatesFilter>;
};

export type OrderWhereInput = {
  AND?: Maybe<Array<Maybe<OrderWhereInput>>>;
  OR?: Maybe<Array<Maybe<OrderWhereInput>>>;
  NOT?: Maybe<Array<Maybe<OrderWhereInput>>>;
  id?: Maybe<IntFilter>;
  assignedAt?: Maybe<DateTimeNullableFilter>;
  cargoType?: Maybe<EnumCargoTypeNullableFilter>;
  comments?: Maybe<StringNullableFilter>;
  completedAt?: Maybe<DateTimeNullableFilter>;
  distance?: Maybe<IntNullableFilter>;
  ourRate?: Maybe<FloatNullableFilter>;
  range?: Maybe<StringNullableFilter>;
  receipts?: Maybe<StringNullableListFilter>;
  requestedAt?: Maybe<DateTimeNullableFilter>;
  status?: Maybe<EnumOrderStatusNullableFilter>;
  theirRate?: Maybe<FloatNullableFilter>;
  truckType?: Maybe<EnumTruckTypeNullableFilter>;
  units?: Maybe<FloatNullableFilter>;
  waypoints?: Maybe<JsonNullableFilter>;
  weight?: Maybe<FloatNullableFilter>;
  assignedTo?: Maybe<CompanyWhereInput>;
  assignedToId?: Maybe<IntNullableFilter>;
  carriedBy?: Maybe<EmployeeWhereInput>;
  carriedById?: Maybe<IntNullableFilter>;
  destination?: Maybe<ShedWhereInput>;
  destinationId?: Maybe<IntNullableFilter>;
  invoices?: Maybe<InvoiceListRelationFilter>;
  origin?: Maybe<ShedWhereInput>;
  originId?: Maybe<IntNullableFilter>;
  requestedBy?: Maybe<CompanyWhereInput>;
  requestedById?: Maybe<IntNullableFilter>;
};

export type OrderOrderByInput = {
  id?: Maybe<SortOrder>;
  assignedAt?: Maybe<SortOrder>;
  cargoType?: Maybe<SortOrder>;
  comments?: Maybe<SortOrder>;
  completedAt?: Maybe<SortOrder>;
  distance?: Maybe<SortOrder>;
  ourRate?: Maybe<SortOrder>;
  range?: Maybe<SortOrder>;
  receipts?: Maybe<SortOrder>;
  requestedAt?: Maybe<SortOrder>;
  status?: Maybe<SortOrder>;
  theirRate?: Maybe<SortOrder>;
  truckType?: Maybe<SortOrder>;
  units?: Maybe<SortOrder>;
  waypoints?: Maybe<SortOrder>;
  weight?: Maybe<SortOrder>;
  assignedToId?: Maybe<SortOrder>;
  carriedById?: Maybe<SortOrder>;
  destinationId?: Maybe<SortOrder>;
  originId?: Maybe<SortOrder>;
  requestedById?: Maybe<SortOrder>;
};

export type OrderWhereUniqueInput = {
  id?: Maybe<Scalars['Int']>;
};

export type OrderScalarWhereWithAggregatesInput = {
  AND?: Maybe<Array<Maybe<OrderScalarWhereWithAggregatesInput>>>;
  OR?: Maybe<Array<Maybe<OrderScalarWhereWithAggregatesInput>>>;
  NOT?: Maybe<Array<Maybe<OrderScalarWhereWithAggregatesInput>>>;
  id?: Maybe<IntWithAggregatesFilter>;
  assignedAt?: Maybe<DateTimeNullableWithAggregatesFilter>;
  cargoType?: Maybe<EnumCargoTypeNullableWithAggregatesFilter>;
  comments?: Maybe<StringNullableWithAggregatesFilter>;
  completedAt?: Maybe<DateTimeNullableWithAggregatesFilter>;
  distance?: Maybe<IntNullableWithAggregatesFilter>;
  ourRate?: Maybe<FloatNullableWithAggregatesFilter>;
  range?: Maybe<StringNullableWithAggregatesFilter>;
  receipts?: Maybe<StringNullableListFilter>;
  requestedAt?: Maybe<DateTimeNullableWithAggregatesFilter>;
  status?: Maybe<EnumOrderStatusNullableWithAggregatesFilter>;
  theirRate?: Maybe<FloatNullableWithAggregatesFilter>;
  truckType?: Maybe<EnumTruckTypeNullableWithAggregatesFilter>;
  units?: Maybe<FloatNullableWithAggregatesFilter>;
  waypoints?: Maybe<JsonNullableWithAggregatesFilter>;
  weight?: Maybe<FloatNullableWithAggregatesFilter>;
  assignedToId?: Maybe<IntNullableWithAggregatesFilter>;
  carriedById?: Maybe<IntNullableWithAggregatesFilter>;
  destinationId?: Maybe<IntNullableWithAggregatesFilter>;
  originId?: Maybe<IntNullableWithAggregatesFilter>;
  requestedById?: Maybe<IntNullableWithAggregatesFilter>;
};

export type InvoiceWhereInput = {
  AND?: Maybe<Array<Maybe<InvoiceWhereInput>>>;
  OR?: Maybe<Array<Maybe<InvoiceWhereInput>>>;
  NOT?: Maybe<Array<Maybe<InvoiceWhereInput>>>;
  id?: Maybe<IntFilter>;
  date?: Maybe<DateTimeFilter>;
  company?: Maybe<CompanyWhereInput>;
  companyId?: Maybe<IntFilter>;
  items?: Maybe<OrderListRelationFilter>;
  total?: Maybe<FloatFilter>;
  bill?: Maybe<BillWhereInput>;
  billId?: Maybe<IntNullableFilter>;
};

export type InvoiceOrderByInput = {
  id?: Maybe<SortOrder>;
  date?: Maybe<SortOrder>;
  companyId?: Maybe<SortOrder>;
  total?: Maybe<SortOrder>;
  billId?: Maybe<SortOrder>;
};

export type InvoiceWhereUniqueInput = {
  id?: Maybe<Scalars['Int']>;
};

export type InvoiceScalarWhereWithAggregatesInput = {
  AND?: Maybe<Array<Maybe<InvoiceScalarWhereWithAggregatesInput>>>;
  OR?: Maybe<Array<Maybe<InvoiceScalarWhereWithAggregatesInput>>>;
  NOT?: Maybe<Array<Maybe<InvoiceScalarWhereWithAggregatesInput>>>;
  id?: Maybe<IntWithAggregatesFilter>;
  date?: Maybe<DateTimeWithAggregatesFilter>;
  companyId?: Maybe<IntWithAggregatesFilter>;
  total?: Maybe<FloatWithAggregatesFilter>;
  billId?: Maybe<IntNullableWithAggregatesFilter>;
};

export type BillWhereInput = {
  AND?: Maybe<Array<Maybe<BillWhereInput>>>;
  OR?: Maybe<Array<Maybe<BillWhereInput>>>;
  NOT?: Maybe<Array<Maybe<BillWhereInput>>>;
  id?: Maybe<IntFilter>;
  date?: Maybe<DateTimeFilter>;
  company?: Maybe<CompanyWhereInput>;
  companyId?: Maybe<IntFilter>;
  items?: Maybe<InvoiceListRelationFilter>;
  amount?: Maybe<FloatFilter>;
  vat?: Maybe<FloatFilter>;
  payments?: Maybe<PaymentListRelationFilter>;
};

export type BillOrderByInput = {
  id?: Maybe<SortOrder>;
  date?: Maybe<SortOrder>;
  companyId?: Maybe<SortOrder>;
  amount?: Maybe<SortOrder>;
  vat?: Maybe<SortOrder>;
};

export type BillWhereUniqueInput = {
  id?: Maybe<Scalars['Int']>;
};

export type BillScalarWhereWithAggregatesInput = {
  AND?: Maybe<Array<Maybe<BillScalarWhereWithAggregatesInput>>>;
  OR?: Maybe<Array<Maybe<BillScalarWhereWithAggregatesInput>>>;
  NOT?: Maybe<Array<Maybe<BillScalarWhereWithAggregatesInput>>>;
  id?: Maybe<IntWithAggregatesFilter>;
  date?: Maybe<DateTimeWithAggregatesFilter>;
  companyId?: Maybe<IntWithAggregatesFilter>;
  amount?: Maybe<FloatWithAggregatesFilter>;
  vat?: Maybe<FloatWithAggregatesFilter>;
};

export type PaymentWhereInput = {
  AND?: Maybe<Array<Maybe<PaymentWhereInput>>>;
  OR?: Maybe<Array<Maybe<PaymentWhereInput>>>;
  NOT?: Maybe<Array<Maybe<PaymentWhereInput>>>;
  id?: Maybe<IntFilter>;
  date?: Maybe<DateTimeFilter>;
  source?: Maybe<CompanyWhereInput>;
  sourceId?: Maybe<IntFilter>;
  amount?: Maybe<FloatFilter>;
  reference?: Maybe<BillWhereInput>;
  referenceId?: Maybe<IntNullableFilter>;
};

export type PaymentOrderByInput = {
  id?: Maybe<SortOrder>;
  date?: Maybe<SortOrder>;
  sourceId?: Maybe<SortOrder>;
  amount?: Maybe<SortOrder>;
  referenceId?: Maybe<SortOrder>;
};

export type PaymentWhereUniqueInput = {
  id?: Maybe<Scalars['Int']>;
};

export type PaymentScalarWhereWithAggregatesInput = {
  AND?: Maybe<Array<Maybe<PaymentScalarWhereWithAggregatesInput>>>;
  OR?: Maybe<Array<Maybe<PaymentScalarWhereWithAggregatesInput>>>;
  NOT?: Maybe<Array<Maybe<PaymentScalarWhereWithAggregatesInput>>>;
  id?: Maybe<IntWithAggregatesFilter>;
  date?: Maybe<DateTimeWithAggregatesFilter>;
  sourceId?: Maybe<IntWithAggregatesFilter>;
  amount?: Maybe<FloatWithAggregatesFilter>;
  referenceId?: Maybe<IntNullableWithAggregatesFilter>;
};

export type ShedWhereInput = {
  AND?: Maybe<Array<Maybe<ShedWhereInput>>>;
  OR?: Maybe<Array<Maybe<ShedWhereInput>>>;
  NOT?: Maybe<Array<Maybe<ShedWhereInput>>>;
  id?: Maybe<IntFilter>;
  name?: Maybe<StringFilter>;
  latitude?: Maybe<FloatFilter>;
  longitude?: Maybe<FloatFilter>;
  asOrigin?: Maybe<OrderListRelationFilter>;
  asDestination?: Maybe<OrderListRelationFilter>;
};

export type ShedOrderByInput = {
  id?: Maybe<SortOrder>;
  name?: Maybe<SortOrder>;
  latitude?: Maybe<SortOrder>;
  longitude?: Maybe<SortOrder>;
};

export type ShedWhereUniqueInput = {
  id?: Maybe<Scalars['Int']>;
};

export type ShedScalarWhereWithAggregatesInput = {
  AND?: Maybe<Array<Maybe<ShedScalarWhereWithAggregatesInput>>>;
  OR?: Maybe<Array<Maybe<ShedScalarWhereWithAggregatesInput>>>;
  NOT?: Maybe<Array<Maybe<ShedScalarWhereWithAggregatesInput>>>;
  id?: Maybe<IntWithAggregatesFilter>;
  name?: Maybe<StringWithAggregatesFilter>;
  latitude?: Maybe<FloatWithAggregatesFilter>;
  longitude?: Maybe<FloatWithAggregatesFilter>;
};

export type UserCreateInput = {
  id?: Maybe<Scalars['String']>;
  email: Scalars['String'];
  password: Scalars['String'];
  role?: Maybe<Role>;
  status?: Maybe<Status>;
};

export type UserUncheckedCreateInput = {
  id?: Maybe<Scalars['String']>;
  email: Scalars['String'];
  password: Scalars['String'];
  role?: Maybe<Role>;
  status?: Maybe<Status>;
};

export type UserUpdateInput = {
  id?: Maybe<StringFieldUpdateOperationsInput>;
  email?: Maybe<StringFieldUpdateOperationsInput>;
  password?: Maybe<StringFieldUpdateOperationsInput>;
  role?: Maybe<EnumRoleFieldUpdateOperationsInput>;
  status?: Maybe<EnumStatusFieldUpdateOperationsInput>;
};

export type UserUncheckedUpdateInput = {
  id?: Maybe<StringFieldUpdateOperationsInput>;
  email?: Maybe<StringFieldUpdateOperationsInput>;
  password?: Maybe<StringFieldUpdateOperationsInput>;
  role?: Maybe<EnumRoleFieldUpdateOperationsInput>;
  status?: Maybe<EnumStatusFieldUpdateOperationsInput>;
};

export type UserUpdateManyMutationInput = {
  id?: Maybe<StringFieldUpdateOperationsInput>;
  email?: Maybe<StringFieldUpdateOperationsInput>;
  password?: Maybe<StringFieldUpdateOperationsInput>;
  role?: Maybe<EnumRoleFieldUpdateOperationsInput>;
  status?: Maybe<EnumStatusFieldUpdateOperationsInput>;
};

export type UserUncheckedUpdateManyInput = {
  id?: Maybe<StringFieldUpdateOperationsInput>;
  email?: Maybe<StringFieldUpdateOperationsInput>;
  password?: Maybe<StringFieldUpdateOperationsInput>;
  role?: Maybe<EnumRoleFieldUpdateOperationsInput>;
  status?: Maybe<EnumStatusFieldUpdateOperationsInput>;
};

export type CompanyCreateInput = {
  name: Scalars['String'];
  cuit: Scalars['String'];
  isCustomer?: Maybe<Scalars['Boolean']>;
  isSupplier?: Maybe<Scalars['Boolean']>;
  type?: Maybe<CompanyType>;
  employees?: Maybe<EmployeeCreateNestedManyWithoutWorksForInput>;
  requested?: Maybe<OrderCreateNestedManyWithoutRequestedByInput>;
  assigned?: Maybe<OrderCreateNestedManyWithoutAssignedToInput>;
  payments?: Maybe<PaymentCreateNestedManyWithoutSourceInput>;
  invoices?: Maybe<InvoiceCreateNestedManyWithoutCompanyInput>;
  bills?: Maybe<BillCreateNestedManyWithoutCompanyInput>;
};

export type CompanyUncheckedCreateInput = {
  id?: Maybe<Scalars['Int']>;
  name: Scalars['String'];
  cuit: Scalars['String'];
  isCustomer?: Maybe<Scalars['Boolean']>;
  isSupplier?: Maybe<Scalars['Boolean']>;
  type?: Maybe<CompanyType>;
  employees?: Maybe<EmployeeUncheckedCreateNestedManyWithoutWorksForInput>;
  requested?: Maybe<OrderUncheckedCreateNestedManyWithoutRequestedByInput>;
  assigned?: Maybe<OrderUncheckedCreateNestedManyWithoutAssignedToInput>;
  payments?: Maybe<PaymentUncheckedCreateNestedManyWithoutSourceInput>;
  invoices?: Maybe<InvoiceUncheckedCreateNestedManyWithoutCompanyInput>;
  bills?: Maybe<BillUncheckedCreateNestedManyWithoutCompanyInput>;
};

export type CompanyUpdateInput = {
  name?: Maybe<StringFieldUpdateOperationsInput>;
  cuit?: Maybe<StringFieldUpdateOperationsInput>;
  isCustomer?: Maybe<BoolFieldUpdateOperationsInput>;
  isSupplier?: Maybe<BoolFieldUpdateOperationsInput>;
  type?: Maybe<EnumCompanyTypeFieldUpdateOperationsInput>;
  employees?: Maybe<EmployeeUpdateManyWithoutWorksForInput>;
  requested?: Maybe<OrderUpdateManyWithoutRequestedByInput>;
  assigned?: Maybe<OrderUpdateManyWithoutAssignedToInput>;
  payments?: Maybe<PaymentUpdateManyWithoutSourceInput>;
  invoices?: Maybe<InvoiceUpdateManyWithoutCompanyInput>;
  bills?: Maybe<BillUpdateManyWithoutCompanyInput>;
};

export type CompanyUncheckedUpdateInput = {
  id?: Maybe<IntFieldUpdateOperationsInput>;
  name?: Maybe<StringFieldUpdateOperationsInput>;
  cuit?: Maybe<StringFieldUpdateOperationsInput>;
  isCustomer?: Maybe<BoolFieldUpdateOperationsInput>;
  isSupplier?: Maybe<BoolFieldUpdateOperationsInput>;
  type?: Maybe<EnumCompanyTypeFieldUpdateOperationsInput>;
  employees?: Maybe<EmployeeUncheckedUpdateManyWithoutWorksForInput>;
  requested?: Maybe<OrderUncheckedUpdateManyWithoutRequestedByInput>;
  assigned?: Maybe<OrderUncheckedUpdateManyWithoutAssignedToInput>;
  payments?: Maybe<PaymentUncheckedUpdateManyWithoutSourceInput>;
  invoices?: Maybe<InvoiceUncheckedUpdateManyWithoutCompanyInput>;
  bills?: Maybe<BillUncheckedUpdateManyWithoutCompanyInput>;
};

export type CompanyUpdateManyMutationInput = {
  name?: Maybe<StringFieldUpdateOperationsInput>;
  cuit?: Maybe<StringFieldUpdateOperationsInput>;
  isCustomer?: Maybe<BoolFieldUpdateOperationsInput>;
  isSupplier?: Maybe<BoolFieldUpdateOperationsInput>;
  type?: Maybe<EnumCompanyTypeFieldUpdateOperationsInput>;
};

export type CompanyUncheckedUpdateManyInput = {
  id?: Maybe<IntFieldUpdateOperationsInput>;
  name?: Maybe<StringFieldUpdateOperationsInput>;
  cuit?: Maybe<StringFieldUpdateOperationsInput>;
  isCustomer?: Maybe<BoolFieldUpdateOperationsInput>;
  isSupplier?: Maybe<BoolFieldUpdateOperationsInput>;
  type?: Maybe<EnumCompanyTypeFieldUpdateOperationsInput>;
};

export type EmployeeCreateInput = {
  name: Scalars['String'];
  dni: Scalars['String'];
  status?: Maybe<EmployeeStatus>;
  worksFor: CompanyCreateNestedOneWithoutEmployeesInput;
  assigned?: Maybe<OrderCreateNestedManyWithoutCarriedByInput>;
};

export type EmployeeUncheckedCreateInput = {
  id?: Maybe<Scalars['Int']>;
  name: Scalars['String'];
  dni: Scalars['String'];
  companyId: Scalars['Int'];
  status?: Maybe<EmployeeStatus>;
  assigned?: Maybe<OrderUncheckedCreateNestedManyWithoutCarriedByInput>;
};

export type EmployeeUpdateInput = {
  name?: Maybe<StringFieldUpdateOperationsInput>;
  dni?: Maybe<StringFieldUpdateOperationsInput>;
  status?: Maybe<EnumEmployeeStatusFieldUpdateOperationsInput>;
  worksFor?: Maybe<CompanyUpdateOneRequiredWithoutEmployeesInput>;
  assigned?: Maybe<OrderUpdateManyWithoutCarriedByInput>;
};

export type EmployeeUncheckedUpdateInput = {
  id?: Maybe<IntFieldUpdateOperationsInput>;
  name?: Maybe<StringFieldUpdateOperationsInput>;
  dni?: Maybe<StringFieldUpdateOperationsInput>;
  companyId?: Maybe<IntFieldUpdateOperationsInput>;
  status?: Maybe<EnumEmployeeStatusFieldUpdateOperationsInput>;
  assigned?: Maybe<OrderUncheckedUpdateManyWithoutCarriedByInput>;
};

export type EmployeeUpdateManyMutationInput = {
  name?: Maybe<StringFieldUpdateOperationsInput>;
  dni?: Maybe<StringFieldUpdateOperationsInput>;
  status?: Maybe<EnumEmployeeStatusFieldUpdateOperationsInput>;
};

export type EmployeeUncheckedUpdateManyInput = {
  id?: Maybe<IntFieldUpdateOperationsInput>;
  name?: Maybe<StringFieldUpdateOperationsInput>;
  dni?: Maybe<StringFieldUpdateOperationsInput>;
  companyId?: Maybe<IntFieldUpdateOperationsInput>;
  status?: Maybe<EnumEmployeeStatusFieldUpdateOperationsInput>;
};

export type OrderCreateInput = {
  assignedAt?: Maybe<Scalars['DateTime']>;
  cargoType?: Maybe<CargoType>;
  comments?: Maybe<Scalars['String']>;
  completedAt?: Maybe<Scalars['DateTime']>;
  distance?: Maybe<Scalars['Int']>;
  ourRate?: Maybe<Scalars['Float']>;
  range?: Maybe<Scalars['String']>;
  requestedAt?: Maybe<Scalars['DateTime']>;
  status?: Maybe<OrderStatus>;
  theirRate?: Maybe<Scalars['Float']>;
  truckType?: Maybe<TruckType>;
  units?: Maybe<Scalars['Float']>;
  waypoints?: Maybe<Scalars['Json']>;
  weight?: Maybe<Scalars['Float']>;
  receipts?: Maybe<Array<Maybe<Scalars['String']>>>;
  assignedTo?: Maybe<CompanyCreateNestedOneWithoutAssignedInput>;
  carriedBy?: Maybe<EmployeeCreateNestedOneWithoutAssignedInput>;
  destination?: Maybe<ShedCreateNestedOneWithoutAsDestinationInput>;
  invoices?: Maybe<InvoiceCreateNestedManyWithoutItemsInput>;
  origin?: Maybe<ShedCreateNestedOneWithoutAsOriginInput>;
  requestedBy?: Maybe<CompanyCreateNestedOneWithoutRequestedInput>;
};

export type OrderUncheckedCreateInput = {
  id?: Maybe<Scalars['Int']>;
  assignedAt?: Maybe<Scalars['DateTime']>;
  cargoType?: Maybe<CargoType>;
  comments?: Maybe<Scalars['String']>;
  completedAt?: Maybe<Scalars['DateTime']>;
  distance?: Maybe<Scalars['Int']>;
  ourRate?: Maybe<Scalars['Float']>;
  range?: Maybe<Scalars['String']>;
  requestedAt?: Maybe<Scalars['DateTime']>;
  status?: Maybe<OrderStatus>;
  theirRate?: Maybe<Scalars['Float']>;
  truckType?: Maybe<TruckType>;
  units?: Maybe<Scalars['Float']>;
  waypoints?: Maybe<Scalars['Json']>;
  weight?: Maybe<Scalars['Float']>;
  assignedToId?: Maybe<Scalars['Int']>;
  carriedById?: Maybe<Scalars['Int']>;
  destinationId?: Maybe<Scalars['Int']>;
  originId?: Maybe<Scalars['Int']>;
  requestedById?: Maybe<Scalars['Int']>;
  receipts?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type OrderUpdateInput = {
  assignedAt?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  cargoType?: Maybe<NullableEnumCargoTypeFieldUpdateOperationsInput>;
  comments?: Maybe<NullableStringFieldUpdateOperationsInput>;
  completedAt?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  distance?: Maybe<NullableIntFieldUpdateOperationsInput>;
  ourRate?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  range?: Maybe<NullableStringFieldUpdateOperationsInput>;
  requestedAt?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  status?: Maybe<NullableEnumOrderStatusFieldUpdateOperationsInput>;
  theirRate?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  truckType?: Maybe<NullableEnumTruckTypeFieldUpdateOperationsInput>;
  units?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  waypoints?: Maybe<Scalars['Json']>;
  weight?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  receipts?: Maybe<Array<Maybe<Scalars['String']>>>;
  assignedTo?: Maybe<CompanyUpdateOneWithoutAssignedInput>;
  carriedBy?: Maybe<EmployeeUpdateOneWithoutAssignedInput>;
  destination?: Maybe<ShedUpdateOneWithoutAsDestinationInput>;
  invoices?: Maybe<InvoiceUpdateManyWithoutItemsInput>;
  origin?: Maybe<ShedUpdateOneWithoutAsOriginInput>;
  requestedBy?: Maybe<CompanyUpdateOneWithoutRequestedInput>;
};

export type OrderUncheckedUpdateInput = {
  id?: Maybe<IntFieldUpdateOperationsInput>;
  assignedAt?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  cargoType?: Maybe<NullableEnumCargoTypeFieldUpdateOperationsInput>;
  comments?: Maybe<NullableStringFieldUpdateOperationsInput>;
  completedAt?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  distance?: Maybe<NullableIntFieldUpdateOperationsInput>;
  ourRate?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  range?: Maybe<NullableStringFieldUpdateOperationsInput>;
  requestedAt?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  status?: Maybe<NullableEnumOrderStatusFieldUpdateOperationsInput>;
  theirRate?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  truckType?: Maybe<NullableEnumTruckTypeFieldUpdateOperationsInput>;
  units?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  waypoints?: Maybe<Scalars['Json']>;
  weight?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  assignedToId?: Maybe<NullableIntFieldUpdateOperationsInput>;
  carriedById?: Maybe<NullableIntFieldUpdateOperationsInput>;
  destinationId?: Maybe<NullableIntFieldUpdateOperationsInput>;
  originId?: Maybe<NullableIntFieldUpdateOperationsInput>;
  requestedById?: Maybe<NullableIntFieldUpdateOperationsInput>;
  receipts?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type OrderUpdateManyMutationInput = {
  assignedAt?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  cargoType?: Maybe<NullableEnumCargoTypeFieldUpdateOperationsInput>;
  comments?: Maybe<NullableStringFieldUpdateOperationsInput>;
  completedAt?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  distance?: Maybe<NullableIntFieldUpdateOperationsInput>;
  ourRate?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  range?: Maybe<NullableStringFieldUpdateOperationsInput>;
  requestedAt?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  status?: Maybe<NullableEnumOrderStatusFieldUpdateOperationsInput>;
  theirRate?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  truckType?: Maybe<NullableEnumTruckTypeFieldUpdateOperationsInput>;
  units?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  waypoints?: Maybe<Scalars['Json']>;
  weight?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  receipts?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type OrderUncheckedUpdateManyInput = {
  id?: Maybe<IntFieldUpdateOperationsInput>;
  assignedAt?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  cargoType?: Maybe<NullableEnumCargoTypeFieldUpdateOperationsInput>;
  comments?: Maybe<NullableStringFieldUpdateOperationsInput>;
  completedAt?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  distance?: Maybe<NullableIntFieldUpdateOperationsInput>;
  ourRate?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  range?: Maybe<NullableStringFieldUpdateOperationsInput>;
  requestedAt?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  status?: Maybe<NullableEnumOrderStatusFieldUpdateOperationsInput>;
  theirRate?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  truckType?: Maybe<NullableEnumTruckTypeFieldUpdateOperationsInput>;
  units?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  waypoints?: Maybe<Scalars['Json']>;
  weight?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  assignedToId?: Maybe<NullableIntFieldUpdateOperationsInput>;
  carriedById?: Maybe<NullableIntFieldUpdateOperationsInput>;
  destinationId?: Maybe<NullableIntFieldUpdateOperationsInput>;
  originId?: Maybe<NullableIntFieldUpdateOperationsInput>;
  requestedById?: Maybe<NullableIntFieldUpdateOperationsInput>;
  receipts?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type InvoiceCreateInput = {
  date?: Maybe<Scalars['DateTime']>;
  total: Scalars['Float'];
  company: CompanyCreateNestedOneWithoutInvoicesInput;
  items?: Maybe<OrderCreateNestedManyWithoutInvoicesInput>;
  bill?: Maybe<BillCreateNestedOneWithoutItemsInput>;
};

export type InvoiceUncheckedCreateInput = {
  id?: Maybe<Scalars['Int']>;
  date?: Maybe<Scalars['DateTime']>;
  companyId: Scalars['Int'];
  total: Scalars['Float'];
  billId?: Maybe<Scalars['Int']>;
};

export type InvoiceUpdateInput = {
  date?: Maybe<DateTimeFieldUpdateOperationsInput>;
  total?: Maybe<FloatFieldUpdateOperationsInput>;
  company?: Maybe<CompanyUpdateOneRequiredWithoutInvoicesInput>;
  items?: Maybe<OrderUpdateManyWithoutInvoicesInput>;
  bill?: Maybe<BillUpdateOneWithoutItemsInput>;
};

export type InvoiceUncheckedUpdateInput = {
  id?: Maybe<IntFieldUpdateOperationsInput>;
  date?: Maybe<DateTimeFieldUpdateOperationsInput>;
  companyId?: Maybe<IntFieldUpdateOperationsInput>;
  total?: Maybe<FloatFieldUpdateOperationsInput>;
  billId?: Maybe<NullableIntFieldUpdateOperationsInput>;
};

export type InvoiceUpdateManyMutationInput = {
  date?: Maybe<DateTimeFieldUpdateOperationsInput>;
  total?: Maybe<FloatFieldUpdateOperationsInput>;
};

export type InvoiceUncheckedUpdateManyInput = {
  id?: Maybe<IntFieldUpdateOperationsInput>;
  date?: Maybe<DateTimeFieldUpdateOperationsInput>;
  companyId?: Maybe<IntFieldUpdateOperationsInput>;
  total?: Maybe<FloatFieldUpdateOperationsInput>;
  billId?: Maybe<NullableIntFieldUpdateOperationsInput>;
};

export type BillCreateInput = {
  date?: Maybe<Scalars['DateTime']>;
  amount: Scalars['Float'];
  vat: Scalars['Float'];
  company: CompanyCreateNestedOneWithoutBillsInput;
  items?: Maybe<InvoiceCreateNestedManyWithoutBillInput>;
  payments?: Maybe<PaymentCreateNestedManyWithoutReferenceInput>;
};

export type BillUncheckedCreateInput = {
  id?: Maybe<Scalars['Int']>;
  date?: Maybe<Scalars['DateTime']>;
  companyId: Scalars['Int'];
  amount: Scalars['Float'];
  vat: Scalars['Float'];
  items?: Maybe<InvoiceUncheckedCreateNestedManyWithoutBillInput>;
  payments?: Maybe<PaymentUncheckedCreateNestedManyWithoutReferenceInput>;
};

export type BillUpdateInput = {
  date?: Maybe<DateTimeFieldUpdateOperationsInput>;
  amount?: Maybe<FloatFieldUpdateOperationsInput>;
  vat?: Maybe<FloatFieldUpdateOperationsInput>;
  company?: Maybe<CompanyUpdateOneRequiredWithoutBillsInput>;
  items?: Maybe<InvoiceUpdateManyWithoutBillInput>;
  payments?: Maybe<PaymentUpdateManyWithoutReferenceInput>;
};

export type BillUncheckedUpdateInput = {
  id?: Maybe<IntFieldUpdateOperationsInput>;
  date?: Maybe<DateTimeFieldUpdateOperationsInput>;
  companyId?: Maybe<IntFieldUpdateOperationsInput>;
  amount?: Maybe<FloatFieldUpdateOperationsInput>;
  vat?: Maybe<FloatFieldUpdateOperationsInput>;
  items?: Maybe<InvoiceUncheckedUpdateManyWithoutBillInput>;
  payments?: Maybe<PaymentUncheckedUpdateManyWithoutReferenceInput>;
};

export type BillUpdateManyMutationInput = {
  date?: Maybe<DateTimeFieldUpdateOperationsInput>;
  amount?: Maybe<FloatFieldUpdateOperationsInput>;
  vat?: Maybe<FloatFieldUpdateOperationsInput>;
};

export type BillUncheckedUpdateManyInput = {
  id?: Maybe<IntFieldUpdateOperationsInput>;
  date?: Maybe<DateTimeFieldUpdateOperationsInput>;
  companyId?: Maybe<IntFieldUpdateOperationsInput>;
  amount?: Maybe<FloatFieldUpdateOperationsInput>;
  vat?: Maybe<FloatFieldUpdateOperationsInput>;
};

export type PaymentCreateInput = {
  date?: Maybe<Scalars['DateTime']>;
  amount: Scalars['Float'];
  source: CompanyCreateNestedOneWithoutPaymentsInput;
  reference?: Maybe<BillCreateNestedOneWithoutPaymentsInput>;
};

export type PaymentUncheckedCreateInput = {
  id?: Maybe<Scalars['Int']>;
  date?: Maybe<Scalars['DateTime']>;
  sourceId: Scalars['Int'];
  amount: Scalars['Float'];
  referenceId?: Maybe<Scalars['Int']>;
};

export type PaymentUpdateInput = {
  date?: Maybe<DateTimeFieldUpdateOperationsInput>;
  amount?: Maybe<FloatFieldUpdateOperationsInput>;
  source?: Maybe<CompanyUpdateOneRequiredWithoutPaymentsInput>;
  reference?: Maybe<BillUpdateOneWithoutPaymentsInput>;
};

export type PaymentUncheckedUpdateInput = {
  id?: Maybe<IntFieldUpdateOperationsInput>;
  date?: Maybe<DateTimeFieldUpdateOperationsInput>;
  sourceId?: Maybe<IntFieldUpdateOperationsInput>;
  amount?: Maybe<FloatFieldUpdateOperationsInput>;
  referenceId?: Maybe<NullableIntFieldUpdateOperationsInput>;
};

export type PaymentUpdateManyMutationInput = {
  date?: Maybe<DateTimeFieldUpdateOperationsInput>;
  amount?: Maybe<FloatFieldUpdateOperationsInput>;
};

export type PaymentUncheckedUpdateManyInput = {
  id?: Maybe<IntFieldUpdateOperationsInput>;
  date?: Maybe<DateTimeFieldUpdateOperationsInput>;
  sourceId?: Maybe<IntFieldUpdateOperationsInput>;
  amount?: Maybe<FloatFieldUpdateOperationsInput>;
  referenceId?: Maybe<NullableIntFieldUpdateOperationsInput>;
};

export type ShedCreateInput = {
  name: Scalars['String'];
  latitude: Scalars['Float'];
  longitude: Scalars['Float'];
  asOrigin?: Maybe<OrderCreateNestedManyWithoutOriginInput>;
  asDestination?: Maybe<OrderCreateNestedManyWithoutDestinationInput>;
};

export type ShedUncheckedCreateInput = {
  id?: Maybe<Scalars['Int']>;
  name: Scalars['String'];
  latitude: Scalars['Float'];
  longitude: Scalars['Float'];
  asOrigin?: Maybe<OrderUncheckedCreateNestedManyWithoutOriginInput>;
  asDestination?: Maybe<OrderUncheckedCreateNestedManyWithoutDestinationInput>;
};

export type ShedUpdateInput = {
  name?: Maybe<StringFieldUpdateOperationsInput>;
  latitude?: Maybe<FloatFieldUpdateOperationsInput>;
  longitude?: Maybe<FloatFieldUpdateOperationsInput>;
  asOrigin?: Maybe<OrderUpdateManyWithoutOriginInput>;
  asDestination?: Maybe<OrderUpdateManyWithoutDestinationInput>;
};

export type ShedUncheckedUpdateInput = {
  id?: Maybe<IntFieldUpdateOperationsInput>;
  name?: Maybe<StringFieldUpdateOperationsInput>;
  latitude?: Maybe<FloatFieldUpdateOperationsInput>;
  longitude?: Maybe<FloatFieldUpdateOperationsInput>;
  asOrigin?: Maybe<OrderUncheckedUpdateManyWithoutOriginInput>;
  asDestination?: Maybe<OrderUncheckedUpdateManyWithoutDestinationInput>;
};

export type ShedUpdateManyMutationInput = {
  name?: Maybe<StringFieldUpdateOperationsInput>;
  latitude?: Maybe<FloatFieldUpdateOperationsInput>;
  longitude?: Maybe<FloatFieldUpdateOperationsInput>;
};

export type ShedUncheckedUpdateManyInput = {
  id?: Maybe<IntFieldUpdateOperationsInput>;
  name?: Maybe<StringFieldUpdateOperationsInput>;
  latitude?: Maybe<FloatFieldUpdateOperationsInput>;
  longitude?: Maybe<FloatFieldUpdateOperationsInput>;
};

export type StringFilter = {
  equals?: Maybe<Scalars['String']>;
  in?: Maybe<Array<Maybe<Scalars['String']>>>;
  notIn?: Maybe<Array<Maybe<Scalars['String']>>>;
  lt?: Maybe<Scalars['String']>;
  lte?: Maybe<Scalars['String']>;
  gt?: Maybe<Scalars['String']>;
  gte?: Maybe<Scalars['String']>;
  contains?: Maybe<Scalars['String']>;
  startsWith?: Maybe<Scalars['String']>;
  endsWith?: Maybe<Scalars['String']>;
  mode?: Maybe<QueryMode>;
  not?: Maybe<NestedStringFilter>;
};

export type EnumRoleFilter = {
  equals?: Maybe<Role>;
  in?: Maybe<Array<Maybe<Role>>>;
  notIn?: Maybe<Array<Maybe<Role>>>;
  not?: Maybe<NestedEnumRoleFilter>;
};

export type EnumStatusFilter = {
  equals?: Maybe<Status>;
  in?: Maybe<Array<Maybe<Status>>>;
  notIn?: Maybe<Array<Maybe<Status>>>;
  not?: Maybe<NestedEnumStatusFilter>;
};

export type StringWithAggregatesFilter = {
  equals?: Maybe<Scalars['String']>;
  in?: Maybe<Array<Maybe<Scalars['String']>>>;
  notIn?: Maybe<Array<Maybe<Scalars['String']>>>;
  lt?: Maybe<Scalars['String']>;
  lte?: Maybe<Scalars['String']>;
  gt?: Maybe<Scalars['String']>;
  gte?: Maybe<Scalars['String']>;
  contains?: Maybe<Scalars['String']>;
  startsWith?: Maybe<Scalars['String']>;
  endsWith?: Maybe<Scalars['String']>;
  mode?: Maybe<QueryMode>;
  not?: Maybe<NestedStringWithAggregatesFilter>;
  count?: Maybe<NestedIntFilter>;
  min?: Maybe<NestedStringFilter>;
  max?: Maybe<NestedStringFilter>;
};

export type EnumRoleWithAggregatesFilter = {
  equals?: Maybe<Role>;
  in?: Maybe<Array<Maybe<Role>>>;
  notIn?: Maybe<Array<Maybe<Role>>>;
  not?: Maybe<NestedEnumRoleWithAggregatesFilter>;
  count?: Maybe<NestedIntFilter>;
  min?: Maybe<NestedEnumRoleFilter>;
  max?: Maybe<NestedEnumRoleFilter>;
};

export type EnumStatusWithAggregatesFilter = {
  equals?: Maybe<Status>;
  in?: Maybe<Array<Maybe<Status>>>;
  notIn?: Maybe<Array<Maybe<Status>>>;
  not?: Maybe<NestedEnumStatusWithAggregatesFilter>;
  count?: Maybe<NestedIntFilter>;
  min?: Maybe<NestedEnumStatusFilter>;
  max?: Maybe<NestedEnumStatusFilter>;
};

export type IntFilter = {
  equals?: Maybe<Scalars['Int']>;
  in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  notIn?: Maybe<Array<Maybe<Scalars['Int']>>>;
  lt?: Maybe<Scalars['Int']>;
  lte?: Maybe<Scalars['Int']>;
  gt?: Maybe<Scalars['Int']>;
  gte?: Maybe<Scalars['Int']>;
  not?: Maybe<NestedIntFilter>;
};

export type EmployeeListRelationFilter = {
  every?: Maybe<EmployeeWhereInput>;
  some?: Maybe<EmployeeWhereInput>;
  none?: Maybe<EmployeeWhereInput>;
};

export type OrderListRelationFilter = {
  every?: Maybe<OrderWhereInput>;
  some?: Maybe<OrderWhereInput>;
  none?: Maybe<OrderWhereInput>;
};

export type PaymentListRelationFilter = {
  every?: Maybe<PaymentWhereInput>;
  some?: Maybe<PaymentWhereInput>;
  none?: Maybe<PaymentWhereInput>;
};

export type BoolFilter = {
  equals?: Maybe<Scalars['Boolean']>;
  not?: Maybe<NestedBoolFilter>;
};

export type InvoiceListRelationFilter = {
  every?: Maybe<InvoiceWhereInput>;
  some?: Maybe<InvoiceWhereInput>;
  none?: Maybe<InvoiceWhereInput>;
};

export type BillListRelationFilter = {
  every?: Maybe<BillWhereInput>;
  some?: Maybe<BillWhereInput>;
  none?: Maybe<BillWhereInput>;
};

export type EnumCompanyTypeFilter = {
  equals?: Maybe<CompanyType>;
  in?: Maybe<Array<Maybe<CompanyType>>>;
  notIn?: Maybe<Array<Maybe<CompanyType>>>;
  not?: Maybe<NestedEnumCompanyTypeFilter>;
};

export type IntWithAggregatesFilter = {
  equals?: Maybe<Scalars['Int']>;
  in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  notIn?: Maybe<Array<Maybe<Scalars['Int']>>>;
  lt?: Maybe<Scalars['Int']>;
  lte?: Maybe<Scalars['Int']>;
  gt?: Maybe<Scalars['Int']>;
  gte?: Maybe<Scalars['Int']>;
  not?: Maybe<NestedIntWithAggregatesFilter>;
  count?: Maybe<NestedIntFilter>;
  avg?: Maybe<NestedFloatFilter>;
  sum?: Maybe<NestedIntFilter>;
  min?: Maybe<NestedIntFilter>;
  max?: Maybe<NestedIntFilter>;
};

export type BoolWithAggregatesFilter = {
  equals?: Maybe<Scalars['Boolean']>;
  not?: Maybe<NestedBoolWithAggregatesFilter>;
  count?: Maybe<NestedIntFilter>;
  min?: Maybe<NestedBoolFilter>;
  max?: Maybe<NestedBoolFilter>;
};

export type EnumCompanyTypeWithAggregatesFilter = {
  equals?: Maybe<CompanyType>;
  in?: Maybe<Array<Maybe<CompanyType>>>;
  notIn?: Maybe<Array<Maybe<CompanyType>>>;
  not?: Maybe<NestedEnumCompanyTypeWithAggregatesFilter>;
  count?: Maybe<NestedIntFilter>;
  min?: Maybe<NestedEnumCompanyTypeFilter>;
  max?: Maybe<NestedEnumCompanyTypeFilter>;
};

export type CompanyRelationFilter = {
  is?: Maybe<CompanyWhereInput>;
  isNot?: Maybe<CompanyWhereInput>;
};

export type EnumEmployeeStatusFilter = {
  equals?: Maybe<EmployeeStatus>;
  in?: Maybe<Array<Maybe<EmployeeStatus>>>;
  notIn?: Maybe<Array<Maybe<EmployeeStatus>>>;
  not?: Maybe<NestedEnumEmployeeStatusFilter>;
};

export type EnumEmployeeStatusWithAggregatesFilter = {
  equals?: Maybe<EmployeeStatus>;
  in?: Maybe<Array<Maybe<EmployeeStatus>>>;
  notIn?: Maybe<Array<Maybe<EmployeeStatus>>>;
  not?: Maybe<NestedEnumEmployeeStatusWithAggregatesFilter>;
  count?: Maybe<NestedIntFilter>;
  min?: Maybe<NestedEnumEmployeeStatusFilter>;
  max?: Maybe<NestedEnumEmployeeStatusFilter>;
};

export type DateTimeNullableFilter = {
  equals?: Maybe<Scalars['DateTime']>;
  in?: Maybe<Array<Maybe<Scalars['DateTime']>>>;
  notIn?: Maybe<Array<Maybe<Scalars['DateTime']>>>;
  lt?: Maybe<Scalars['DateTime']>;
  lte?: Maybe<Scalars['DateTime']>;
  gt?: Maybe<Scalars['DateTime']>;
  gte?: Maybe<Scalars['DateTime']>;
  not?: Maybe<NestedDateTimeNullableFilter>;
};

export type EnumCargoTypeNullableFilter = {
  equals?: Maybe<CargoType>;
  in?: Maybe<Array<Maybe<CargoType>>>;
  notIn?: Maybe<Array<Maybe<CargoType>>>;
  not?: Maybe<NestedEnumCargoTypeNullableFilter>;
};

export type StringNullableFilter = {
  equals?: Maybe<Scalars['String']>;
  in?: Maybe<Array<Maybe<Scalars['String']>>>;
  notIn?: Maybe<Array<Maybe<Scalars['String']>>>;
  lt?: Maybe<Scalars['String']>;
  lte?: Maybe<Scalars['String']>;
  gt?: Maybe<Scalars['String']>;
  gte?: Maybe<Scalars['String']>;
  contains?: Maybe<Scalars['String']>;
  startsWith?: Maybe<Scalars['String']>;
  endsWith?: Maybe<Scalars['String']>;
  mode?: Maybe<QueryMode>;
  not?: Maybe<NestedStringNullableFilter>;
};

export type IntNullableFilter = {
  equals?: Maybe<Scalars['Int']>;
  in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  notIn?: Maybe<Array<Maybe<Scalars['Int']>>>;
  lt?: Maybe<Scalars['Int']>;
  lte?: Maybe<Scalars['Int']>;
  gt?: Maybe<Scalars['Int']>;
  gte?: Maybe<Scalars['Int']>;
  not?: Maybe<NestedIntNullableFilter>;
};

export type FloatNullableFilter = {
  equals?: Maybe<Scalars['Float']>;
  in?: Maybe<Array<Maybe<Scalars['Float']>>>;
  notIn?: Maybe<Array<Maybe<Scalars['Float']>>>;
  lt?: Maybe<Scalars['Float']>;
  lte?: Maybe<Scalars['Float']>;
  gt?: Maybe<Scalars['Float']>;
  gte?: Maybe<Scalars['Float']>;
  not?: Maybe<NestedFloatNullableFilter>;
};

export type StringNullableListFilter = {
  equals?: Maybe<Array<Maybe<Scalars['String']>>>;
  has?: Maybe<Scalars['String']>;
  hasEvery?: Maybe<Array<Maybe<Scalars['String']>>>;
  hasSome?: Maybe<Array<Maybe<Scalars['String']>>>;
  isEmpty?: Maybe<Scalars['Boolean']>;
};

export type EnumOrderStatusNullableFilter = {
  equals?: Maybe<OrderStatus>;
  in?: Maybe<Array<Maybe<OrderStatus>>>;
  notIn?: Maybe<Array<Maybe<OrderStatus>>>;
  not?: Maybe<NestedEnumOrderStatusNullableFilter>;
};

export type EnumTruckTypeNullableFilter = {
  equals?: Maybe<TruckType>;
  in?: Maybe<Array<Maybe<TruckType>>>;
  notIn?: Maybe<Array<Maybe<TruckType>>>;
  not?: Maybe<NestedEnumTruckTypeNullableFilter>;
};

export type JsonNullableFilter = {
  equals?: Maybe<Scalars['Json']>;
  not?: Maybe<Scalars['Json']>;
};

export type EmployeeRelationFilter = {
  is?: Maybe<EmployeeWhereInput>;
  isNot?: Maybe<EmployeeWhereInput>;
};

export type ShedRelationFilter = {
  is?: Maybe<ShedWhereInput>;
  isNot?: Maybe<ShedWhereInput>;
};

export type DateTimeNullableWithAggregatesFilter = {
  equals?: Maybe<Scalars['DateTime']>;
  in?: Maybe<Array<Maybe<Scalars['DateTime']>>>;
  notIn?: Maybe<Array<Maybe<Scalars['DateTime']>>>;
  lt?: Maybe<Scalars['DateTime']>;
  lte?: Maybe<Scalars['DateTime']>;
  gt?: Maybe<Scalars['DateTime']>;
  gte?: Maybe<Scalars['DateTime']>;
  not?: Maybe<NestedDateTimeNullableWithAggregatesFilter>;
  count?: Maybe<NestedIntNullableFilter>;
  min?: Maybe<NestedDateTimeNullableFilter>;
  max?: Maybe<NestedDateTimeNullableFilter>;
};

export type EnumCargoTypeNullableWithAggregatesFilter = {
  equals?: Maybe<CargoType>;
  in?: Maybe<Array<Maybe<CargoType>>>;
  notIn?: Maybe<Array<Maybe<CargoType>>>;
  not?: Maybe<NestedEnumCargoTypeNullableWithAggregatesFilter>;
  count?: Maybe<NestedIntNullableFilter>;
  min?: Maybe<NestedEnumCargoTypeNullableFilter>;
  max?: Maybe<NestedEnumCargoTypeNullableFilter>;
};

export type StringNullableWithAggregatesFilter = {
  equals?: Maybe<Scalars['String']>;
  in?: Maybe<Array<Maybe<Scalars['String']>>>;
  notIn?: Maybe<Array<Maybe<Scalars['String']>>>;
  lt?: Maybe<Scalars['String']>;
  lte?: Maybe<Scalars['String']>;
  gt?: Maybe<Scalars['String']>;
  gte?: Maybe<Scalars['String']>;
  contains?: Maybe<Scalars['String']>;
  startsWith?: Maybe<Scalars['String']>;
  endsWith?: Maybe<Scalars['String']>;
  mode?: Maybe<QueryMode>;
  not?: Maybe<NestedStringNullableWithAggregatesFilter>;
  count?: Maybe<NestedIntNullableFilter>;
  min?: Maybe<NestedStringNullableFilter>;
  max?: Maybe<NestedStringNullableFilter>;
};

export type IntNullableWithAggregatesFilter = {
  equals?: Maybe<Scalars['Int']>;
  in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  notIn?: Maybe<Array<Maybe<Scalars['Int']>>>;
  lt?: Maybe<Scalars['Int']>;
  lte?: Maybe<Scalars['Int']>;
  gt?: Maybe<Scalars['Int']>;
  gte?: Maybe<Scalars['Int']>;
  not?: Maybe<NestedIntNullableWithAggregatesFilter>;
  count?: Maybe<NestedIntNullableFilter>;
  avg?: Maybe<NestedFloatNullableFilter>;
  sum?: Maybe<NestedIntNullableFilter>;
  min?: Maybe<NestedIntNullableFilter>;
  max?: Maybe<NestedIntNullableFilter>;
};

export type FloatNullableWithAggregatesFilter = {
  equals?: Maybe<Scalars['Float']>;
  in?: Maybe<Array<Maybe<Scalars['Float']>>>;
  notIn?: Maybe<Array<Maybe<Scalars['Float']>>>;
  lt?: Maybe<Scalars['Float']>;
  lte?: Maybe<Scalars['Float']>;
  gt?: Maybe<Scalars['Float']>;
  gte?: Maybe<Scalars['Float']>;
  not?: Maybe<NestedFloatNullableWithAggregatesFilter>;
  count?: Maybe<NestedIntNullableFilter>;
  avg?: Maybe<NestedFloatNullableFilter>;
  sum?: Maybe<NestedFloatNullableFilter>;
  min?: Maybe<NestedFloatNullableFilter>;
  max?: Maybe<NestedFloatNullableFilter>;
};

export type EnumOrderStatusNullableWithAggregatesFilter = {
  equals?: Maybe<OrderStatus>;
  in?: Maybe<Array<Maybe<OrderStatus>>>;
  notIn?: Maybe<Array<Maybe<OrderStatus>>>;
  not?: Maybe<NestedEnumOrderStatusNullableWithAggregatesFilter>;
  count?: Maybe<NestedIntNullableFilter>;
  min?: Maybe<NestedEnumOrderStatusNullableFilter>;
  max?: Maybe<NestedEnumOrderStatusNullableFilter>;
};

export type EnumTruckTypeNullableWithAggregatesFilter = {
  equals?: Maybe<TruckType>;
  in?: Maybe<Array<Maybe<TruckType>>>;
  notIn?: Maybe<Array<Maybe<TruckType>>>;
  not?: Maybe<NestedEnumTruckTypeNullableWithAggregatesFilter>;
  count?: Maybe<NestedIntNullableFilter>;
  min?: Maybe<NestedEnumTruckTypeNullableFilter>;
  max?: Maybe<NestedEnumTruckTypeNullableFilter>;
};

export type JsonNullableWithAggregatesFilter = {
  equals?: Maybe<Scalars['Json']>;
  not?: Maybe<Scalars['Json']>;
  count?: Maybe<NestedIntNullableFilter>;
  min?: Maybe<NestedJsonNullableFilter>;
  max?: Maybe<NestedJsonNullableFilter>;
};

export type DateTimeFilter = {
  equals?: Maybe<Scalars['DateTime']>;
  in?: Maybe<Array<Maybe<Scalars['DateTime']>>>;
  notIn?: Maybe<Array<Maybe<Scalars['DateTime']>>>;
  lt?: Maybe<Scalars['DateTime']>;
  lte?: Maybe<Scalars['DateTime']>;
  gt?: Maybe<Scalars['DateTime']>;
  gte?: Maybe<Scalars['DateTime']>;
  not?: Maybe<NestedDateTimeFilter>;
};

export type FloatFilter = {
  equals?: Maybe<Scalars['Float']>;
  in?: Maybe<Array<Maybe<Scalars['Float']>>>;
  notIn?: Maybe<Array<Maybe<Scalars['Float']>>>;
  lt?: Maybe<Scalars['Float']>;
  lte?: Maybe<Scalars['Float']>;
  gt?: Maybe<Scalars['Float']>;
  gte?: Maybe<Scalars['Float']>;
  not?: Maybe<NestedFloatFilter>;
};

export type BillRelationFilter = {
  is?: Maybe<BillWhereInput>;
  isNot?: Maybe<BillWhereInput>;
};

export type DateTimeWithAggregatesFilter = {
  equals?: Maybe<Scalars['DateTime']>;
  in?: Maybe<Array<Maybe<Scalars['DateTime']>>>;
  notIn?: Maybe<Array<Maybe<Scalars['DateTime']>>>;
  lt?: Maybe<Scalars['DateTime']>;
  lte?: Maybe<Scalars['DateTime']>;
  gt?: Maybe<Scalars['DateTime']>;
  gte?: Maybe<Scalars['DateTime']>;
  not?: Maybe<NestedDateTimeWithAggregatesFilter>;
  count?: Maybe<NestedIntFilter>;
  min?: Maybe<NestedDateTimeFilter>;
  max?: Maybe<NestedDateTimeFilter>;
};

export type FloatWithAggregatesFilter = {
  equals?: Maybe<Scalars['Float']>;
  in?: Maybe<Array<Maybe<Scalars['Float']>>>;
  notIn?: Maybe<Array<Maybe<Scalars['Float']>>>;
  lt?: Maybe<Scalars['Float']>;
  lte?: Maybe<Scalars['Float']>;
  gt?: Maybe<Scalars['Float']>;
  gte?: Maybe<Scalars['Float']>;
  not?: Maybe<NestedFloatWithAggregatesFilter>;
  count?: Maybe<NestedIntFilter>;
  avg?: Maybe<NestedFloatFilter>;
  sum?: Maybe<NestedFloatFilter>;
  min?: Maybe<NestedFloatFilter>;
  max?: Maybe<NestedFloatFilter>;
};

export type StringFieldUpdateOperationsInput = {
  set?: Maybe<Scalars['String']>;
};

export type EnumRoleFieldUpdateOperationsInput = {
  set?: Maybe<Role>;
};

export type EnumStatusFieldUpdateOperationsInput = {
  set?: Maybe<Status>;
};

export type EmployeeCreateNestedManyWithoutWorksForInput = {
  create?: Maybe<Array<Maybe<EmployeeCreateWithoutWorksForInput>>>;
  connectOrCreate?: Maybe<Array<Maybe<EmployeeCreateOrConnectWithoutWorksForInput>>>;
  connect?: Maybe<Array<Maybe<EmployeeWhereUniqueInput>>>;
};

export type OrderCreateNestedManyWithoutRequestedByInput = {
  create?: Maybe<Array<Maybe<OrderCreateWithoutRequestedByInput>>>;
  connectOrCreate?: Maybe<Array<Maybe<OrderCreateOrConnectWithoutRequestedByInput>>>;
  connect?: Maybe<Array<Maybe<OrderWhereUniqueInput>>>;
};

export type OrderCreateNestedManyWithoutAssignedToInput = {
  create?: Maybe<Array<Maybe<OrderCreateWithoutAssignedToInput>>>;
  connectOrCreate?: Maybe<Array<Maybe<OrderCreateOrConnectWithoutAssignedToInput>>>;
  connect?: Maybe<Array<Maybe<OrderWhereUniqueInput>>>;
};

export type PaymentCreateNestedManyWithoutSourceInput = {
  create?: Maybe<Array<Maybe<PaymentCreateWithoutSourceInput>>>;
  connectOrCreate?: Maybe<Array<Maybe<PaymentCreateOrConnectWithoutSourceInput>>>;
  connect?: Maybe<Array<Maybe<PaymentWhereUniqueInput>>>;
};

export type InvoiceCreateNestedManyWithoutCompanyInput = {
  create?: Maybe<Array<Maybe<InvoiceCreateWithoutCompanyInput>>>;
  connectOrCreate?: Maybe<Array<Maybe<InvoiceCreateOrConnectWithoutCompanyInput>>>;
  connect?: Maybe<Array<Maybe<InvoiceWhereUniqueInput>>>;
};

export type BillCreateNestedManyWithoutCompanyInput = {
  create?: Maybe<Array<Maybe<BillCreateWithoutCompanyInput>>>;
  connectOrCreate?: Maybe<Array<Maybe<BillCreateOrConnectWithoutCompanyInput>>>;
  connect?: Maybe<Array<Maybe<BillWhereUniqueInput>>>;
};

export type EmployeeUncheckedCreateNestedManyWithoutWorksForInput = {
  create?: Maybe<Array<Maybe<EmployeeCreateWithoutWorksForInput>>>;
  connectOrCreate?: Maybe<Array<Maybe<EmployeeCreateOrConnectWithoutWorksForInput>>>;
  connect?: Maybe<Array<Maybe<EmployeeWhereUniqueInput>>>;
};

export type OrderUncheckedCreateNestedManyWithoutRequestedByInput = {
  create?: Maybe<Array<Maybe<OrderCreateWithoutRequestedByInput>>>;
  connectOrCreate?: Maybe<Array<Maybe<OrderCreateOrConnectWithoutRequestedByInput>>>;
  connect?: Maybe<Array<Maybe<OrderWhereUniqueInput>>>;
};

export type OrderUncheckedCreateNestedManyWithoutAssignedToInput = {
  create?: Maybe<Array<Maybe<OrderCreateWithoutAssignedToInput>>>;
  connectOrCreate?: Maybe<Array<Maybe<OrderCreateOrConnectWithoutAssignedToInput>>>;
  connect?: Maybe<Array<Maybe<OrderWhereUniqueInput>>>;
};

export type PaymentUncheckedCreateNestedManyWithoutSourceInput = {
  create?: Maybe<Array<Maybe<PaymentCreateWithoutSourceInput>>>;
  connectOrCreate?: Maybe<Array<Maybe<PaymentCreateOrConnectWithoutSourceInput>>>;
  connect?: Maybe<Array<Maybe<PaymentWhereUniqueInput>>>;
};

export type InvoiceUncheckedCreateNestedManyWithoutCompanyInput = {
  create?: Maybe<Array<Maybe<InvoiceCreateWithoutCompanyInput>>>;
  connectOrCreate?: Maybe<Array<Maybe<InvoiceCreateOrConnectWithoutCompanyInput>>>;
  connect?: Maybe<Array<Maybe<InvoiceWhereUniqueInput>>>;
};

export type BillUncheckedCreateNestedManyWithoutCompanyInput = {
  create?: Maybe<Array<Maybe<BillCreateWithoutCompanyInput>>>;
  connectOrCreate?: Maybe<Array<Maybe<BillCreateOrConnectWithoutCompanyInput>>>;
  connect?: Maybe<Array<Maybe<BillWhereUniqueInput>>>;
};

export type BoolFieldUpdateOperationsInput = {
  set?: Maybe<Scalars['Boolean']>;
};

export type EnumCompanyTypeFieldUpdateOperationsInput = {
  set?: Maybe<CompanyType>;
};

export type EmployeeUpdateManyWithoutWorksForInput = {
  create?: Maybe<Array<Maybe<EmployeeCreateWithoutWorksForInput>>>;
  connectOrCreate?: Maybe<Array<Maybe<EmployeeCreateOrConnectWithoutWorksForInput>>>;
  upsert?: Maybe<Array<Maybe<EmployeeUpsertWithWhereUniqueWithoutWorksForInput>>>;
  connect?: Maybe<Array<Maybe<EmployeeWhereUniqueInput>>>;
  set?: Maybe<Array<Maybe<EmployeeWhereUniqueInput>>>;
  disconnect?: Maybe<Array<Maybe<EmployeeWhereUniqueInput>>>;
  delete?: Maybe<Array<Maybe<EmployeeWhereUniqueInput>>>;
  update?: Maybe<Array<Maybe<EmployeeUpdateWithWhereUniqueWithoutWorksForInput>>>;
  updateMany?: Maybe<Array<Maybe<EmployeeUpdateManyWithWhereWithoutWorksForInput>>>;
  deleteMany?: Maybe<Array<Maybe<EmployeeScalarWhereInput>>>;
};

export type OrderUpdateManyWithoutRequestedByInput = {
  create?: Maybe<Array<Maybe<OrderCreateWithoutRequestedByInput>>>;
  connectOrCreate?: Maybe<Array<Maybe<OrderCreateOrConnectWithoutRequestedByInput>>>;
  upsert?: Maybe<Array<Maybe<OrderUpsertWithWhereUniqueWithoutRequestedByInput>>>;
  connect?: Maybe<Array<Maybe<OrderWhereUniqueInput>>>;
  set?: Maybe<Array<Maybe<OrderWhereUniqueInput>>>;
  disconnect?: Maybe<Array<Maybe<OrderWhereUniqueInput>>>;
  delete?: Maybe<Array<Maybe<OrderWhereUniqueInput>>>;
  update?: Maybe<Array<Maybe<OrderUpdateWithWhereUniqueWithoutRequestedByInput>>>;
  updateMany?: Maybe<Array<Maybe<OrderUpdateManyWithWhereWithoutRequestedByInput>>>;
  deleteMany?: Maybe<Array<Maybe<OrderScalarWhereInput>>>;
};

export type OrderUpdateManyWithoutAssignedToInput = {
  create?: Maybe<Array<Maybe<OrderCreateWithoutAssignedToInput>>>;
  connectOrCreate?: Maybe<Array<Maybe<OrderCreateOrConnectWithoutAssignedToInput>>>;
  upsert?: Maybe<Array<Maybe<OrderUpsertWithWhereUniqueWithoutAssignedToInput>>>;
  connect?: Maybe<Array<Maybe<OrderWhereUniqueInput>>>;
  set?: Maybe<Array<Maybe<OrderWhereUniqueInput>>>;
  disconnect?: Maybe<Array<Maybe<OrderWhereUniqueInput>>>;
  delete?: Maybe<Array<Maybe<OrderWhereUniqueInput>>>;
  update?: Maybe<Array<Maybe<OrderUpdateWithWhereUniqueWithoutAssignedToInput>>>;
  updateMany?: Maybe<Array<Maybe<OrderUpdateManyWithWhereWithoutAssignedToInput>>>;
  deleteMany?: Maybe<Array<Maybe<OrderScalarWhereInput>>>;
};

export type PaymentUpdateManyWithoutSourceInput = {
  create?: Maybe<Array<Maybe<PaymentCreateWithoutSourceInput>>>;
  connectOrCreate?: Maybe<Array<Maybe<PaymentCreateOrConnectWithoutSourceInput>>>;
  upsert?: Maybe<Array<Maybe<PaymentUpsertWithWhereUniqueWithoutSourceInput>>>;
  connect?: Maybe<Array<Maybe<PaymentWhereUniqueInput>>>;
  set?: Maybe<Array<Maybe<PaymentWhereUniqueInput>>>;
  disconnect?: Maybe<Array<Maybe<PaymentWhereUniqueInput>>>;
  delete?: Maybe<Array<Maybe<PaymentWhereUniqueInput>>>;
  update?: Maybe<Array<Maybe<PaymentUpdateWithWhereUniqueWithoutSourceInput>>>;
  updateMany?: Maybe<Array<Maybe<PaymentUpdateManyWithWhereWithoutSourceInput>>>;
  deleteMany?: Maybe<Array<Maybe<PaymentScalarWhereInput>>>;
};

export type InvoiceUpdateManyWithoutCompanyInput = {
  create?: Maybe<Array<Maybe<InvoiceCreateWithoutCompanyInput>>>;
  connectOrCreate?: Maybe<Array<Maybe<InvoiceCreateOrConnectWithoutCompanyInput>>>;
  upsert?: Maybe<Array<Maybe<InvoiceUpsertWithWhereUniqueWithoutCompanyInput>>>;
  connect?: Maybe<Array<Maybe<InvoiceWhereUniqueInput>>>;
  set?: Maybe<Array<Maybe<InvoiceWhereUniqueInput>>>;
  disconnect?: Maybe<Array<Maybe<InvoiceWhereUniqueInput>>>;
  delete?: Maybe<Array<Maybe<InvoiceWhereUniqueInput>>>;
  update?: Maybe<Array<Maybe<InvoiceUpdateWithWhereUniqueWithoutCompanyInput>>>;
  updateMany?: Maybe<Array<Maybe<InvoiceUpdateManyWithWhereWithoutCompanyInput>>>;
  deleteMany?: Maybe<Array<Maybe<InvoiceScalarWhereInput>>>;
};

export type BillUpdateManyWithoutCompanyInput = {
  create?: Maybe<Array<Maybe<BillCreateWithoutCompanyInput>>>;
  connectOrCreate?: Maybe<Array<Maybe<BillCreateOrConnectWithoutCompanyInput>>>;
  upsert?: Maybe<Array<Maybe<BillUpsertWithWhereUniqueWithoutCompanyInput>>>;
  connect?: Maybe<Array<Maybe<BillWhereUniqueInput>>>;
  set?: Maybe<Array<Maybe<BillWhereUniqueInput>>>;
  disconnect?: Maybe<Array<Maybe<BillWhereUniqueInput>>>;
  delete?: Maybe<Array<Maybe<BillWhereUniqueInput>>>;
  update?: Maybe<Array<Maybe<BillUpdateWithWhereUniqueWithoutCompanyInput>>>;
  updateMany?: Maybe<Array<Maybe<BillUpdateManyWithWhereWithoutCompanyInput>>>;
  deleteMany?: Maybe<Array<Maybe<BillScalarWhereInput>>>;
};

export type IntFieldUpdateOperationsInput = {
  set?: Maybe<Scalars['Int']>;
  increment?: Maybe<Scalars['Int']>;
  decrement?: Maybe<Scalars['Int']>;
  multiply?: Maybe<Scalars['Int']>;
  divide?: Maybe<Scalars['Int']>;
};

export type EmployeeUncheckedUpdateManyWithoutWorksForInput = {
  create?: Maybe<Array<Maybe<EmployeeCreateWithoutWorksForInput>>>;
  connectOrCreate?: Maybe<Array<Maybe<EmployeeCreateOrConnectWithoutWorksForInput>>>;
  upsert?: Maybe<Array<Maybe<EmployeeUpsertWithWhereUniqueWithoutWorksForInput>>>;
  connect?: Maybe<Array<Maybe<EmployeeWhereUniqueInput>>>;
  set?: Maybe<Array<Maybe<EmployeeWhereUniqueInput>>>;
  disconnect?: Maybe<Array<Maybe<EmployeeWhereUniqueInput>>>;
  delete?: Maybe<Array<Maybe<EmployeeWhereUniqueInput>>>;
  update?: Maybe<Array<Maybe<EmployeeUpdateWithWhereUniqueWithoutWorksForInput>>>;
  updateMany?: Maybe<Array<Maybe<EmployeeUpdateManyWithWhereWithoutWorksForInput>>>;
  deleteMany?: Maybe<Array<Maybe<EmployeeScalarWhereInput>>>;
};

export type OrderUncheckedUpdateManyWithoutRequestedByInput = {
  create?: Maybe<Array<Maybe<OrderCreateWithoutRequestedByInput>>>;
  connectOrCreate?: Maybe<Array<Maybe<OrderCreateOrConnectWithoutRequestedByInput>>>;
  upsert?: Maybe<Array<Maybe<OrderUpsertWithWhereUniqueWithoutRequestedByInput>>>;
  connect?: Maybe<Array<Maybe<OrderWhereUniqueInput>>>;
  set?: Maybe<Array<Maybe<OrderWhereUniqueInput>>>;
  disconnect?: Maybe<Array<Maybe<OrderWhereUniqueInput>>>;
  delete?: Maybe<Array<Maybe<OrderWhereUniqueInput>>>;
  update?: Maybe<Array<Maybe<OrderUpdateWithWhereUniqueWithoutRequestedByInput>>>;
  updateMany?: Maybe<Array<Maybe<OrderUpdateManyWithWhereWithoutRequestedByInput>>>;
  deleteMany?: Maybe<Array<Maybe<OrderScalarWhereInput>>>;
};

export type OrderUncheckedUpdateManyWithoutAssignedToInput = {
  create?: Maybe<Array<Maybe<OrderCreateWithoutAssignedToInput>>>;
  connectOrCreate?: Maybe<Array<Maybe<OrderCreateOrConnectWithoutAssignedToInput>>>;
  upsert?: Maybe<Array<Maybe<OrderUpsertWithWhereUniqueWithoutAssignedToInput>>>;
  connect?: Maybe<Array<Maybe<OrderWhereUniqueInput>>>;
  set?: Maybe<Array<Maybe<OrderWhereUniqueInput>>>;
  disconnect?: Maybe<Array<Maybe<OrderWhereUniqueInput>>>;
  delete?: Maybe<Array<Maybe<OrderWhereUniqueInput>>>;
  update?: Maybe<Array<Maybe<OrderUpdateWithWhereUniqueWithoutAssignedToInput>>>;
  updateMany?: Maybe<Array<Maybe<OrderUpdateManyWithWhereWithoutAssignedToInput>>>;
  deleteMany?: Maybe<Array<Maybe<OrderScalarWhereInput>>>;
};

export type PaymentUncheckedUpdateManyWithoutSourceInput = {
  create?: Maybe<Array<Maybe<PaymentCreateWithoutSourceInput>>>;
  connectOrCreate?: Maybe<Array<Maybe<PaymentCreateOrConnectWithoutSourceInput>>>;
  upsert?: Maybe<Array<Maybe<PaymentUpsertWithWhereUniqueWithoutSourceInput>>>;
  connect?: Maybe<Array<Maybe<PaymentWhereUniqueInput>>>;
  set?: Maybe<Array<Maybe<PaymentWhereUniqueInput>>>;
  disconnect?: Maybe<Array<Maybe<PaymentWhereUniqueInput>>>;
  delete?: Maybe<Array<Maybe<PaymentWhereUniqueInput>>>;
  update?: Maybe<Array<Maybe<PaymentUpdateWithWhereUniqueWithoutSourceInput>>>;
  updateMany?: Maybe<Array<Maybe<PaymentUpdateManyWithWhereWithoutSourceInput>>>;
  deleteMany?: Maybe<Array<Maybe<PaymentScalarWhereInput>>>;
};

export type InvoiceUncheckedUpdateManyWithoutCompanyInput = {
  create?: Maybe<Array<Maybe<InvoiceCreateWithoutCompanyInput>>>;
  connectOrCreate?: Maybe<Array<Maybe<InvoiceCreateOrConnectWithoutCompanyInput>>>;
  upsert?: Maybe<Array<Maybe<InvoiceUpsertWithWhereUniqueWithoutCompanyInput>>>;
  connect?: Maybe<Array<Maybe<InvoiceWhereUniqueInput>>>;
  set?: Maybe<Array<Maybe<InvoiceWhereUniqueInput>>>;
  disconnect?: Maybe<Array<Maybe<InvoiceWhereUniqueInput>>>;
  delete?: Maybe<Array<Maybe<InvoiceWhereUniqueInput>>>;
  update?: Maybe<Array<Maybe<InvoiceUpdateWithWhereUniqueWithoutCompanyInput>>>;
  updateMany?: Maybe<Array<Maybe<InvoiceUpdateManyWithWhereWithoutCompanyInput>>>;
  deleteMany?: Maybe<Array<Maybe<InvoiceScalarWhereInput>>>;
};

export type BillUncheckedUpdateManyWithoutCompanyInput = {
  create?: Maybe<Array<Maybe<BillCreateWithoutCompanyInput>>>;
  connectOrCreate?: Maybe<Array<Maybe<BillCreateOrConnectWithoutCompanyInput>>>;
  upsert?: Maybe<Array<Maybe<BillUpsertWithWhereUniqueWithoutCompanyInput>>>;
  connect?: Maybe<Array<Maybe<BillWhereUniqueInput>>>;
  set?: Maybe<Array<Maybe<BillWhereUniqueInput>>>;
  disconnect?: Maybe<Array<Maybe<BillWhereUniqueInput>>>;
  delete?: Maybe<Array<Maybe<BillWhereUniqueInput>>>;
  update?: Maybe<Array<Maybe<BillUpdateWithWhereUniqueWithoutCompanyInput>>>;
  updateMany?: Maybe<Array<Maybe<BillUpdateManyWithWhereWithoutCompanyInput>>>;
  deleteMany?: Maybe<Array<Maybe<BillScalarWhereInput>>>;
};

export type CompanyCreateNestedOneWithoutEmployeesInput = {
  create?: Maybe<CompanyUncheckedCreateWithoutEmployeesInput>;
  connectOrCreate?: Maybe<CompanyCreateOrConnectWithoutEmployeesInput>;
  connect?: Maybe<CompanyWhereUniqueInput>;
};

export type OrderCreateNestedManyWithoutCarriedByInput = {
  create?: Maybe<Array<Maybe<OrderCreateWithoutCarriedByInput>>>;
  connectOrCreate?: Maybe<Array<Maybe<OrderCreateOrConnectWithoutCarriedByInput>>>;
  connect?: Maybe<Array<Maybe<OrderWhereUniqueInput>>>;
};

export type OrderUncheckedCreateNestedManyWithoutCarriedByInput = {
  create?: Maybe<Array<Maybe<OrderCreateWithoutCarriedByInput>>>;
  connectOrCreate?: Maybe<Array<Maybe<OrderCreateOrConnectWithoutCarriedByInput>>>;
  connect?: Maybe<Array<Maybe<OrderWhereUniqueInput>>>;
};

export type EnumEmployeeStatusFieldUpdateOperationsInput = {
  set?: Maybe<EmployeeStatus>;
};

export type CompanyUpdateOneRequiredWithoutEmployeesInput = {
  create?: Maybe<CompanyUncheckedCreateWithoutEmployeesInput>;
  connectOrCreate?: Maybe<CompanyCreateOrConnectWithoutEmployeesInput>;
  upsert?: Maybe<CompanyUpsertWithoutEmployeesInput>;
  connect?: Maybe<CompanyWhereUniqueInput>;
  update?: Maybe<CompanyUncheckedUpdateWithoutEmployeesInput>;
};

export type OrderUpdateManyWithoutCarriedByInput = {
  create?: Maybe<Array<Maybe<OrderCreateWithoutCarriedByInput>>>;
  connectOrCreate?: Maybe<Array<Maybe<OrderCreateOrConnectWithoutCarriedByInput>>>;
  upsert?: Maybe<Array<Maybe<OrderUpsertWithWhereUniqueWithoutCarriedByInput>>>;
  connect?: Maybe<Array<Maybe<OrderWhereUniqueInput>>>;
  set?: Maybe<Array<Maybe<OrderWhereUniqueInput>>>;
  disconnect?: Maybe<Array<Maybe<OrderWhereUniqueInput>>>;
  delete?: Maybe<Array<Maybe<OrderWhereUniqueInput>>>;
  update?: Maybe<Array<Maybe<OrderUpdateWithWhereUniqueWithoutCarriedByInput>>>;
  updateMany?: Maybe<Array<Maybe<OrderUpdateManyWithWhereWithoutCarriedByInput>>>;
  deleteMany?: Maybe<Array<Maybe<OrderScalarWhereInput>>>;
};

export type OrderUncheckedUpdateManyWithoutCarriedByInput = {
  create?: Maybe<Array<Maybe<OrderCreateWithoutCarriedByInput>>>;
  connectOrCreate?: Maybe<Array<Maybe<OrderCreateOrConnectWithoutCarriedByInput>>>;
  upsert?: Maybe<Array<Maybe<OrderUpsertWithWhereUniqueWithoutCarriedByInput>>>;
  connect?: Maybe<Array<Maybe<OrderWhereUniqueInput>>>;
  set?: Maybe<Array<Maybe<OrderWhereUniqueInput>>>;
  disconnect?: Maybe<Array<Maybe<OrderWhereUniqueInput>>>;
  delete?: Maybe<Array<Maybe<OrderWhereUniqueInput>>>;
  update?: Maybe<Array<Maybe<OrderUpdateWithWhereUniqueWithoutCarriedByInput>>>;
  updateMany?: Maybe<Array<Maybe<OrderUpdateManyWithWhereWithoutCarriedByInput>>>;
  deleteMany?: Maybe<Array<Maybe<OrderScalarWhereInput>>>;
};

export type OrderCreatereceiptsInput = {
  set: Scalars['String'];
};

export type CompanyCreateNestedOneWithoutAssignedInput = {
  create?: Maybe<CompanyUncheckedCreateWithoutAssignedInput>;
  connectOrCreate?: Maybe<CompanyCreateOrConnectWithoutAssignedInput>;
  connect?: Maybe<CompanyWhereUniqueInput>;
};

export type EmployeeCreateNestedOneWithoutAssignedInput = {
  create?: Maybe<EmployeeUncheckedCreateWithoutAssignedInput>;
  connectOrCreate?: Maybe<EmployeeCreateOrConnectWithoutAssignedInput>;
  connect?: Maybe<EmployeeWhereUniqueInput>;
};

export type ShedCreateNestedOneWithoutAsDestinationInput = {
  create?: Maybe<ShedUncheckedCreateWithoutAsDestinationInput>;
  connectOrCreate?: Maybe<ShedCreateOrConnectWithoutAsDestinationInput>;
  connect?: Maybe<ShedWhereUniqueInput>;
};

export type InvoiceCreateNestedManyWithoutItemsInput = {
  create?: Maybe<Array<Maybe<InvoiceCreateWithoutItemsInput>>>;
  connectOrCreate?: Maybe<Array<Maybe<InvoiceCreateOrConnectWithoutItemsInput>>>;
  connect?: Maybe<Array<Maybe<InvoiceWhereUniqueInput>>>;
};

export type ShedCreateNestedOneWithoutAsOriginInput = {
  create?: Maybe<ShedUncheckedCreateWithoutAsOriginInput>;
  connectOrCreate?: Maybe<ShedCreateOrConnectWithoutAsOriginInput>;
  connect?: Maybe<ShedWhereUniqueInput>;
};

export type CompanyCreateNestedOneWithoutRequestedInput = {
  create?: Maybe<CompanyUncheckedCreateWithoutRequestedInput>;
  connectOrCreate?: Maybe<CompanyCreateOrConnectWithoutRequestedInput>;
  connect?: Maybe<CompanyWhereUniqueInput>;
};

export type NullableDateTimeFieldUpdateOperationsInput = {
  set?: Maybe<Scalars['DateTime']>;
};

export type NullableEnumCargoTypeFieldUpdateOperationsInput = {
  set?: Maybe<CargoType>;
};

export type NullableStringFieldUpdateOperationsInput = {
  set?: Maybe<Scalars['String']>;
};

export type NullableIntFieldUpdateOperationsInput = {
  set?: Maybe<Scalars['Int']>;
  increment?: Maybe<Scalars['Int']>;
  decrement?: Maybe<Scalars['Int']>;
  multiply?: Maybe<Scalars['Int']>;
  divide?: Maybe<Scalars['Int']>;
};

export type NullableFloatFieldUpdateOperationsInput = {
  set?: Maybe<Scalars['Float']>;
  increment?: Maybe<Scalars['Float']>;
  decrement?: Maybe<Scalars['Float']>;
  multiply?: Maybe<Scalars['Float']>;
  divide?: Maybe<Scalars['Float']>;
};

export type NullableEnumOrderStatusFieldUpdateOperationsInput = {
  set?: Maybe<OrderStatus>;
};

export type NullableEnumTruckTypeFieldUpdateOperationsInput = {
  set?: Maybe<TruckType>;
};

export type OrderUpdatereceiptsInput = {
  set: Scalars['String'];
};

export type CompanyUpdateOneWithoutAssignedInput = {
  create?: Maybe<CompanyUncheckedCreateWithoutAssignedInput>;
  connectOrCreate?: Maybe<CompanyCreateOrConnectWithoutAssignedInput>;
  upsert?: Maybe<CompanyUpsertWithoutAssignedInput>;
  connect?: Maybe<CompanyWhereUniqueInput>;
  disconnect?: Maybe<Scalars['Boolean']>;
  delete?: Maybe<Scalars['Boolean']>;
  update?: Maybe<CompanyUncheckedUpdateWithoutAssignedInput>;
};

export type EmployeeUpdateOneWithoutAssignedInput = {
  create?: Maybe<EmployeeUncheckedCreateWithoutAssignedInput>;
  connectOrCreate?: Maybe<EmployeeCreateOrConnectWithoutAssignedInput>;
  upsert?: Maybe<EmployeeUpsertWithoutAssignedInput>;
  connect?: Maybe<EmployeeWhereUniqueInput>;
  disconnect?: Maybe<Scalars['Boolean']>;
  delete?: Maybe<Scalars['Boolean']>;
  update?: Maybe<EmployeeUncheckedUpdateWithoutAssignedInput>;
};

export type ShedUpdateOneWithoutAsDestinationInput = {
  create?: Maybe<ShedUncheckedCreateWithoutAsDestinationInput>;
  connectOrCreate?: Maybe<ShedCreateOrConnectWithoutAsDestinationInput>;
  upsert?: Maybe<ShedUpsertWithoutAsDestinationInput>;
  connect?: Maybe<ShedWhereUniqueInput>;
  disconnect?: Maybe<Scalars['Boolean']>;
  delete?: Maybe<Scalars['Boolean']>;
  update?: Maybe<ShedUncheckedUpdateWithoutAsDestinationInput>;
};

export type InvoiceUpdateManyWithoutItemsInput = {
  create?: Maybe<Array<Maybe<InvoiceCreateWithoutItemsInput>>>;
  connectOrCreate?: Maybe<Array<Maybe<InvoiceCreateOrConnectWithoutItemsInput>>>;
  upsert?: Maybe<Array<Maybe<InvoiceUpsertWithWhereUniqueWithoutItemsInput>>>;
  connect?: Maybe<Array<Maybe<InvoiceWhereUniqueInput>>>;
  set?: Maybe<Array<Maybe<InvoiceWhereUniqueInput>>>;
  disconnect?: Maybe<Array<Maybe<InvoiceWhereUniqueInput>>>;
  delete?: Maybe<Array<Maybe<InvoiceWhereUniqueInput>>>;
  update?: Maybe<Array<Maybe<InvoiceUpdateWithWhereUniqueWithoutItemsInput>>>;
  updateMany?: Maybe<Array<Maybe<InvoiceUpdateManyWithWhereWithoutItemsInput>>>;
  deleteMany?: Maybe<Array<Maybe<InvoiceScalarWhereInput>>>;
};

export type ShedUpdateOneWithoutAsOriginInput = {
  create?: Maybe<ShedUncheckedCreateWithoutAsOriginInput>;
  connectOrCreate?: Maybe<ShedCreateOrConnectWithoutAsOriginInput>;
  upsert?: Maybe<ShedUpsertWithoutAsOriginInput>;
  connect?: Maybe<ShedWhereUniqueInput>;
  disconnect?: Maybe<Scalars['Boolean']>;
  delete?: Maybe<Scalars['Boolean']>;
  update?: Maybe<ShedUncheckedUpdateWithoutAsOriginInput>;
};

export type CompanyUpdateOneWithoutRequestedInput = {
  create?: Maybe<CompanyUncheckedCreateWithoutRequestedInput>;
  connectOrCreate?: Maybe<CompanyCreateOrConnectWithoutRequestedInput>;
  upsert?: Maybe<CompanyUpsertWithoutRequestedInput>;
  connect?: Maybe<CompanyWhereUniqueInput>;
  disconnect?: Maybe<Scalars['Boolean']>;
  delete?: Maybe<Scalars['Boolean']>;
  update?: Maybe<CompanyUncheckedUpdateWithoutRequestedInput>;
};

export type CompanyCreateNestedOneWithoutInvoicesInput = {
  create?: Maybe<CompanyUncheckedCreateWithoutInvoicesInput>;
  connectOrCreate?: Maybe<CompanyCreateOrConnectWithoutInvoicesInput>;
  connect?: Maybe<CompanyWhereUniqueInput>;
};

export type OrderCreateNestedManyWithoutInvoicesInput = {
  create?: Maybe<Array<Maybe<OrderCreateWithoutInvoicesInput>>>;
  connectOrCreate?: Maybe<Array<Maybe<OrderCreateOrConnectWithoutInvoicesInput>>>;
  connect?: Maybe<Array<Maybe<OrderWhereUniqueInput>>>;
};

export type BillCreateNestedOneWithoutItemsInput = {
  create?: Maybe<BillUncheckedCreateWithoutItemsInput>;
  connectOrCreate?: Maybe<BillCreateOrConnectWithoutItemsInput>;
  connect?: Maybe<BillWhereUniqueInput>;
};

export type DateTimeFieldUpdateOperationsInput = {
  set?: Maybe<Scalars['DateTime']>;
};

export type FloatFieldUpdateOperationsInput = {
  set?: Maybe<Scalars['Float']>;
  increment?: Maybe<Scalars['Float']>;
  decrement?: Maybe<Scalars['Float']>;
  multiply?: Maybe<Scalars['Float']>;
  divide?: Maybe<Scalars['Float']>;
};

export type CompanyUpdateOneRequiredWithoutInvoicesInput = {
  create?: Maybe<CompanyUncheckedCreateWithoutInvoicesInput>;
  connectOrCreate?: Maybe<CompanyCreateOrConnectWithoutInvoicesInput>;
  upsert?: Maybe<CompanyUpsertWithoutInvoicesInput>;
  connect?: Maybe<CompanyWhereUniqueInput>;
  update?: Maybe<CompanyUncheckedUpdateWithoutInvoicesInput>;
};

export type OrderUpdateManyWithoutInvoicesInput = {
  create?: Maybe<Array<Maybe<OrderCreateWithoutInvoicesInput>>>;
  connectOrCreate?: Maybe<Array<Maybe<OrderCreateOrConnectWithoutInvoicesInput>>>;
  upsert?: Maybe<Array<Maybe<OrderUpsertWithWhereUniqueWithoutInvoicesInput>>>;
  connect?: Maybe<Array<Maybe<OrderWhereUniqueInput>>>;
  set?: Maybe<Array<Maybe<OrderWhereUniqueInput>>>;
  disconnect?: Maybe<Array<Maybe<OrderWhereUniqueInput>>>;
  delete?: Maybe<Array<Maybe<OrderWhereUniqueInput>>>;
  update?: Maybe<Array<Maybe<OrderUpdateWithWhereUniqueWithoutInvoicesInput>>>;
  updateMany?: Maybe<Array<Maybe<OrderUpdateManyWithWhereWithoutInvoicesInput>>>;
  deleteMany?: Maybe<Array<Maybe<OrderScalarWhereInput>>>;
};

export type BillUpdateOneWithoutItemsInput = {
  create?: Maybe<BillUncheckedCreateWithoutItemsInput>;
  connectOrCreate?: Maybe<BillCreateOrConnectWithoutItemsInput>;
  upsert?: Maybe<BillUpsertWithoutItemsInput>;
  connect?: Maybe<BillWhereUniqueInput>;
  disconnect?: Maybe<Scalars['Boolean']>;
  delete?: Maybe<Scalars['Boolean']>;
  update?: Maybe<BillUncheckedUpdateWithoutItemsInput>;
};

export type CompanyCreateNestedOneWithoutBillsInput = {
  create?: Maybe<CompanyUncheckedCreateWithoutBillsInput>;
  connectOrCreate?: Maybe<CompanyCreateOrConnectWithoutBillsInput>;
  connect?: Maybe<CompanyWhereUniqueInput>;
};

export type InvoiceCreateNestedManyWithoutBillInput = {
  create?: Maybe<Array<Maybe<InvoiceCreateWithoutBillInput>>>;
  connectOrCreate?: Maybe<Array<Maybe<InvoiceCreateOrConnectWithoutBillInput>>>;
  connect?: Maybe<Array<Maybe<InvoiceWhereUniqueInput>>>;
};

export type PaymentCreateNestedManyWithoutReferenceInput = {
  create?: Maybe<Array<Maybe<PaymentCreateWithoutReferenceInput>>>;
  connectOrCreate?: Maybe<Array<Maybe<PaymentCreateOrConnectWithoutReferenceInput>>>;
  connect?: Maybe<Array<Maybe<PaymentWhereUniqueInput>>>;
};

export type InvoiceUncheckedCreateNestedManyWithoutBillInput = {
  create?: Maybe<Array<Maybe<InvoiceCreateWithoutBillInput>>>;
  connectOrCreate?: Maybe<Array<Maybe<InvoiceCreateOrConnectWithoutBillInput>>>;
  connect?: Maybe<Array<Maybe<InvoiceWhereUniqueInput>>>;
};

export type PaymentUncheckedCreateNestedManyWithoutReferenceInput = {
  create?: Maybe<Array<Maybe<PaymentCreateWithoutReferenceInput>>>;
  connectOrCreate?: Maybe<Array<Maybe<PaymentCreateOrConnectWithoutReferenceInput>>>;
  connect?: Maybe<Array<Maybe<PaymentWhereUniqueInput>>>;
};

export type CompanyUpdateOneRequiredWithoutBillsInput = {
  create?: Maybe<CompanyUncheckedCreateWithoutBillsInput>;
  connectOrCreate?: Maybe<CompanyCreateOrConnectWithoutBillsInput>;
  upsert?: Maybe<CompanyUpsertWithoutBillsInput>;
  connect?: Maybe<CompanyWhereUniqueInput>;
  update?: Maybe<CompanyUncheckedUpdateWithoutBillsInput>;
};

export type InvoiceUpdateManyWithoutBillInput = {
  create?: Maybe<Array<Maybe<InvoiceCreateWithoutBillInput>>>;
  connectOrCreate?: Maybe<Array<Maybe<InvoiceCreateOrConnectWithoutBillInput>>>;
  upsert?: Maybe<Array<Maybe<InvoiceUpsertWithWhereUniqueWithoutBillInput>>>;
  connect?: Maybe<Array<Maybe<InvoiceWhereUniqueInput>>>;
  set?: Maybe<Array<Maybe<InvoiceWhereUniqueInput>>>;
  disconnect?: Maybe<Array<Maybe<InvoiceWhereUniqueInput>>>;
  delete?: Maybe<Array<Maybe<InvoiceWhereUniqueInput>>>;
  update?: Maybe<Array<Maybe<InvoiceUpdateWithWhereUniqueWithoutBillInput>>>;
  updateMany?: Maybe<Array<Maybe<InvoiceUpdateManyWithWhereWithoutBillInput>>>;
  deleteMany?: Maybe<Array<Maybe<InvoiceScalarWhereInput>>>;
};

export type PaymentUpdateManyWithoutReferenceInput = {
  create?: Maybe<Array<Maybe<PaymentCreateWithoutReferenceInput>>>;
  connectOrCreate?: Maybe<Array<Maybe<PaymentCreateOrConnectWithoutReferenceInput>>>;
  upsert?: Maybe<Array<Maybe<PaymentUpsertWithWhereUniqueWithoutReferenceInput>>>;
  connect?: Maybe<Array<Maybe<PaymentWhereUniqueInput>>>;
  set?: Maybe<Array<Maybe<PaymentWhereUniqueInput>>>;
  disconnect?: Maybe<Array<Maybe<PaymentWhereUniqueInput>>>;
  delete?: Maybe<Array<Maybe<PaymentWhereUniqueInput>>>;
  update?: Maybe<Array<Maybe<PaymentUpdateWithWhereUniqueWithoutReferenceInput>>>;
  updateMany?: Maybe<Array<Maybe<PaymentUpdateManyWithWhereWithoutReferenceInput>>>;
  deleteMany?: Maybe<Array<Maybe<PaymentScalarWhereInput>>>;
};

export type InvoiceUncheckedUpdateManyWithoutBillInput = {
  create?: Maybe<Array<Maybe<InvoiceCreateWithoutBillInput>>>;
  connectOrCreate?: Maybe<Array<Maybe<InvoiceCreateOrConnectWithoutBillInput>>>;
  upsert?: Maybe<Array<Maybe<InvoiceUpsertWithWhereUniqueWithoutBillInput>>>;
  connect?: Maybe<Array<Maybe<InvoiceWhereUniqueInput>>>;
  set?: Maybe<Array<Maybe<InvoiceWhereUniqueInput>>>;
  disconnect?: Maybe<Array<Maybe<InvoiceWhereUniqueInput>>>;
  delete?: Maybe<Array<Maybe<InvoiceWhereUniqueInput>>>;
  update?: Maybe<Array<Maybe<InvoiceUpdateWithWhereUniqueWithoutBillInput>>>;
  updateMany?: Maybe<Array<Maybe<InvoiceUpdateManyWithWhereWithoutBillInput>>>;
  deleteMany?: Maybe<Array<Maybe<InvoiceScalarWhereInput>>>;
};

export type PaymentUncheckedUpdateManyWithoutReferenceInput = {
  create?: Maybe<Array<Maybe<PaymentCreateWithoutReferenceInput>>>;
  connectOrCreate?: Maybe<Array<Maybe<PaymentCreateOrConnectWithoutReferenceInput>>>;
  upsert?: Maybe<Array<Maybe<PaymentUpsertWithWhereUniqueWithoutReferenceInput>>>;
  connect?: Maybe<Array<Maybe<PaymentWhereUniqueInput>>>;
  set?: Maybe<Array<Maybe<PaymentWhereUniqueInput>>>;
  disconnect?: Maybe<Array<Maybe<PaymentWhereUniqueInput>>>;
  delete?: Maybe<Array<Maybe<PaymentWhereUniqueInput>>>;
  update?: Maybe<Array<Maybe<PaymentUpdateWithWhereUniqueWithoutReferenceInput>>>;
  updateMany?: Maybe<Array<Maybe<PaymentUpdateManyWithWhereWithoutReferenceInput>>>;
  deleteMany?: Maybe<Array<Maybe<PaymentScalarWhereInput>>>;
};

export type CompanyCreateNestedOneWithoutPaymentsInput = {
  create?: Maybe<CompanyUncheckedCreateWithoutPaymentsInput>;
  connectOrCreate?: Maybe<CompanyCreateOrConnectWithoutPaymentsInput>;
  connect?: Maybe<CompanyWhereUniqueInput>;
};

export type BillCreateNestedOneWithoutPaymentsInput = {
  create?: Maybe<BillUncheckedCreateWithoutPaymentsInput>;
  connectOrCreate?: Maybe<BillCreateOrConnectWithoutPaymentsInput>;
  connect?: Maybe<BillWhereUniqueInput>;
};

export type CompanyUpdateOneRequiredWithoutPaymentsInput = {
  create?: Maybe<CompanyUncheckedCreateWithoutPaymentsInput>;
  connectOrCreate?: Maybe<CompanyCreateOrConnectWithoutPaymentsInput>;
  upsert?: Maybe<CompanyUpsertWithoutPaymentsInput>;
  connect?: Maybe<CompanyWhereUniqueInput>;
  update?: Maybe<CompanyUncheckedUpdateWithoutPaymentsInput>;
};

export type BillUpdateOneWithoutPaymentsInput = {
  create?: Maybe<BillUncheckedCreateWithoutPaymentsInput>;
  connectOrCreate?: Maybe<BillCreateOrConnectWithoutPaymentsInput>;
  upsert?: Maybe<BillUpsertWithoutPaymentsInput>;
  connect?: Maybe<BillWhereUniqueInput>;
  disconnect?: Maybe<Scalars['Boolean']>;
  delete?: Maybe<Scalars['Boolean']>;
  update?: Maybe<BillUncheckedUpdateWithoutPaymentsInput>;
};

export type OrderCreateNestedManyWithoutOriginInput = {
  create?: Maybe<Array<Maybe<OrderCreateWithoutOriginInput>>>;
  connectOrCreate?: Maybe<Array<Maybe<OrderCreateOrConnectWithoutOriginInput>>>;
  connect?: Maybe<Array<Maybe<OrderWhereUniqueInput>>>;
};

export type OrderCreateNestedManyWithoutDestinationInput = {
  create?: Maybe<Array<Maybe<OrderCreateWithoutDestinationInput>>>;
  connectOrCreate?: Maybe<Array<Maybe<OrderCreateOrConnectWithoutDestinationInput>>>;
  connect?: Maybe<Array<Maybe<OrderWhereUniqueInput>>>;
};

export type OrderUncheckedCreateNestedManyWithoutOriginInput = {
  create?: Maybe<Array<Maybe<OrderCreateWithoutOriginInput>>>;
  connectOrCreate?: Maybe<Array<Maybe<OrderCreateOrConnectWithoutOriginInput>>>;
  connect?: Maybe<Array<Maybe<OrderWhereUniqueInput>>>;
};

export type OrderUncheckedCreateNestedManyWithoutDestinationInput = {
  create?: Maybe<Array<Maybe<OrderCreateWithoutDestinationInput>>>;
  connectOrCreate?: Maybe<Array<Maybe<OrderCreateOrConnectWithoutDestinationInput>>>;
  connect?: Maybe<Array<Maybe<OrderWhereUniqueInput>>>;
};

export type OrderUpdateManyWithoutOriginInput = {
  create?: Maybe<Array<Maybe<OrderCreateWithoutOriginInput>>>;
  connectOrCreate?: Maybe<Array<Maybe<OrderCreateOrConnectWithoutOriginInput>>>;
  upsert?: Maybe<Array<Maybe<OrderUpsertWithWhereUniqueWithoutOriginInput>>>;
  connect?: Maybe<Array<Maybe<OrderWhereUniqueInput>>>;
  set?: Maybe<Array<Maybe<OrderWhereUniqueInput>>>;
  disconnect?: Maybe<Array<Maybe<OrderWhereUniqueInput>>>;
  delete?: Maybe<Array<Maybe<OrderWhereUniqueInput>>>;
  update?: Maybe<Array<Maybe<OrderUpdateWithWhereUniqueWithoutOriginInput>>>;
  updateMany?: Maybe<Array<Maybe<OrderUpdateManyWithWhereWithoutOriginInput>>>;
  deleteMany?: Maybe<Array<Maybe<OrderScalarWhereInput>>>;
};

export type OrderUpdateManyWithoutDestinationInput = {
  create?: Maybe<Array<Maybe<OrderCreateWithoutDestinationInput>>>;
  connectOrCreate?: Maybe<Array<Maybe<OrderCreateOrConnectWithoutDestinationInput>>>;
  upsert?: Maybe<Array<Maybe<OrderUpsertWithWhereUniqueWithoutDestinationInput>>>;
  connect?: Maybe<Array<Maybe<OrderWhereUniqueInput>>>;
  set?: Maybe<Array<Maybe<OrderWhereUniqueInput>>>;
  disconnect?: Maybe<Array<Maybe<OrderWhereUniqueInput>>>;
  delete?: Maybe<Array<Maybe<OrderWhereUniqueInput>>>;
  update?: Maybe<Array<Maybe<OrderUpdateWithWhereUniqueWithoutDestinationInput>>>;
  updateMany?: Maybe<Array<Maybe<OrderUpdateManyWithWhereWithoutDestinationInput>>>;
  deleteMany?: Maybe<Array<Maybe<OrderScalarWhereInput>>>;
};

export type OrderUncheckedUpdateManyWithoutOriginInput = {
  create?: Maybe<Array<Maybe<OrderCreateWithoutOriginInput>>>;
  connectOrCreate?: Maybe<Array<Maybe<OrderCreateOrConnectWithoutOriginInput>>>;
  upsert?: Maybe<Array<Maybe<OrderUpsertWithWhereUniqueWithoutOriginInput>>>;
  connect?: Maybe<Array<Maybe<OrderWhereUniqueInput>>>;
  set?: Maybe<Array<Maybe<OrderWhereUniqueInput>>>;
  disconnect?: Maybe<Array<Maybe<OrderWhereUniqueInput>>>;
  delete?: Maybe<Array<Maybe<OrderWhereUniqueInput>>>;
  update?: Maybe<Array<Maybe<OrderUpdateWithWhereUniqueWithoutOriginInput>>>;
  updateMany?: Maybe<Array<Maybe<OrderUpdateManyWithWhereWithoutOriginInput>>>;
  deleteMany?: Maybe<Array<Maybe<OrderScalarWhereInput>>>;
};

export type OrderUncheckedUpdateManyWithoutDestinationInput = {
  create?: Maybe<Array<Maybe<OrderCreateWithoutDestinationInput>>>;
  connectOrCreate?: Maybe<Array<Maybe<OrderCreateOrConnectWithoutDestinationInput>>>;
  upsert?: Maybe<Array<Maybe<OrderUpsertWithWhereUniqueWithoutDestinationInput>>>;
  connect?: Maybe<Array<Maybe<OrderWhereUniqueInput>>>;
  set?: Maybe<Array<Maybe<OrderWhereUniqueInput>>>;
  disconnect?: Maybe<Array<Maybe<OrderWhereUniqueInput>>>;
  delete?: Maybe<Array<Maybe<OrderWhereUniqueInput>>>;
  update?: Maybe<Array<Maybe<OrderUpdateWithWhereUniqueWithoutDestinationInput>>>;
  updateMany?: Maybe<Array<Maybe<OrderUpdateManyWithWhereWithoutDestinationInput>>>;
  deleteMany?: Maybe<Array<Maybe<OrderScalarWhereInput>>>;
};

export type NestedStringFilter = {
  equals?: Maybe<Scalars['String']>;
  in?: Maybe<Array<Maybe<Scalars['String']>>>;
  notIn?: Maybe<Array<Maybe<Scalars['String']>>>;
  lt?: Maybe<Scalars['String']>;
  lte?: Maybe<Scalars['String']>;
  gt?: Maybe<Scalars['String']>;
  gte?: Maybe<Scalars['String']>;
  contains?: Maybe<Scalars['String']>;
  startsWith?: Maybe<Scalars['String']>;
  endsWith?: Maybe<Scalars['String']>;
  not?: Maybe<NestedStringFilter>;
};

export type NestedEnumRoleFilter = {
  equals?: Maybe<Role>;
  in?: Maybe<Array<Maybe<Role>>>;
  notIn?: Maybe<Array<Maybe<Role>>>;
  not?: Maybe<NestedEnumRoleFilter>;
};

export type NestedEnumStatusFilter = {
  equals?: Maybe<Status>;
  in?: Maybe<Array<Maybe<Status>>>;
  notIn?: Maybe<Array<Maybe<Status>>>;
  not?: Maybe<NestedEnumStatusFilter>;
};

export type NestedStringWithAggregatesFilter = {
  equals?: Maybe<Scalars['String']>;
  in?: Maybe<Array<Maybe<Scalars['String']>>>;
  notIn?: Maybe<Array<Maybe<Scalars['String']>>>;
  lt?: Maybe<Scalars['String']>;
  lte?: Maybe<Scalars['String']>;
  gt?: Maybe<Scalars['String']>;
  gte?: Maybe<Scalars['String']>;
  contains?: Maybe<Scalars['String']>;
  startsWith?: Maybe<Scalars['String']>;
  endsWith?: Maybe<Scalars['String']>;
  not?: Maybe<NestedStringWithAggregatesFilter>;
  count?: Maybe<NestedIntFilter>;
  min?: Maybe<NestedStringFilter>;
  max?: Maybe<NestedStringFilter>;
};

export type NestedIntFilter = {
  equals?: Maybe<Scalars['Int']>;
  in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  notIn?: Maybe<Array<Maybe<Scalars['Int']>>>;
  lt?: Maybe<Scalars['Int']>;
  lte?: Maybe<Scalars['Int']>;
  gt?: Maybe<Scalars['Int']>;
  gte?: Maybe<Scalars['Int']>;
  not?: Maybe<NestedIntFilter>;
};

export type NestedEnumRoleWithAggregatesFilter = {
  equals?: Maybe<Role>;
  in?: Maybe<Array<Maybe<Role>>>;
  notIn?: Maybe<Array<Maybe<Role>>>;
  not?: Maybe<NestedEnumRoleWithAggregatesFilter>;
  count?: Maybe<NestedIntFilter>;
  min?: Maybe<NestedEnumRoleFilter>;
  max?: Maybe<NestedEnumRoleFilter>;
};

export type NestedEnumStatusWithAggregatesFilter = {
  equals?: Maybe<Status>;
  in?: Maybe<Array<Maybe<Status>>>;
  notIn?: Maybe<Array<Maybe<Status>>>;
  not?: Maybe<NestedEnumStatusWithAggregatesFilter>;
  count?: Maybe<NestedIntFilter>;
  min?: Maybe<NestedEnumStatusFilter>;
  max?: Maybe<NestedEnumStatusFilter>;
};

export type NestedBoolFilter = {
  equals?: Maybe<Scalars['Boolean']>;
  not?: Maybe<NestedBoolFilter>;
};

export type NestedEnumCompanyTypeFilter = {
  equals?: Maybe<CompanyType>;
  in?: Maybe<Array<Maybe<CompanyType>>>;
  notIn?: Maybe<Array<Maybe<CompanyType>>>;
  not?: Maybe<NestedEnumCompanyTypeFilter>;
};

export type NestedIntWithAggregatesFilter = {
  equals?: Maybe<Scalars['Int']>;
  in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  notIn?: Maybe<Array<Maybe<Scalars['Int']>>>;
  lt?: Maybe<Scalars['Int']>;
  lte?: Maybe<Scalars['Int']>;
  gt?: Maybe<Scalars['Int']>;
  gte?: Maybe<Scalars['Int']>;
  not?: Maybe<NestedIntWithAggregatesFilter>;
  count?: Maybe<NestedIntFilter>;
  avg?: Maybe<NestedFloatFilter>;
  sum?: Maybe<NestedIntFilter>;
  min?: Maybe<NestedIntFilter>;
  max?: Maybe<NestedIntFilter>;
};

export type NestedFloatFilter = {
  equals?: Maybe<Scalars['Float']>;
  in?: Maybe<Array<Maybe<Scalars['Float']>>>;
  notIn?: Maybe<Array<Maybe<Scalars['Float']>>>;
  lt?: Maybe<Scalars['Float']>;
  lte?: Maybe<Scalars['Float']>;
  gt?: Maybe<Scalars['Float']>;
  gte?: Maybe<Scalars['Float']>;
  not?: Maybe<NestedFloatFilter>;
};

export type NestedBoolWithAggregatesFilter = {
  equals?: Maybe<Scalars['Boolean']>;
  not?: Maybe<NestedBoolWithAggregatesFilter>;
  count?: Maybe<NestedIntFilter>;
  min?: Maybe<NestedBoolFilter>;
  max?: Maybe<NestedBoolFilter>;
};

export type NestedEnumCompanyTypeWithAggregatesFilter = {
  equals?: Maybe<CompanyType>;
  in?: Maybe<Array<Maybe<CompanyType>>>;
  notIn?: Maybe<Array<Maybe<CompanyType>>>;
  not?: Maybe<NestedEnumCompanyTypeWithAggregatesFilter>;
  count?: Maybe<NestedIntFilter>;
  min?: Maybe<NestedEnumCompanyTypeFilter>;
  max?: Maybe<NestedEnumCompanyTypeFilter>;
};

export type NestedEnumEmployeeStatusFilter = {
  equals?: Maybe<EmployeeStatus>;
  in?: Maybe<Array<Maybe<EmployeeStatus>>>;
  notIn?: Maybe<Array<Maybe<EmployeeStatus>>>;
  not?: Maybe<NestedEnumEmployeeStatusFilter>;
};

export type NestedEnumEmployeeStatusWithAggregatesFilter = {
  equals?: Maybe<EmployeeStatus>;
  in?: Maybe<Array<Maybe<EmployeeStatus>>>;
  notIn?: Maybe<Array<Maybe<EmployeeStatus>>>;
  not?: Maybe<NestedEnumEmployeeStatusWithAggregatesFilter>;
  count?: Maybe<NestedIntFilter>;
  min?: Maybe<NestedEnumEmployeeStatusFilter>;
  max?: Maybe<NestedEnumEmployeeStatusFilter>;
};

export type NestedDateTimeNullableFilter = {
  equals?: Maybe<Scalars['DateTime']>;
  in?: Maybe<Array<Maybe<Scalars['DateTime']>>>;
  notIn?: Maybe<Array<Maybe<Scalars['DateTime']>>>;
  lt?: Maybe<Scalars['DateTime']>;
  lte?: Maybe<Scalars['DateTime']>;
  gt?: Maybe<Scalars['DateTime']>;
  gte?: Maybe<Scalars['DateTime']>;
  not?: Maybe<NestedDateTimeNullableFilter>;
};

export type NestedEnumCargoTypeNullableFilter = {
  equals?: Maybe<CargoType>;
  in?: Maybe<Array<Maybe<CargoType>>>;
  notIn?: Maybe<Array<Maybe<CargoType>>>;
  not?: Maybe<NestedEnumCargoTypeNullableFilter>;
};

export type NestedStringNullableFilter = {
  equals?: Maybe<Scalars['String']>;
  in?: Maybe<Array<Maybe<Scalars['String']>>>;
  notIn?: Maybe<Array<Maybe<Scalars['String']>>>;
  lt?: Maybe<Scalars['String']>;
  lte?: Maybe<Scalars['String']>;
  gt?: Maybe<Scalars['String']>;
  gte?: Maybe<Scalars['String']>;
  contains?: Maybe<Scalars['String']>;
  startsWith?: Maybe<Scalars['String']>;
  endsWith?: Maybe<Scalars['String']>;
  not?: Maybe<NestedStringNullableFilter>;
};

export type NestedIntNullableFilter = {
  equals?: Maybe<Scalars['Int']>;
  in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  notIn?: Maybe<Array<Maybe<Scalars['Int']>>>;
  lt?: Maybe<Scalars['Int']>;
  lte?: Maybe<Scalars['Int']>;
  gt?: Maybe<Scalars['Int']>;
  gte?: Maybe<Scalars['Int']>;
  not?: Maybe<NestedIntNullableFilter>;
};

export type NestedFloatNullableFilter = {
  equals?: Maybe<Scalars['Float']>;
  in?: Maybe<Array<Maybe<Scalars['Float']>>>;
  notIn?: Maybe<Array<Maybe<Scalars['Float']>>>;
  lt?: Maybe<Scalars['Float']>;
  lte?: Maybe<Scalars['Float']>;
  gt?: Maybe<Scalars['Float']>;
  gte?: Maybe<Scalars['Float']>;
  not?: Maybe<NestedFloatNullableFilter>;
};

export type NestedEnumOrderStatusNullableFilter = {
  equals?: Maybe<OrderStatus>;
  in?: Maybe<Array<Maybe<OrderStatus>>>;
  notIn?: Maybe<Array<Maybe<OrderStatus>>>;
  not?: Maybe<NestedEnumOrderStatusNullableFilter>;
};

export type NestedEnumTruckTypeNullableFilter = {
  equals?: Maybe<TruckType>;
  in?: Maybe<Array<Maybe<TruckType>>>;
  notIn?: Maybe<Array<Maybe<TruckType>>>;
  not?: Maybe<NestedEnumTruckTypeNullableFilter>;
};

export type NestedDateTimeNullableWithAggregatesFilter = {
  equals?: Maybe<Scalars['DateTime']>;
  in?: Maybe<Array<Maybe<Scalars['DateTime']>>>;
  notIn?: Maybe<Array<Maybe<Scalars['DateTime']>>>;
  lt?: Maybe<Scalars['DateTime']>;
  lte?: Maybe<Scalars['DateTime']>;
  gt?: Maybe<Scalars['DateTime']>;
  gte?: Maybe<Scalars['DateTime']>;
  not?: Maybe<NestedDateTimeNullableWithAggregatesFilter>;
  count?: Maybe<NestedIntNullableFilter>;
  min?: Maybe<NestedDateTimeNullableFilter>;
  max?: Maybe<NestedDateTimeNullableFilter>;
};

export type NestedEnumCargoTypeNullableWithAggregatesFilter = {
  equals?: Maybe<CargoType>;
  in?: Maybe<Array<Maybe<CargoType>>>;
  notIn?: Maybe<Array<Maybe<CargoType>>>;
  not?: Maybe<NestedEnumCargoTypeNullableWithAggregatesFilter>;
  count?: Maybe<NestedIntNullableFilter>;
  min?: Maybe<NestedEnumCargoTypeNullableFilter>;
  max?: Maybe<NestedEnumCargoTypeNullableFilter>;
};

export type NestedStringNullableWithAggregatesFilter = {
  equals?: Maybe<Scalars['String']>;
  in?: Maybe<Array<Maybe<Scalars['String']>>>;
  notIn?: Maybe<Array<Maybe<Scalars['String']>>>;
  lt?: Maybe<Scalars['String']>;
  lte?: Maybe<Scalars['String']>;
  gt?: Maybe<Scalars['String']>;
  gte?: Maybe<Scalars['String']>;
  contains?: Maybe<Scalars['String']>;
  startsWith?: Maybe<Scalars['String']>;
  endsWith?: Maybe<Scalars['String']>;
  not?: Maybe<NestedStringNullableWithAggregatesFilter>;
  count?: Maybe<NestedIntNullableFilter>;
  min?: Maybe<NestedStringNullableFilter>;
  max?: Maybe<NestedStringNullableFilter>;
};

export type NestedIntNullableWithAggregatesFilter = {
  equals?: Maybe<Scalars['Int']>;
  in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  notIn?: Maybe<Array<Maybe<Scalars['Int']>>>;
  lt?: Maybe<Scalars['Int']>;
  lte?: Maybe<Scalars['Int']>;
  gt?: Maybe<Scalars['Int']>;
  gte?: Maybe<Scalars['Int']>;
  not?: Maybe<NestedIntNullableWithAggregatesFilter>;
  count?: Maybe<NestedIntNullableFilter>;
  avg?: Maybe<NestedFloatNullableFilter>;
  sum?: Maybe<NestedIntNullableFilter>;
  min?: Maybe<NestedIntNullableFilter>;
  max?: Maybe<NestedIntNullableFilter>;
};

export type NestedFloatNullableWithAggregatesFilter = {
  equals?: Maybe<Scalars['Float']>;
  in?: Maybe<Array<Maybe<Scalars['Float']>>>;
  notIn?: Maybe<Array<Maybe<Scalars['Float']>>>;
  lt?: Maybe<Scalars['Float']>;
  lte?: Maybe<Scalars['Float']>;
  gt?: Maybe<Scalars['Float']>;
  gte?: Maybe<Scalars['Float']>;
  not?: Maybe<NestedFloatNullableWithAggregatesFilter>;
  count?: Maybe<NestedIntNullableFilter>;
  avg?: Maybe<NestedFloatNullableFilter>;
  sum?: Maybe<NestedFloatNullableFilter>;
  min?: Maybe<NestedFloatNullableFilter>;
  max?: Maybe<NestedFloatNullableFilter>;
};

export type NestedEnumOrderStatusNullableWithAggregatesFilter = {
  equals?: Maybe<OrderStatus>;
  in?: Maybe<Array<Maybe<OrderStatus>>>;
  notIn?: Maybe<Array<Maybe<OrderStatus>>>;
  not?: Maybe<NestedEnumOrderStatusNullableWithAggregatesFilter>;
  count?: Maybe<NestedIntNullableFilter>;
  min?: Maybe<NestedEnumOrderStatusNullableFilter>;
  max?: Maybe<NestedEnumOrderStatusNullableFilter>;
};

export type NestedEnumTruckTypeNullableWithAggregatesFilter = {
  equals?: Maybe<TruckType>;
  in?: Maybe<Array<Maybe<TruckType>>>;
  notIn?: Maybe<Array<Maybe<TruckType>>>;
  not?: Maybe<NestedEnumTruckTypeNullableWithAggregatesFilter>;
  count?: Maybe<NestedIntNullableFilter>;
  min?: Maybe<NestedEnumTruckTypeNullableFilter>;
  max?: Maybe<NestedEnumTruckTypeNullableFilter>;
};

export type NestedJsonNullableFilter = {
  equals?: Maybe<Scalars['Json']>;
  not?: Maybe<Scalars['Json']>;
};

export type NestedDateTimeFilter = {
  equals?: Maybe<Scalars['DateTime']>;
  in?: Maybe<Array<Maybe<Scalars['DateTime']>>>;
  notIn?: Maybe<Array<Maybe<Scalars['DateTime']>>>;
  lt?: Maybe<Scalars['DateTime']>;
  lte?: Maybe<Scalars['DateTime']>;
  gt?: Maybe<Scalars['DateTime']>;
  gte?: Maybe<Scalars['DateTime']>;
  not?: Maybe<NestedDateTimeFilter>;
};

export type NestedDateTimeWithAggregatesFilter = {
  equals?: Maybe<Scalars['DateTime']>;
  in?: Maybe<Array<Maybe<Scalars['DateTime']>>>;
  notIn?: Maybe<Array<Maybe<Scalars['DateTime']>>>;
  lt?: Maybe<Scalars['DateTime']>;
  lte?: Maybe<Scalars['DateTime']>;
  gt?: Maybe<Scalars['DateTime']>;
  gte?: Maybe<Scalars['DateTime']>;
  not?: Maybe<NestedDateTimeWithAggregatesFilter>;
  count?: Maybe<NestedIntFilter>;
  min?: Maybe<NestedDateTimeFilter>;
  max?: Maybe<NestedDateTimeFilter>;
};

export type NestedFloatWithAggregatesFilter = {
  equals?: Maybe<Scalars['Float']>;
  in?: Maybe<Array<Maybe<Scalars['Float']>>>;
  notIn?: Maybe<Array<Maybe<Scalars['Float']>>>;
  lt?: Maybe<Scalars['Float']>;
  lte?: Maybe<Scalars['Float']>;
  gt?: Maybe<Scalars['Float']>;
  gte?: Maybe<Scalars['Float']>;
  not?: Maybe<NestedFloatWithAggregatesFilter>;
  count?: Maybe<NestedIntFilter>;
  avg?: Maybe<NestedFloatFilter>;
  sum?: Maybe<NestedFloatFilter>;
  min?: Maybe<NestedFloatFilter>;
  max?: Maybe<NestedFloatFilter>;
};

export type EmployeeCreateWithoutWorksForInput = {
  name: Scalars['String'];
  dni: Scalars['String'];
  status?: Maybe<EmployeeStatus>;
  assigned?: Maybe<OrderCreateNestedManyWithoutCarriedByInput>;
};

export type EmployeeUncheckedCreateWithoutWorksForInput = {
  id?: Maybe<Scalars['Int']>;
  name: Scalars['String'];
  dni: Scalars['String'];
  status?: Maybe<EmployeeStatus>;
  assigned?: Maybe<OrderUncheckedCreateNestedManyWithoutCarriedByInput>;
};

export type EmployeeCreateOrConnectWithoutWorksForInput = {
  where: EmployeeWhereUniqueInput;
  create: EmployeeUncheckedCreateWithoutWorksForInput;
};

export type OrderCreateWithoutRequestedByInput = {
  assignedAt?: Maybe<Scalars['DateTime']>;
  cargoType?: Maybe<CargoType>;
  comments?: Maybe<Scalars['String']>;
  completedAt?: Maybe<Scalars['DateTime']>;
  distance?: Maybe<Scalars['Int']>;
  ourRate?: Maybe<Scalars['Float']>;
  range?: Maybe<Scalars['String']>;
  requestedAt?: Maybe<Scalars['DateTime']>;
  status?: Maybe<OrderStatus>;
  theirRate?: Maybe<Scalars['Float']>;
  truckType?: Maybe<TruckType>;
  units?: Maybe<Scalars['Float']>;
  waypoints?: Maybe<Scalars['Json']>;
  weight?: Maybe<Scalars['Float']>;
  receipts?: Maybe<Array<Maybe<Scalars['String']>>>;
  assignedTo?: Maybe<CompanyCreateNestedOneWithoutAssignedInput>;
  carriedBy?: Maybe<EmployeeCreateNestedOneWithoutAssignedInput>;
  destination?: Maybe<ShedCreateNestedOneWithoutAsDestinationInput>;
  invoices?: Maybe<InvoiceCreateNestedManyWithoutItemsInput>;
  origin?: Maybe<ShedCreateNestedOneWithoutAsOriginInput>;
};

export type OrderUncheckedCreateWithoutRequestedByInput = {
  id?: Maybe<Scalars['Int']>;
  assignedAt?: Maybe<Scalars['DateTime']>;
  cargoType?: Maybe<CargoType>;
  comments?: Maybe<Scalars['String']>;
  completedAt?: Maybe<Scalars['DateTime']>;
  distance?: Maybe<Scalars['Int']>;
  ourRate?: Maybe<Scalars['Float']>;
  range?: Maybe<Scalars['String']>;
  requestedAt?: Maybe<Scalars['DateTime']>;
  status?: Maybe<OrderStatus>;
  theirRate?: Maybe<Scalars['Float']>;
  truckType?: Maybe<TruckType>;
  units?: Maybe<Scalars['Float']>;
  waypoints?: Maybe<Scalars['Json']>;
  weight?: Maybe<Scalars['Float']>;
  assignedToId?: Maybe<Scalars['Int']>;
  carriedById?: Maybe<Scalars['Int']>;
  destinationId?: Maybe<Scalars['Int']>;
  originId?: Maybe<Scalars['Int']>;
  receipts?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type OrderCreateOrConnectWithoutRequestedByInput = {
  where: OrderWhereUniqueInput;
  create: OrderUncheckedCreateWithoutRequestedByInput;
};

export type OrderCreateWithoutAssignedToInput = {
  assignedAt?: Maybe<Scalars['DateTime']>;
  cargoType?: Maybe<CargoType>;
  comments?: Maybe<Scalars['String']>;
  completedAt?: Maybe<Scalars['DateTime']>;
  distance?: Maybe<Scalars['Int']>;
  ourRate?: Maybe<Scalars['Float']>;
  range?: Maybe<Scalars['String']>;
  requestedAt?: Maybe<Scalars['DateTime']>;
  status?: Maybe<OrderStatus>;
  theirRate?: Maybe<Scalars['Float']>;
  truckType?: Maybe<TruckType>;
  units?: Maybe<Scalars['Float']>;
  waypoints?: Maybe<Scalars['Json']>;
  weight?: Maybe<Scalars['Float']>;
  receipts?: Maybe<Array<Maybe<Scalars['String']>>>;
  carriedBy?: Maybe<EmployeeCreateNestedOneWithoutAssignedInput>;
  destination?: Maybe<ShedCreateNestedOneWithoutAsDestinationInput>;
  invoices?: Maybe<InvoiceCreateNestedManyWithoutItemsInput>;
  origin?: Maybe<ShedCreateNestedOneWithoutAsOriginInput>;
  requestedBy?: Maybe<CompanyCreateNestedOneWithoutRequestedInput>;
};

export type OrderUncheckedCreateWithoutAssignedToInput = {
  id?: Maybe<Scalars['Int']>;
  assignedAt?: Maybe<Scalars['DateTime']>;
  cargoType?: Maybe<CargoType>;
  comments?: Maybe<Scalars['String']>;
  completedAt?: Maybe<Scalars['DateTime']>;
  distance?: Maybe<Scalars['Int']>;
  ourRate?: Maybe<Scalars['Float']>;
  range?: Maybe<Scalars['String']>;
  requestedAt?: Maybe<Scalars['DateTime']>;
  status?: Maybe<OrderStatus>;
  theirRate?: Maybe<Scalars['Float']>;
  truckType?: Maybe<TruckType>;
  units?: Maybe<Scalars['Float']>;
  waypoints?: Maybe<Scalars['Json']>;
  weight?: Maybe<Scalars['Float']>;
  carriedById?: Maybe<Scalars['Int']>;
  destinationId?: Maybe<Scalars['Int']>;
  originId?: Maybe<Scalars['Int']>;
  requestedById?: Maybe<Scalars['Int']>;
  receipts?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type OrderCreateOrConnectWithoutAssignedToInput = {
  where: OrderWhereUniqueInput;
  create: OrderUncheckedCreateWithoutAssignedToInput;
};

export type PaymentCreateWithoutSourceInput = {
  date?: Maybe<Scalars['DateTime']>;
  amount: Scalars['Float'];
  reference?: Maybe<BillCreateNestedOneWithoutPaymentsInput>;
};

export type PaymentUncheckedCreateWithoutSourceInput = {
  id?: Maybe<Scalars['Int']>;
  date?: Maybe<Scalars['DateTime']>;
  amount: Scalars['Float'];
  referenceId?: Maybe<Scalars['Int']>;
};

export type PaymentCreateOrConnectWithoutSourceInput = {
  where: PaymentWhereUniqueInput;
  create: PaymentUncheckedCreateWithoutSourceInput;
};

export type InvoiceCreateWithoutCompanyInput = {
  date?: Maybe<Scalars['DateTime']>;
  total: Scalars['Float'];
  items?: Maybe<OrderCreateNestedManyWithoutInvoicesInput>;
  bill?: Maybe<BillCreateNestedOneWithoutItemsInput>;
};

export type InvoiceUncheckedCreateWithoutCompanyInput = {
  id?: Maybe<Scalars['Int']>;
  date?: Maybe<Scalars['DateTime']>;
  total: Scalars['Float'];
  billId?: Maybe<Scalars['Int']>;
};

export type InvoiceCreateOrConnectWithoutCompanyInput = {
  where: InvoiceWhereUniqueInput;
  create: InvoiceUncheckedCreateWithoutCompanyInput;
};

export type BillCreateWithoutCompanyInput = {
  date?: Maybe<Scalars['DateTime']>;
  amount: Scalars['Float'];
  vat: Scalars['Float'];
  items?: Maybe<InvoiceCreateNestedManyWithoutBillInput>;
  payments?: Maybe<PaymentCreateNestedManyWithoutReferenceInput>;
};

export type BillUncheckedCreateWithoutCompanyInput = {
  id?: Maybe<Scalars['Int']>;
  date?: Maybe<Scalars['DateTime']>;
  amount: Scalars['Float'];
  vat: Scalars['Float'];
  items?: Maybe<InvoiceUncheckedCreateNestedManyWithoutBillInput>;
  payments?: Maybe<PaymentUncheckedCreateNestedManyWithoutReferenceInput>;
};

export type BillCreateOrConnectWithoutCompanyInput = {
  where: BillWhereUniqueInput;
  create: BillUncheckedCreateWithoutCompanyInput;
};

export type EmployeeUpsertWithWhereUniqueWithoutWorksForInput = {
  where: EmployeeWhereUniqueInput;
  update: EmployeeUncheckedUpdateWithoutWorksForInput;
  create: EmployeeUncheckedCreateWithoutWorksForInput;
};

export type EmployeeUpdateWithWhereUniqueWithoutWorksForInput = {
  where: EmployeeWhereUniqueInput;
  data: EmployeeUncheckedUpdateWithoutWorksForInput;
};

export type EmployeeUpdateManyWithWhereWithoutWorksForInput = {
  where: EmployeeScalarWhereInput;
  data: EmployeeUncheckedUpdateManyWithoutEmployeesInput;
};

export type EmployeeScalarWhereInput = {
  AND?: Maybe<Array<Maybe<EmployeeScalarWhereInput>>>;
  OR?: Maybe<Array<Maybe<EmployeeScalarWhereInput>>>;
  NOT?: Maybe<Array<Maybe<EmployeeScalarWhereInput>>>;
  id?: Maybe<IntFilter>;
  name?: Maybe<StringFilter>;
  dni?: Maybe<StringFilter>;
  companyId?: Maybe<IntFilter>;
  status?: Maybe<EnumEmployeeStatusFilter>;
};

export type OrderUpsertWithWhereUniqueWithoutRequestedByInput = {
  where: OrderWhereUniqueInput;
  update: OrderUncheckedUpdateWithoutRequestedByInput;
  create: OrderUncheckedCreateWithoutRequestedByInput;
};

export type OrderUpdateWithWhereUniqueWithoutRequestedByInput = {
  where: OrderWhereUniqueInput;
  data: OrderUncheckedUpdateWithoutRequestedByInput;
};

export type OrderUpdateManyWithWhereWithoutRequestedByInput = {
  where: OrderScalarWhereInput;
  data: OrderUncheckedUpdateManyWithoutRequestedInput;
};

export type OrderScalarWhereInput = {
  AND?: Maybe<Array<Maybe<OrderScalarWhereInput>>>;
  OR?: Maybe<Array<Maybe<OrderScalarWhereInput>>>;
  NOT?: Maybe<Array<Maybe<OrderScalarWhereInput>>>;
  id?: Maybe<IntFilter>;
  assignedAt?: Maybe<DateTimeNullableFilter>;
  cargoType?: Maybe<EnumCargoTypeNullableFilter>;
  comments?: Maybe<StringNullableFilter>;
  completedAt?: Maybe<DateTimeNullableFilter>;
  distance?: Maybe<IntNullableFilter>;
  ourRate?: Maybe<FloatNullableFilter>;
  range?: Maybe<StringNullableFilter>;
  receipts?: Maybe<StringNullableListFilter>;
  requestedAt?: Maybe<DateTimeNullableFilter>;
  status?: Maybe<EnumOrderStatusNullableFilter>;
  theirRate?: Maybe<FloatNullableFilter>;
  truckType?: Maybe<EnumTruckTypeNullableFilter>;
  units?: Maybe<FloatNullableFilter>;
  waypoints?: Maybe<JsonNullableFilter>;
  weight?: Maybe<FloatNullableFilter>;
  assignedToId?: Maybe<IntNullableFilter>;
  carriedById?: Maybe<IntNullableFilter>;
  destinationId?: Maybe<IntNullableFilter>;
  originId?: Maybe<IntNullableFilter>;
  requestedById?: Maybe<IntNullableFilter>;
};

export type OrderUpsertWithWhereUniqueWithoutAssignedToInput = {
  where: OrderWhereUniqueInput;
  update: OrderUncheckedUpdateWithoutAssignedToInput;
  create: OrderUncheckedCreateWithoutAssignedToInput;
};

export type OrderUpdateWithWhereUniqueWithoutAssignedToInput = {
  where: OrderWhereUniqueInput;
  data: OrderUncheckedUpdateWithoutAssignedToInput;
};

export type OrderUpdateManyWithWhereWithoutAssignedToInput = {
  where: OrderScalarWhereInput;
  data: OrderUncheckedUpdateManyWithoutAssignedInput;
};

export type PaymentUpsertWithWhereUniqueWithoutSourceInput = {
  where: PaymentWhereUniqueInput;
  update: PaymentUncheckedUpdateWithoutSourceInput;
  create: PaymentUncheckedCreateWithoutSourceInput;
};

export type PaymentUpdateWithWhereUniqueWithoutSourceInput = {
  where: PaymentWhereUniqueInput;
  data: PaymentUncheckedUpdateWithoutSourceInput;
};

export type PaymentUpdateManyWithWhereWithoutSourceInput = {
  where: PaymentScalarWhereInput;
  data: PaymentUncheckedUpdateManyWithoutPaymentsInput;
};

export type PaymentScalarWhereInput = {
  AND?: Maybe<Array<Maybe<PaymentScalarWhereInput>>>;
  OR?: Maybe<Array<Maybe<PaymentScalarWhereInput>>>;
  NOT?: Maybe<Array<Maybe<PaymentScalarWhereInput>>>;
  id?: Maybe<IntFilter>;
  date?: Maybe<DateTimeFilter>;
  sourceId?: Maybe<IntFilter>;
  amount?: Maybe<FloatFilter>;
  referenceId?: Maybe<IntNullableFilter>;
};

export type InvoiceUpsertWithWhereUniqueWithoutCompanyInput = {
  where: InvoiceWhereUniqueInput;
  update: InvoiceUncheckedUpdateWithoutCompanyInput;
  create: InvoiceUncheckedCreateWithoutCompanyInput;
};

export type InvoiceUpdateWithWhereUniqueWithoutCompanyInput = {
  where: InvoiceWhereUniqueInput;
  data: InvoiceUncheckedUpdateWithoutCompanyInput;
};

export type InvoiceUpdateManyWithWhereWithoutCompanyInput = {
  where: InvoiceScalarWhereInput;
  data: InvoiceUncheckedUpdateManyWithoutInvoicesInput;
};

export type InvoiceScalarWhereInput = {
  AND?: Maybe<Array<Maybe<InvoiceScalarWhereInput>>>;
  OR?: Maybe<Array<Maybe<InvoiceScalarWhereInput>>>;
  NOT?: Maybe<Array<Maybe<InvoiceScalarWhereInput>>>;
  id?: Maybe<IntFilter>;
  date?: Maybe<DateTimeFilter>;
  companyId?: Maybe<IntFilter>;
  total?: Maybe<FloatFilter>;
  billId?: Maybe<IntNullableFilter>;
};

export type BillUpsertWithWhereUniqueWithoutCompanyInput = {
  where: BillWhereUniqueInput;
  update: BillUncheckedUpdateWithoutCompanyInput;
  create: BillUncheckedCreateWithoutCompanyInput;
};

export type BillUpdateWithWhereUniqueWithoutCompanyInput = {
  where: BillWhereUniqueInput;
  data: BillUncheckedUpdateWithoutCompanyInput;
};

export type BillUpdateManyWithWhereWithoutCompanyInput = {
  where: BillScalarWhereInput;
  data: BillUncheckedUpdateManyWithoutBillsInput;
};

export type BillScalarWhereInput = {
  AND?: Maybe<Array<Maybe<BillScalarWhereInput>>>;
  OR?: Maybe<Array<Maybe<BillScalarWhereInput>>>;
  NOT?: Maybe<Array<Maybe<BillScalarWhereInput>>>;
  id?: Maybe<IntFilter>;
  date?: Maybe<DateTimeFilter>;
  companyId?: Maybe<IntFilter>;
  amount?: Maybe<FloatFilter>;
  vat?: Maybe<FloatFilter>;
};

export type CompanyCreateWithoutEmployeesInput = {
  name: Scalars['String'];
  cuit: Scalars['String'];
  isCustomer?: Maybe<Scalars['Boolean']>;
  isSupplier?: Maybe<Scalars['Boolean']>;
  type?: Maybe<CompanyType>;
  requested?: Maybe<OrderCreateNestedManyWithoutRequestedByInput>;
  assigned?: Maybe<OrderCreateNestedManyWithoutAssignedToInput>;
  payments?: Maybe<PaymentCreateNestedManyWithoutSourceInput>;
  invoices?: Maybe<InvoiceCreateNestedManyWithoutCompanyInput>;
  bills?: Maybe<BillCreateNestedManyWithoutCompanyInput>;
};

export type CompanyUncheckedCreateWithoutEmployeesInput = {
  id?: Maybe<Scalars['Int']>;
  name: Scalars['String'];
  cuit: Scalars['String'];
  isCustomer?: Maybe<Scalars['Boolean']>;
  isSupplier?: Maybe<Scalars['Boolean']>;
  type?: Maybe<CompanyType>;
  requested?: Maybe<OrderUncheckedCreateNestedManyWithoutRequestedByInput>;
  assigned?: Maybe<OrderUncheckedCreateNestedManyWithoutAssignedToInput>;
  payments?: Maybe<PaymentUncheckedCreateNestedManyWithoutSourceInput>;
  invoices?: Maybe<InvoiceUncheckedCreateNestedManyWithoutCompanyInput>;
  bills?: Maybe<BillUncheckedCreateNestedManyWithoutCompanyInput>;
};

export type CompanyCreateOrConnectWithoutEmployeesInput = {
  where: CompanyWhereUniqueInput;
  create: CompanyUncheckedCreateWithoutEmployeesInput;
};

export type OrderCreateWithoutCarriedByInput = {
  assignedAt?: Maybe<Scalars['DateTime']>;
  cargoType?: Maybe<CargoType>;
  comments?: Maybe<Scalars['String']>;
  completedAt?: Maybe<Scalars['DateTime']>;
  distance?: Maybe<Scalars['Int']>;
  ourRate?: Maybe<Scalars['Float']>;
  range?: Maybe<Scalars['String']>;
  requestedAt?: Maybe<Scalars['DateTime']>;
  status?: Maybe<OrderStatus>;
  theirRate?: Maybe<Scalars['Float']>;
  truckType?: Maybe<TruckType>;
  units?: Maybe<Scalars['Float']>;
  waypoints?: Maybe<Scalars['Json']>;
  weight?: Maybe<Scalars['Float']>;
  receipts?: Maybe<Array<Maybe<Scalars['String']>>>;
  assignedTo?: Maybe<CompanyCreateNestedOneWithoutAssignedInput>;
  destination?: Maybe<ShedCreateNestedOneWithoutAsDestinationInput>;
  invoices?: Maybe<InvoiceCreateNestedManyWithoutItemsInput>;
  origin?: Maybe<ShedCreateNestedOneWithoutAsOriginInput>;
  requestedBy?: Maybe<CompanyCreateNestedOneWithoutRequestedInput>;
};

export type OrderUncheckedCreateWithoutCarriedByInput = {
  id?: Maybe<Scalars['Int']>;
  assignedAt?: Maybe<Scalars['DateTime']>;
  cargoType?: Maybe<CargoType>;
  comments?: Maybe<Scalars['String']>;
  completedAt?: Maybe<Scalars['DateTime']>;
  distance?: Maybe<Scalars['Int']>;
  ourRate?: Maybe<Scalars['Float']>;
  range?: Maybe<Scalars['String']>;
  requestedAt?: Maybe<Scalars['DateTime']>;
  status?: Maybe<OrderStatus>;
  theirRate?: Maybe<Scalars['Float']>;
  truckType?: Maybe<TruckType>;
  units?: Maybe<Scalars['Float']>;
  waypoints?: Maybe<Scalars['Json']>;
  weight?: Maybe<Scalars['Float']>;
  assignedToId?: Maybe<Scalars['Int']>;
  destinationId?: Maybe<Scalars['Int']>;
  originId?: Maybe<Scalars['Int']>;
  requestedById?: Maybe<Scalars['Int']>;
  receipts?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type OrderCreateOrConnectWithoutCarriedByInput = {
  where: OrderWhereUniqueInput;
  create: OrderUncheckedCreateWithoutCarriedByInput;
};

export type CompanyUpsertWithoutEmployeesInput = {
  update: CompanyUncheckedUpdateWithoutEmployeesInput;
  create: CompanyUncheckedCreateWithoutEmployeesInput;
};

export type CompanyUpdateWithoutEmployeesInput = {
  name?: Maybe<StringFieldUpdateOperationsInput>;
  cuit?: Maybe<StringFieldUpdateOperationsInput>;
  isCustomer?: Maybe<BoolFieldUpdateOperationsInput>;
  isSupplier?: Maybe<BoolFieldUpdateOperationsInput>;
  type?: Maybe<EnumCompanyTypeFieldUpdateOperationsInput>;
  requested?: Maybe<OrderUpdateManyWithoutRequestedByInput>;
  assigned?: Maybe<OrderUpdateManyWithoutAssignedToInput>;
  payments?: Maybe<PaymentUpdateManyWithoutSourceInput>;
  invoices?: Maybe<InvoiceUpdateManyWithoutCompanyInput>;
  bills?: Maybe<BillUpdateManyWithoutCompanyInput>;
};

export type CompanyUncheckedUpdateWithoutEmployeesInput = {
  id?: Maybe<IntFieldUpdateOperationsInput>;
  name?: Maybe<StringFieldUpdateOperationsInput>;
  cuit?: Maybe<StringFieldUpdateOperationsInput>;
  isCustomer?: Maybe<BoolFieldUpdateOperationsInput>;
  isSupplier?: Maybe<BoolFieldUpdateOperationsInput>;
  type?: Maybe<EnumCompanyTypeFieldUpdateOperationsInput>;
  requested?: Maybe<OrderUncheckedUpdateManyWithoutRequestedByInput>;
  assigned?: Maybe<OrderUncheckedUpdateManyWithoutAssignedToInput>;
  payments?: Maybe<PaymentUncheckedUpdateManyWithoutSourceInput>;
  invoices?: Maybe<InvoiceUncheckedUpdateManyWithoutCompanyInput>;
  bills?: Maybe<BillUncheckedUpdateManyWithoutCompanyInput>;
};

export type OrderUpsertWithWhereUniqueWithoutCarriedByInput = {
  where: OrderWhereUniqueInput;
  update: OrderUncheckedUpdateWithoutCarriedByInput;
  create: OrderUncheckedCreateWithoutCarriedByInput;
};

export type OrderUpdateWithWhereUniqueWithoutCarriedByInput = {
  where: OrderWhereUniqueInput;
  data: OrderUncheckedUpdateWithoutCarriedByInput;
};

export type OrderUpdateManyWithWhereWithoutCarriedByInput = {
  where: OrderScalarWhereInput;
  data: OrderUncheckedUpdateManyWithoutAssignedInput;
};

export type CompanyCreateWithoutAssignedInput = {
  name: Scalars['String'];
  cuit: Scalars['String'];
  isCustomer?: Maybe<Scalars['Boolean']>;
  isSupplier?: Maybe<Scalars['Boolean']>;
  type?: Maybe<CompanyType>;
  employees?: Maybe<EmployeeCreateNestedManyWithoutWorksForInput>;
  requested?: Maybe<OrderCreateNestedManyWithoutRequestedByInput>;
  payments?: Maybe<PaymentCreateNestedManyWithoutSourceInput>;
  invoices?: Maybe<InvoiceCreateNestedManyWithoutCompanyInput>;
  bills?: Maybe<BillCreateNestedManyWithoutCompanyInput>;
};

export type CompanyUncheckedCreateWithoutAssignedInput = {
  id?: Maybe<Scalars['Int']>;
  name: Scalars['String'];
  cuit: Scalars['String'];
  isCustomer?: Maybe<Scalars['Boolean']>;
  isSupplier?: Maybe<Scalars['Boolean']>;
  type?: Maybe<CompanyType>;
  employees?: Maybe<EmployeeUncheckedCreateNestedManyWithoutWorksForInput>;
  requested?: Maybe<OrderUncheckedCreateNestedManyWithoutRequestedByInput>;
  payments?: Maybe<PaymentUncheckedCreateNestedManyWithoutSourceInput>;
  invoices?: Maybe<InvoiceUncheckedCreateNestedManyWithoutCompanyInput>;
  bills?: Maybe<BillUncheckedCreateNestedManyWithoutCompanyInput>;
};

export type CompanyCreateOrConnectWithoutAssignedInput = {
  where: CompanyWhereUniqueInput;
  create: CompanyUncheckedCreateWithoutAssignedInput;
};

export type EmployeeCreateWithoutAssignedInput = {
  name: Scalars['String'];
  dni: Scalars['String'];
  status?: Maybe<EmployeeStatus>;
  worksFor: CompanyCreateNestedOneWithoutEmployeesInput;
};

export type EmployeeUncheckedCreateWithoutAssignedInput = {
  id?: Maybe<Scalars['Int']>;
  name: Scalars['String'];
  dni: Scalars['String'];
  companyId: Scalars['Int'];
  status?: Maybe<EmployeeStatus>;
};

export type EmployeeCreateOrConnectWithoutAssignedInput = {
  where: EmployeeWhereUniqueInput;
  create: EmployeeUncheckedCreateWithoutAssignedInput;
};

export type ShedCreateWithoutAsDestinationInput = {
  name: Scalars['String'];
  latitude: Scalars['Float'];
  longitude: Scalars['Float'];
  asOrigin?: Maybe<OrderCreateNestedManyWithoutOriginInput>;
};

export type ShedUncheckedCreateWithoutAsDestinationInput = {
  id?: Maybe<Scalars['Int']>;
  name: Scalars['String'];
  latitude: Scalars['Float'];
  longitude: Scalars['Float'];
  asOrigin?: Maybe<OrderUncheckedCreateNestedManyWithoutOriginInput>;
};

export type ShedCreateOrConnectWithoutAsDestinationInput = {
  where: ShedWhereUniqueInput;
  create: ShedUncheckedCreateWithoutAsDestinationInput;
};

export type InvoiceCreateWithoutItemsInput = {
  date?: Maybe<Scalars['DateTime']>;
  total: Scalars['Float'];
  company: CompanyCreateNestedOneWithoutInvoicesInput;
  bill?: Maybe<BillCreateNestedOneWithoutItemsInput>;
};

export type InvoiceUncheckedCreateWithoutItemsInput = {
  id?: Maybe<Scalars['Int']>;
  date?: Maybe<Scalars['DateTime']>;
  companyId: Scalars['Int'];
  total: Scalars['Float'];
  billId?: Maybe<Scalars['Int']>;
};

export type InvoiceCreateOrConnectWithoutItemsInput = {
  where: InvoiceWhereUniqueInput;
  create: InvoiceUncheckedCreateWithoutItemsInput;
};

export type ShedCreateWithoutAsOriginInput = {
  name: Scalars['String'];
  latitude: Scalars['Float'];
  longitude: Scalars['Float'];
  asDestination?: Maybe<OrderCreateNestedManyWithoutDestinationInput>;
};

export type ShedUncheckedCreateWithoutAsOriginInput = {
  id?: Maybe<Scalars['Int']>;
  name: Scalars['String'];
  latitude: Scalars['Float'];
  longitude: Scalars['Float'];
  asDestination?: Maybe<OrderUncheckedCreateNestedManyWithoutDestinationInput>;
};

export type ShedCreateOrConnectWithoutAsOriginInput = {
  where: ShedWhereUniqueInput;
  create: ShedUncheckedCreateWithoutAsOriginInput;
};

export type CompanyCreateWithoutRequestedInput = {
  name: Scalars['String'];
  cuit: Scalars['String'];
  isCustomer?: Maybe<Scalars['Boolean']>;
  isSupplier?: Maybe<Scalars['Boolean']>;
  type?: Maybe<CompanyType>;
  employees?: Maybe<EmployeeCreateNestedManyWithoutWorksForInput>;
  assigned?: Maybe<OrderCreateNestedManyWithoutAssignedToInput>;
  payments?: Maybe<PaymentCreateNestedManyWithoutSourceInput>;
  invoices?: Maybe<InvoiceCreateNestedManyWithoutCompanyInput>;
  bills?: Maybe<BillCreateNestedManyWithoutCompanyInput>;
};

export type CompanyUncheckedCreateWithoutRequestedInput = {
  id?: Maybe<Scalars['Int']>;
  name: Scalars['String'];
  cuit: Scalars['String'];
  isCustomer?: Maybe<Scalars['Boolean']>;
  isSupplier?: Maybe<Scalars['Boolean']>;
  type?: Maybe<CompanyType>;
  employees?: Maybe<EmployeeUncheckedCreateNestedManyWithoutWorksForInput>;
  assigned?: Maybe<OrderUncheckedCreateNestedManyWithoutAssignedToInput>;
  payments?: Maybe<PaymentUncheckedCreateNestedManyWithoutSourceInput>;
  invoices?: Maybe<InvoiceUncheckedCreateNestedManyWithoutCompanyInput>;
  bills?: Maybe<BillUncheckedCreateNestedManyWithoutCompanyInput>;
};

export type CompanyCreateOrConnectWithoutRequestedInput = {
  where: CompanyWhereUniqueInput;
  create: CompanyUncheckedCreateWithoutRequestedInput;
};

export type CompanyUpsertWithoutAssignedInput = {
  update: CompanyUncheckedUpdateWithoutAssignedInput;
  create: CompanyUncheckedCreateWithoutAssignedInput;
};

export type CompanyUpdateWithoutAssignedInput = {
  name?: Maybe<StringFieldUpdateOperationsInput>;
  cuit?: Maybe<StringFieldUpdateOperationsInput>;
  isCustomer?: Maybe<BoolFieldUpdateOperationsInput>;
  isSupplier?: Maybe<BoolFieldUpdateOperationsInput>;
  type?: Maybe<EnumCompanyTypeFieldUpdateOperationsInput>;
  employees?: Maybe<EmployeeUpdateManyWithoutWorksForInput>;
  requested?: Maybe<OrderUpdateManyWithoutRequestedByInput>;
  payments?: Maybe<PaymentUpdateManyWithoutSourceInput>;
  invoices?: Maybe<InvoiceUpdateManyWithoutCompanyInput>;
  bills?: Maybe<BillUpdateManyWithoutCompanyInput>;
};

export type CompanyUncheckedUpdateWithoutAssignedInput = {
  id?: Maybe<IntFieldUpdateOperationsInput>;
  name?: Maybe<StringFieldUpdateOperationsInput>;
  cuit?: Maybe<StringFieldUpdateOperationsInput>;
  isCustomer?: Maybe<BoolFieldUpdateOperationsInput>;
  isSupplier?: Maybe<BoolFieldUpdateOperationsInput>;
  type?: Maybe<EnumCompanyTypeFieldUpdateOperationsInput>;
  employees?: Maybe<EmployeeUncheckedUpdateManyWithoutWorksForInput>;
  requested?: Maybe<OrderUncheckedUpdateManyWithoutRequestedByInput>;
  payments?: Maybe<PaymentUncheckedUpdateManyWithoutSourceInput>;
  invoices?: Maybe<InvoiceUncheckedUpdateManyWithoutCompanyInput>;
  bills?: Maybe<BillUncheckedUpdateManyWithoutCompanyInput>;
};

export type EmployeeUpsertWithoutAssignedInput = {
  update: EmployeeUncheckedUpdateWithoutAssignedInput;
  create: EmployeeUncheckedCreateWithoutAssignedInput;
};

export type EmployeeUpdateWithoutAssignedInput = {
  name?: Maybe<StringFieldUpdateOperationsInput>;
  dni?: Maybe<StringFieldUpdateOperationsInput>;
  status?: Maybe<EnumEmployeeStatusFieldUpdateOperationsInput>;
  worksFor?: Maybe<CompanyUpdateOneRequiredWithoutEmployeesInput>;
};

export type EmployeeUncheckedUpdateWithoutAssignedInput = {
  id?: Maybe<IntFieldUpdateOperationsInput>;
  name?: Maybe<StringFieldUpdateOperationsInput>;
  dni?: Maybe<StringFieldUpdateOperationsInput>;
  companyId?: Maybe<IntFieldUpdateOperationsInput>;
  status?: Maybe<EnumEmployeeStatusFieldUpdateOperationsInput>;
};

export type ShedUpsertWithoutAsDestinationInput = {
  update: ShedUncheckedUpdateWithoutAsDestinationInput;
  create: ShedUncheckedCreateWithoutAsDestinationInput;
};

export type ShedUpdateWithoutAsDestinationInput = {
  name?: Maybe<StringFieldUpdateOperationsInput>;
  latitude?: Maybe<FloatFieldUpdateOperationsInput>;
  longitude?: Maybe<FloatFieldUpdateOperationsInput>;
  asOrigin?: Maybe<OrderUpdateManyWithoutOriginInput>;
};

export type ShedUncheckedUpdateWithoutAsDestinationInput = {
  id?: Maybe<IntFieldUpdateOperationsInput>;
  name?: Maybe<StringFieldUpdateOperationsInput>;
  latitude?: Maybe<FloatFieldUpdateOperationsInput>;
  longitude?: Maybe<FloatFieldUpdateOperationsInput>;
  asOrigin?: Maybe<OrderUncheckedUpdateManyWithoutOriginInput>;
};

export type InvoiceUpsertWithWhereUniqueWithoutItemsInput = {
  where: InvoiceWhereUniqueInput;
  update: InvoiceUncheckedUpdateWithoutItemsInput;
  create: InvoiceUncheckedCreateWithoutItemsInput;
};

export type InvoiceUpdateWithWhereUniqueWithoutItemsInput = {
  where: InvoiceWhereUniqueInput;
  data: InvoiceUncheckedUpdateWithoutItemsInput;
};

export type InvoiceUpdateManyWithWhereWithoutItemsInput = {
  where: InvoiceScalarWhereInput;
  data: InvoiceUncheckedUpdateManyWithoutInvoicesInput;
};

export type ShedUpsertWithoutAsOriginInput = {
  update: ShedUncheckedUpdateWithoutAsOriginInput;
  create: ShedUncheckedCreateWithoutAsOriginInput;
};

export type ShedUpdateWithoutAsOriginInput = {
  name?: Maybe<StringFieldUpdateOperationsInput>;
  latitude?: Maybe<FloatFieldUpdateOperationsInput>;
  longitude?: Maybe<FloatFieldUpdateOperationsInput>;
  asDestination?: Maybe<OrderUpdateManyWithoutDestinationInput>;
};

export type ShedUncheckedUpdateWithoutAsOriginInput = {
  id?: Maybe<IntFieldUpdateOperationsInput>;
  name?: Maybe<StringFieldUpdateOperationsInput>;
  latitude?: Maybe<FloatFieldUpdateOperationsInput>;
  longitude?: Maybe<FloatFieldUpdateOperationsInput>;
  asDestination?: Maybe<OrderUncheckedUpdateManyWithoutDestinationInput>;
};

export type CompanyUpsertWithoutRequestedInput = {
  update: CompanyUncheckedUpdateWithoutRequestedInput;
  create: CompanyUncheckedCreateWithoutRequestedInput;
};

export type CompanyUpdateWithoutRequestedInput = {
  name?: Maybe<StringFieldUpdateOperationsInput>;
  cuit?: Maybe<StringFieldUpdateOperationsInput>;
  isCustomer?: Maybe<BoolFieldUpdateOperationsInput>;
  isSupplier?: Maybe<BoolFieldUpdateOperationsInput>;
  type?: Maybe<EnumCompanyTypeFieldUpdateOperationsInput>;
  employees?: Maybe<EmployeeUpdateManyWithoutWorksForInput>;
  assigned?: Maybe<OrderUpdateManyWithoutAssignedToInput>;
  payments?: Maybe<PaymentUpdateManyWithoutSourceInput>;
  invoices?: Maybe<InvoiceUpdateManyWithoutCompanyInput>;
  bills?: Maybe<BillUpdateManyWithoutCompanyInput>;
};

export type CompanyUncheckedUpdateWithoutRequestedInput = {
  id?: Maybe<IntFieldUpdateOperationsInput>;
  name?: Maybe<StringFieldUpdateOperationsInput>;
  cuit?: Maybe<StringFieldUpdateOperationsInput>;
  isCustomer?: Maybe<BoolFieldUpdateOperationsInput>;
  isSupplier?: Maybe<BoolFieldUpdateOperationsInput>;
  type?: Maybe<EnumCompanyTypeFieldUpdateOperationsInput>;
  employees?: Maybe<EmployeeUncheckedUpdateManyWithoutWorksForInput>;
  assigned?: Maybe<OrderUncheckedUpdateManyWithoutAssignedToInput>;
  payments?: Maybe<PaymentUncheckedUpdateManyWithoutSourceInput>;
  invoices?: Maybe<InvoiceUncheckedUpdateManyWithoutCompanyInput>;
  bills?: Maybe<BillUncheckedUpdateManyWithoutCompanyInput>;
};

export type CompanyCreateWithoutInvoicesInput = {
  name: Scalars['String'];
  cuit: Scalars['String'];
  isCustomer?: Maybe<Scalars['Boolean']>;
  isSupplier?: Maybe<Scalars['Boolean']>;
  type?: Maybe<CompanyType>;
  employees?: Maybe<EmployeeCreateNestedManyWithoutWorksForInput>;
  requested?: Maybe<OrderCreateNestedManyWithoutRequestedByInput>;
  assigned?: Maybe<OrderCreateNestedManyWithoutAssignedToInput>;
  payments?: Maybe<PaymentCreateNestedManyWithoutSourceInput>;
  bills?: Maybe<BillCreateNestedManyWithoutCompanyInput>;
};

export type CompanyUncheckedCreateWithoutInvoicesInput = {
  id?: Maybe<Scalars['Int']>;
  name: Scalars['String'];
  cuit: Scalars['String'];
  isCustomer?: Maybe<Scalars['Boolean']>;
  isSupplier?: Maybe<Scalars['Boolean']>;
  type?: Maybe<CompanyType>;
  employees?: Maybe<EmployeeUncheckedCreateNestedManyWithoutWorksForInput>;
  requested?: Maybe<OrderUncheckedCreateNestedManyWithoutRequestedByInput>;
  assigned?: Maybe<OrderUncheckedCreateNestedManyWithoutAssignedToInput>;
  payments?: Maybe<PaymentUncheckedCreateNestedManyWithoutSourceInput>;
  bills?: Maybe<BillUncheckedCreateNestedManyWithoutCompanyInput>;
};

export type CompanyCreateOrConnectWithoutInvoicesInput = {
  where: CompanyWhereUniqueInput;
  create: CompanyUncheckedCreateWithoutInvoicesInput;
};

export type OrderCreateWithoutInvoicesInput = {
  assignedAt?: Maybe<Scalars['DateTime']>;
  cargoType?: Maybe<CargoType>;
  comments?: Maybe<Scalars['String']>;
  completedAt?: Maybe<Scalars['DateTime']>;
  distance?: Maybe<Scalars['Int']>;
  ourRate?: Maybe<Scalars['Float']>;
  range?: Maybe<Scalars['String']>;
  requestedAt?: Maybe<Scalars['DateTime']>;
  status?: Maybe<OrderStatus>;
  theirRate?: Maybe<Scalars['Float']>;
  truckType?: Maybe<TruckType>;
  units?: Maybe<Scalars['Float']>;
  waypoints?: Maybe<Scalars['Json']>;
  weight?: Maybe<Scalars['Float']>;
  receipts?: Maybe<Array<Maybe<Scalars['String']>>>;
  assignedTo?: Maybe<CompanyCreateNestedOneWithoutAssignedInput>;
  carriedBy?: Maybe<EmployeeCreateNestedOneWithoutAssignedInput>;
  destination?: Maybe<ShedCreateNestedOneWithoutAsDestinationInput>;
  origin?: Maybe<ShedCreateNestedOneWithoutAsOriginInput>;
  requestedBy?: Maybe<CompanyCreateNestedOneWithoutRequestedInput>;
};

export type OrderUncheckedCreateWithoutInvoicesInput = {
  id?: Maybe<Scalars['Int']>;
  assignedAt?: Maybe<Scalars['DateTime']>;
  cargoType?: Maybe<CargoType>;
  comments?: Maybe<Scalars['String']>;
  completedAt?: Maybe<Scalars['DateTime']>;
  distance?: Maybe<Scalars['Int']>;
  ourRate?: Maybe<Scalars['Float']>;
  range?: Maybe<Scalars['String']>;
  requestedAt?: Maybe<Scalars['DateTime']>;
  status?: Maybe<OrderStatus>;
  theirRate?: Maybe<Scalars['Float']>;
  truckType?: Maybe<TruckType>;
  units?: Maybe<Scalars['Float']>;
  waypoints?: Maybe<Scalars['Json']>;
  weight?: Maybe<Scalars['Float']>;
  assignedToId?: Maybe<Scalars['Int']>;
  carriedById?: Maybe<Scalars['Int']>;
  destinationId?: Maybe<Scalars['Int']>;
  originId?: Maybe<Scalars['Int']>;
  requestedById?: Maybe<Scalars['Int']>;
  receipts?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type OrderCreateOrConnectWithoutInvoicesInput = {
  where: OrderWhereUniqueInput;
  create: OrderUncheckedCreateWithoutInvoicesInput;
};

export type BillCreateWithoutItemsInput = {
  date?: Maybe<Scalars['DateTime']>;
  amount: Scalars['Float'];
  vat: Scalars['Float'];
  company: CompanyCreateNestedOneWithoutBillsInput;
  payments?: Maybe<PaymentCreateNestedManyWithoutReferenceInput>;
};

export type BillUncheckedCreateWithoutItemsInput = {
  id?: Maybe<Scalars['Int']>;
  date?: Maybe<Scalars['DateTime']>;
  companyId: Scalars['Int'];
  amount: Scalars['Float'];
  vat: Scalars['Float'];
  payments?: Maybe<PaymentUncheckedCreateNestedManyWithoutReferenceInput>;
};

export type BillCreateOrConnectWithoutItemsInput = {
  where: BillWhereUniqueInput;
  create: BillUncheckedCreateWithoutItemsInput;
};

export type CompanyUpsertWithoutInvoicesInput = {
  update: CompanyUncheckedUpdateWithoutInvoicesInput;
  create: CompanyUncheckedCreateWithoutInvoicesInput;
};

export type CompanyUpdateWithoutInvoicesInput = {
  name?: Maybe<StringFieldUpdateOperationsInput>;
  cuit?: Maybe<StringFieldUpdateOperationsInput>;
  isCustomer?: Maybe<BoolFieldUpdateOperationsInput>;
  isSupplier?: Maybe<BoolFieldUpdateOperationsInput>;
  type?: Maybe<EnumCompanyTypeFieldUpdateOperationsInput>;
  employees?: Maybe<EmployeeUpdateManyWithoutWorksForInput>;
  requested?: Maybe<OrderUpdateManyWithoutRequestedByInput>;
  assigned?: Maybe<OrderUpdateManyWithoutAssignedToInput>;
  payments?: Maybe<PaymentUpdateManyWithoutSourceInput>;
  bills?: Maybe<BillUpdateManyWithoutCompanyInput>;
};

export type CompanyUncheckedUpdateWithoutInvoicesInput = {
  id?: Maybe<IntFieldUpdateOperationsInput>;
  name?: Maybe<StringFieldUpdateOperationsInput>;
  cuit?: Maybe<StringFieldUpdateOperationsInput>;
  isCustomer?: Maybe<BoolFieldUpdateOperationsInput>;
  isSupplier?: Maybe<BoolFieldUpdateOperationsInput>;
  type?: Maybe<EnumCompanyTypeFieldUpdateOperationsInput>;
  employees?: Maybe<EmployeeUncheckedUpdateManyWithoutWorksForInput>;
  requested?: Maybe<OrderUncheckedUpdateManyWithoutRequestedByInput>;
  assigned?: Maybe<OrderUncheckedUpdateManyWithoutAssignedToInput>;
  payments?: Maybe<PaymentUncheckedUpdateManyWithoutSourceInput>;
  bills?: Maybe<BillUncheckedUpdateManyWithoutCompanyInput>;
};

export type OrderUpsertWithWhereUniqueWithoutInvoicesInput = {
  where: OrderWhereUniqueInput;
  update: OrderUncheckedUpdateWithoutInvoicesInput;
  create: OrderUncheckedCreateWithoutInvoicesInput;
};

export type OrderUpdateWithWhereUniqueWithoutInvoicesInput = {
  where: OrderWhereUniqueInput;
  data: OrderUncheckedUpdateWithoutInvoicesInput;
};

export type OrderUpdateManyWithWhereWithoutInvoicesInput = {
  where: OrderScalarWhereInput;
  data: OrderUncheckedUpdateManyWithoutItemsInput;
};

export type BillUpsertWithoutItemsInput = {
  update: BillUncheckedUpdateWithoutItemsInput;
  create: BillUncheckedCreateWithoutItemsInput;
};

export type BillUpdateWithoutItemsInput = {
  date?: Maybe<DateTimeFieldUpdateOperationsInput>;
  amount?: Maybe<FloatFieldUpdateOperationsInput>;
  vat?: Maybe<FloatFieldUpdateOperationsInput>;
  company?: Maybe<CompanyUpdateOneRequiredWithoutBillsInput>;
  payments?: Maybe<PaymentUpdateManyWithoutReferenceInput>;
};

export type BillUncheckedUpdateWithoutItemsInput = {
  id?: Maybe<IntFieldUpdateOperationsInput>;
  date?: Maybe<DateTimeFieldUpdateOperationsInput>;
  companyId?: Maybe<IntFieldUpdateOperationsInput>;
  amount?: Maybe<FloatFieldUpdateOperationsInput>;
  vat?: Maybe<FloatFieldUpdateOperationsInput>;
  payments?: Maybe<PaymentUncheckedUpdateManyWithoutReferenceInput>;
};

export type CompanyCreateWithoutBillsInput = {
  name: Scalars['String'];
  cuit: Scalars['String'];
  isCustomer?: Maybe<Scalars['Boolean']>;
  isSupplier?: Maybe<Scalars['Boolean']>;
  type?: Maybe<CompanyType>;
  employees?: Maybe<EmployeeCreateNestedManyWithoutWorksForInput>;
  requested?: Maybe<OrderCreateNestedManyWithoutRequestedByInput>;
  assigned?: Maybe<OrderCreateNestedManyWithoutAssignedToInput>;
  payments?: Maybe<PaymentCreateNestedManyWithoutSourceInput>;
  invoices?: Maybe<InvoiceCreateNestedManyWithoutCompanyInput>;
};

export type CompanyUncheckedCreateWithoutBillsInput = {
  id?: Maybe<Scalars['Int']>;
  name: Scalars['String'];
  cuit: Scalars['String'];
  isCustomer?: Maybe<Scalars['Boolean']>;
  isSupplier?: Maybe<Scalars['Boolean']>;
  type?: Maybe<CompanyType>;
  employees?: Maybe<EmployeeUncheckedCreateNestedManyWithoutWorksForInput>;
  requested?: Maybe<OrderUncheckedCreateNestedManyWithoutRequestedByInput>;
  assigned?: Maybe<OrderUncheckedCreateNestedManyWithoutAssignedToInput>;
  payments?: Maybe<PaymentUncheckedCreateNestedManyWithoutSourceInput>;
  invoices?: Maybe<InvoiceUncheckedCreateNestedManyWithoutCompanyInput>;
};

export type CompanyCreateOrConnectWithoutBillsInput = {
  where: CompanyWhereUniqueInput;
  create: CompanyUncheckedCreateWithoutBillsInput;
};

export type InvoiceCreateWithoutBillInput = {
  date?: Maybe<Scalars['DateTime']>;
  total: Scalars['Float'];
  company: CompanyCreateNestedOneWithoutInvoicesInput;
  items?: Maybe<OrderCreateNestedManyWithoutInvoicesInput>;
};

export type InvoiceUncheckedCreateWithoutBillInput = {
  id?: Maybe<Scalars['Int']>;
  date?: Maybe<Scalars['DateTime']>;
  companyId: Scalars['Int'];
  total: Scalars['Float'];
};

export type InvoiceCreateOrConnectWithoutBillInput = {
  where: InvoiceWhereUniqueInput;
  create: InvoiceUncheckedCreateWithoutBillInput;
};

export type PaymentCreateWithoutReferenceInput = {
  date?: Maybe<Scalars['DateTime']>;
  amount: Scalars['Float'];
  source: CompanyCreateNestedOneWithoutPaymentsInput;
};

export type PaymentUncheckedCreateWithoutReferenceInput = {
  id?: Maybe<Scalars['Int']>;
  date?: Maybe<Scalars['DateTime']>;
  sourceId: Scalars['Int'];
  amount: Scalars['Float'];
};

export type PaymentCreateOrConnectWithoutReferenceInput = {
  where: PaymentWhereUniqueInput;
  create: PaymentUncheckedCreateWithoutReferenceInput;
};

export type CompanyUpsertWithoutBillsInput = {
  update: CompanyUncheckedUpdateWithoutBillsInput;
  create: CompanyUncheckedCreateWithoutBillsInput;
};

export type CompanyUpdateWithoutBillsInput = {
  name?: Maybe<StringFieldUpdateOperationsInput>;
  cuit?: Maybe<StringFieldUpdateOperationsInput>;
  isCustomer?: Maybe<BoolFieldUpdateOperationsInput>;
  isSupplier?: Maybe<BoolFieldUpdateOperationsInput>;
  type?: Maybe<EnumCompanyTypeFieldUpdateOperationsInput>;
  employees?: Maybe<EmployeeUpdateManyWithoutWorksForInput>;
  requested?: Maybe<OrderUpdateManyWithoutRequestedByInput>;
  assigned?: Maybe<OrderUpdateManyWithoutAssignedToInput>;
  payments?: Maybe<PaymentUpdateManyWithoutSourceInput>;
  invoices?: Maybe<InvoiceUpdateManyWithoutCompanyInput>;
};

export type CompanyUncheckedUpdateWithoutBillsInput = {
  id?: Maybe<IntFieldUpdateOperationsInput>;
  name?: Maybe<StringFieldUpdateOperationsInput>;
  cuit?: Maybe<StringFieldUpdateOperationsInput>;
  isCustomer?: Maybe<BoolFieldUpdateOperationsInput>;
  isSupplier?: Maybe<BoolFieldUpdateOperationsInput>;
  type?: Maybe<EnumCompanyTypeFieldUpdateOperationsInput>;
  employees?: Maybe<EmployeeUncheckedUpdateManyWithoutWorksForInput>;
  requested?: Maybe<OrderUncheckedUpdateManyWithoutRequestedByInput>;
  assigned?: Maybe<OrderUncheckedUpdateManyWithoutAssignedToInput>;
  payments?: Maybe<PaymentUncheckedUpdateManyWithoutSourceInput>;
  invoices?: Maybe<InvoiceUncheckedUpdateManyWithoutCompanyInput>;
};

export type InvoiceUpsertWithWhereUniqueWithoutBillInput = {
  where: InvoiceWhereUniqueInput;
  update: InvoiceUncheckedUpdateWithoutBillInput;
  create: InvoiceUncheckedCreateWithoutBillInput;
};

export type InvoiceUpdateWithWhereUniqueWithoutBillInput = {
  where: InvoiceWhereUniqueInput;
  data: InvoiceUncheckedUpdateWithoutBillInput;
};

export type InvoiceUpdateManyWithWhereWithoutBillInput = {
  where: InvoiceScalarWhereInput;
  data: InvoiceUncheckedUpdateManyWithoutItemsInput;
};

export type PaymentUpsertWithWhereUniqueWithoutReferenceInput = {
  where: PaymentWhereUniqueInput;
  update: PaymentUncheckedUpdateWithoutReferenceInput;
  create: PaymentUncheckedCreateWithoutReferenceInput;
};

export type PaymentUpdateWithWhereUniqueWithoutReferenceInput = {
  where: PaymentWhereUniqueInput;
  data: PaymentUncheckedUpdateWithoutReferenceInput;
};

export type PaymentUpdateManyWithWhereWithoutReferenceInput = {
  where: PaymentScalarWhereInput;
  data: PaymentUncheckedUpdateManyWithoutPaymentsInput;
};

export type CompanyCreateWithoutPaymentsInput = {
  name: Scalars['String'];
  cuit: Scalars['String'];
  isCustomer?: Maybe<Scalars['Boolean']>;
  isSupplier?: Maybe<Scalars['Boolean']>;
  type?: Maybe<CompanyType>;
  employees?: Maybe<EmployeeCreateNestedManyWithoutWorksForInput>;
  requested?: Maybe<OrderCreateNestedManyWithoutRequestedByInput>;
  assigned?: Maybe<OrderCreateNestedManyWithoutAssignedToInput>;
  invoices?: Maybe<InvoiceCreateNestedManyWithoutCompanyInput>;
  bills?: Maybe<BillCreateNestedManyWithoutCompanyInput>;
};

export type CompanyUncheckedCreateWithoutPaymentsInput = {
  id?: Maybe<Scalars['Int']>;
  name: Scalars['String'];
  cuit: Scalars['String'];
  isCustomer?: Maybe<Scalars['Boolean']>;
  isSupplier?: Maybe<Scalars['Boolean']>;
  type?: Maybe<CompanyType>;
  employees?: Maybe<EmployeeUncheckedCreateNestedManyWithoutWorksForInput>;
  requested?: Maybe<OrderUncheckedCreateNestedManyWithoutRequestedByInput>;
  assigned?: Maybe<OrderUncheckedCreateNestedManyWithoutAssignedToInput>;
  invoices?: Maybe<InvoiceUncheckedCreateNestedManyWithoutCompanyInput>;
  bills?: Maybe<BillUncheckedCreateNestedManyWithoutCompanyInput>;
};

export type CompanyCreateOrConnectWithoutPaymentsInput = {
  where: CompanyWhereUniqueInput;
  create: CompanyUncheckedCreateWithoutPaymentsInput;
};

export type BillCreateWithoutPaymentsInput = {
  date?: Maybe<Scalars['DateTime']>;
  amount: Scalars['Float'];
  vat: Scalars['Float'];
  company: CompanyCreateNestedOneWithoutBillsInput;
  items?: Maybe<InvoiceCreateNestedManyWithoutBillInput>;
};

export type BillUncheckedCreateWithoutPaymentsInput = {
  id?: Maybe<Scalars['Int']>;
  date?: Maybe<Scalars['DateTime']>;
  companyId: Scalars['Int'];
  amount: Scalars['Float'];
  vat: Scalars['Float'];
  items?: Maybe<InvoiceUncheckedCreateNestedManyWithoutBillInput>;
};

export type BillCreateOrConnectWithoutPaymentsInput = {
  where: BillWhereUniqueInput;
  create: BillUncheckedCreateWithoutPaymentsInput;
};

export type CompanyUpsertWithoutPaymentsInput = {
  update: CompanyUncheckedUpdateWithoutPaymentsInput;
  create: CompanyUncheckedCreateWithoutPaymentsInput;
};

export type CompanyUpdateWithoutPaymentsInput = {
  name?: Maybe<StringFieldUpdateOperationsInput>;
  cuit?: Maybe<StringFieldUpdateOperationsInput>;
  isCustomer?: Maybe<BoolFieldUpdateOperationsInput>;
  isSupplier?: Maybe<BoolFieldUpdateOperationsInput>;
  type?: Maybe<EnumCompanyTypeFieldUpdateOperationsInput>;
  employees?: Maybe<EmployeeUpdateManyWithoutWorksForInput>;
  requested?: Maybe<OrderUpdateManyWithoutRequestedByInput>;
  assigned?: Maybe<OrderUpdateManyWithoutAssignedToInput>;
  invoices?: Maybe<InvoiceUpdateManyWithoutCompanyInput>;
  bills?: Maybe<BillUpdateManyWithoutCompanyInput>;
};

export type CompanyUncheckedUpdateWithoutPaymentsInput = {
  id?: Maybe<IntFieldUpdateOperationsInput>;
  name?: Maybe<StringFieldUpdateOperationsInput>;
  cuit?: Maybe<StringFieldUpdateOperationsInput>;
  isCustomer?: Maybe<BoolFieldUpdateOperationsInput>;
  isSupplier?: Maybe<BoolFieldUpdateOperationsInput>;
  type?: Maybe<EnumCompanyTypeFieldUpdateOperationsInput>;
  employees?: Maybe<EmployeeUncheckedUpdateManyWithoutWorksForInput>;
  requested?: Maybe<OrderUncheckedUpdateManyWithoutRequestedByInput>;
  assigned?: Maybe<OrderUncheckedUpdateManyWithoutAssignedToInput>;
  invoices?: Maybe<InvoiceUncheckedUpdateManyWithoutCompanyInput>;
  bills?: Maybe<BillUncheckedUpdateManyWithoutCompanyInput>;
};

export type BillUpsertWithoutPaymentsInput = {
  update: BillUncheckedUpdateWithoutPaymentsInput;
  create: BillUncheckedCreateWithoutPaymentsInput;
};

export type BillUpdateWithoutPaymentsInput = {
  date?: Maybe<DateTimeFieldUpdateOperationsInput>;
  amount?: Maybe<FloatFieldUpdateOperationsInput>;
  vat?: Maybe<FloatFieldUpdateOperationsInput>;
  company?: Maybe<CompanyUpdateOneRequiredWithoutBillsInput>;
  items?: Maybe<InvoiceUpdateManyWithoutBillInput>;
};

export type BillUncheckedUpdateWithoutPaymentsInput = {
  id?: Maybe<IntFieldUpdateOperationsInput>;
  date?: Maybe<DateTimeFieldUpdateOperationsInput>;
  companyId?: Maybe<IntFieldUpdateOperationsInput>;
  amount?: Maybe<FloatFieldUpdateOperationsInput>;
  vat?: Maybe<FloatFieldUpdateOperationsInput>;
  items?: Maybe<InvoiceUncheckedUpdateManyWithoutBillInput>;
};

export type OrderCreateWithoutOriginInput = {
  assignedAt?: Maybe<Scalars['DateTime']>;
  cargoType?: Maybe<CargoType>;
  comments?: Maybe<Scalars['String']>;
  completedAt?: Maybe<Scalars['DateTime']>;
  distance?: Maybe<Scalars['Int']>;
  ourRate?: Maybe<Scalars['Float']>;
  range?: Maybe<Scalars['String']>;
  requestedAt?: Maybe<Scalars['DateTime']>;
  status?: Maybe<OrderStatus>;
  theirRate?: Maybe<Scalars['Float']>;
  truckType?: Maybe<TruckType>;
  units?: Maybe<Scalars['Float']>;
  waypoints?: Maybe<Scalars['Json']>;
  weight?: Maybe<Scalars['Float']>;
  receipts?: Maybe<Array<Maybe<Scalars['String']>>>;
  assignedTo?: Maybe<CompanyCreateNestedOneWithoutAssignedInput>;
  carriedBy?: Maybe<EmployeeCreateNestedOneWithoutAssignedInput>;
  destination?: Maybe<ShedCreateNestedOneWithoutAsDestinationInput>;
  invoices?: Maybe<InvoiceCreateNestedManyWithoutItemsInput>;
  requestedBy?: Maybe<CompanyCreateNestedOneWithoutRequestedInput>;
};

export type OrderUncheckedCreateWithoutOriginInput = {
  id?: Maybe<Scalars['Int']>;
  assignedAt?: Maybe<Scalars['DateTime']>;
  cargoType?: Maybe<CargoType>;
  comments?: Maybe<Scalars['String']>;
  completedAt?: Maybe<Scalars['DateTime']>;
  distance?: Maybe<Scalars['Int']>;
  ourRate?: Maybe<Scalars['Float']>;
  range?: Maybe<Scalars['String']>;
  requestedAt?: Maybe<Scalars['DateTime']>;
  status?: Maybe<OrderStatus>;
  theirRate?: Maybe<Scalars['Float']>;
  truckType?: Maybe<TruckType>;
  units?: Maybe<Scalars['Float']>;
  waypoints?: Maybe<Scalars['Json']>;
  weight?: Maybe<Scalars['Float']>;
  assignedToId?: Maybe<Scalars['Int']>;
  carriedById?: Maybe<Scalars['Int']>;
  destinationId?: Maybe<Scalars['Int']>;
  requestedById?: Maybe<Scalars['Int']>;
  receipts?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type OrderCreateOrConnectWithoutOriginInput = {
  where: OrderWhereUniqueInput;
  create: OrderUncheckedCreateWithoutOriginInput;
};

export type OrderCreateWithoutDestinationInput = {
  assignedAt?: Maybe<Scalars['DateTime']>;
  cargoType?: Maybe<CargoType>;
  comments?: Maybe<Scalars['String']>;
  completedAt?: Maybe<Scalars['DateTime']>;
  distance?: Maybe<Scalars['Int']>;
  ourRate?: Maybe<Scalars['Float']>;
  range?: Maybe<Scalars['String']>;
  requestedAt?: Maybe<Scalars['DateTime']>;
  status?: Maybe<OrderStatus>;
  theirRate?: Maybe<Scalars['Float']>;
  truckType?: Maybe<TruckType>;
  units?: Maybe<Scalars['Float']>;
  waypoints?: Maybe<Scalars['Json']>;
  weight?: Maybe<Scalars['Float']>;
  receipts?: Maybe<Array<Maybe<Scalars['String']>>>;
  assignedTo?: Maybe<CompanyCreateNestedOneWithoutAssignedInput>;
  carriedBy?: Maybe<EmployeeCreateNestedOneWithoutAssignedInput>;
  invoices?: Maybe<InvoiceCreateNestedManyWithoutItemsInput>;
  origin?: Maybe<ShedCreateNestedOneWithoutAsOriginInput>;
  requestedBy?: Maybe<CompanyCreateNestedOneWithoutRequestedInput>;
};

export type OrderUncheckedCreateWithoutDestinationInput = {
  id?: Maybe<Scalars['Int']>;
  assignedAt?: Maybe<Scalars['DateTime']>;
  cargoType?: Maybe<CargoType>;
  comments?: Maybe<Scalars['String']>;
  completedAt?: Maybe<Scalars['DateTime']>;
  distance?: Maybe<Scalars['Int']>;
  ourRate?: Maybe<Scalars['Float']>;
  range?: Maybe<Scalars['String']>;
  requestedAt?: Maybe<Scalars['DateTime']>;
  status?: Maybe<OrderStatus>;
  theirRate?: Maybe<Scalars['Float']>;
  truckType?: Maybe<TruckType>;
  units?: Maybe<Scalars['Float']>;
  waypoints?: Maybe<Scalars['Json']>;
  weight?: Maybe<Scalars['Float']>;
  assignedToId?: Maybe<Scalars['Int']>;
  carriedById?: Maybe<Scalars['Int']>;
  originId?: Maybe<Scalars['Int']>;
  requestedById?: Maybe<Scalars['Int']>;
  receipts?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type OrderCreateOrConnectWithoutDestinationInput = {
  where: OrderWhereUniqueInput;
  create: OrderUncheckedCreateWithoutDestinationInput;
};

export type OrderUpsertWithWhereUniqueWithoutOriginInput = {
  where: OrderWhereUniqueInput;
  update: OrderUncheckedUpdateWithoutOriginInput;
  create: OrderUncheckedCreateWithoutOriginInput;
};

export type OrderUpdateWithWhereUniqueWithoutOriginInput = {
  where: OrderWhereUniqueInput;
  data: OrderUncheckedUpdateWithoutOriginInput;
};

export type OrderUpdateManyWithWhereWithoutOriginInput = {
  where: OrderScalarWhereInput;
  data: OrderUncheckedUpdateManyWithoutAsOriginInput;
};

export type OrderUpsertWithWhereUniqueWithoutDestinationInput = {
  where: OrderWhereUniqueInput;
  update: OrderUncheckedUpdateWithoutDestinationInput;
  create: OrderUncheckedCreateWithoutDestinationInput;
};

export type OrderUpdateWithWhereUniqueWithoutDestinationInput = {
  where: OrderWhereUniqueInput;
  data: OrderUncheckedUpdateWithoutDestinationInput;
};

export type OrderUpdateManyWithWhereWithoutDestinationInput = {
  where: OrderScalarWhereInput;
  data: OrderUncheckedUpdateManyWithoutAsDestinationInput;
};

export type EmployeeUpdateWithoutWorksForInput = {
  name?: Maybe<StringFieldUpdateOperationsInput>;
  dni?: Maybe<StringFieldUpdateOperationsInput>;
  status?: Maybe<EnumEmployeeStatusFieldUpdateOperationsInput>;
  assigned?: Maybe<OrderUpdateManyWithoutCarriedByInput>;
};

export type EmployeeUncheckedUpdateWithoutWorksForInput = {
  id?: Maybe<IntFieldUpdateOperationsInput>;
  name?: Maybe<StringFieldUpdateOperationsInput>;
  dni?: Maybe<StringFieldUpdateOperationsInput>;
  status?: Maybe<EnumEmployeeStatusFieldUpdateOperationsInput>;
  assigned?: Maybe<OrderUncheckedUpdateManyWithoutCarriedByInput>;
};

export type EmployeeUncheckedUpdateManyWithoutEmployeesInput = {
  id?: Maybe<IntFieldUpdateOperationsInput>;
  name?: Maybe<StringFieldUpdateOperationsInput>;
  dni?: Maybe<StringFieldUpdateOperationsInput>;
  status?: Maybe<EnumEmployeeStatusFieldUpdateOperationsInput>;
};

export type OrderUpdateWithoutRequestedByInput = {
  assignedAt?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  cargoType?: Maybe<NullableEnumCargoTypeFieldUpdateOperationsInput>;
  comments?: Maybe<NullableStringFieldUpdateOperationsInput>;
  completedAt?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  distance?: Maybe<NullableIntFieldUpdateOperationsInput>;
  ourRate?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  range?: Maybe<NullableStringFieldUpdateOperationsInput>;
  requestedAt?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  status?: Maybe<NullableEnumOrderStatusFieldUpdateOperationsInput>;
  theirRate?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  truckType?: Maybe<NullableEnumTruckTypeFieldUpdateOperationsInput>;
  units?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  waypoints?: Maybe<Scalars['Json']>;
  weight?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  receipts?: Maybe<Array<Maybe<Scalars['String']>>>;
  assignedTo?: Maybe<CompanyUpdateOneWithoutAssignedInput>;
  carriedBy?: Maybe<EmployeeUpdateOneWithoutAssignedInput>;
  destination?: Maybe<ShedUpdateOneWithoutAsDestinationInput>;
  invoices?: Maybe<InvoiceUpdateManyWithoutItemsInput>;
  origin?: Maybe<ShedUpdateOneWithoutAsOriginInput>;
};

export type OrderUncheckedUpdateWithoutRequestedByInput = {
  id?: Maybe<IntFieldUpdateOperationsInput>;
  assignedAt?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  cargoType?: Maybe<NullableEnumCargoTypeFieldUpdateOperationsInput>;
  comments?: Maybe<NullableStringFieldUpdateOperationsInput>;
  completedAt?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  distance?: Maybe<NullableIntFieldUpdateOperationsInput>;
  ourRate?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  range?: Maybe<NullableStringFieldUpdateOperationsInput>;
  requestedAt?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  status?: Maybe<NullableEnumOrderStatusFieldUpdateOperationsInput>;
  theirRate?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  truckType?: Maybe<NullableEnumTruckTypeFieldUpdateOperationsInput>;
  units?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  waypoints?: Maybe<Scalars['Json']>;
  weight?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  assignedToId?: Maybe<NullableIntFieldUpdateOperationsInput>;
  carriedById?: Maybe<NullableIntFieldUpdateOperationsInput>;
  destinationId?: Maybe<NullableIntFieldUpdateOperationsInput>;
  originId?: Maybe<NullableIntFieldUpdateOperationsInput>;
  receipts?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type OrderUncheckedUpdateManyWithoutRequestedInput = {
  id?: Maybe<IntFieldUpdateOperationsInput>;
  assignedAt?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  cargoType?: Maybe<NullableEnumCargoTypeFieldUpdateOperationsInput>;
  comments?: Maybe<NullableStringFieldUpdateOperationsInput>;
  completedAt?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  distance?: Maybe<NullableIntFieldUpdateOperationsInput>;
  ourRate?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  range?: Maybe<NullableStringFieldUpdateOperationsInput>;
  requestedAt?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  status?: Maybe<NullableEnumOrderStatusFieldUpdateOperationsInput>;
  theirRate?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  truckType?: Maybe<NullableEnumTruckTypeFieldUpdateOperationsInput>;
  units?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  waypoints?: Maybe<Scalars['Json']>;
  weight?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  assignedToId?: Maybe<NullableIntFieldUpdateOperationsInput>;
  carriedById?: Maybe<NullableIntFieldUpdateOperationsInput>;
  destinationId?: Maybe<NullableIntFieldUpdateOperationsInput>;
  originId?: Maybe<NullableIntFieldUpdateOperationsInput>;
  receipts?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type OrderUpdateWithoutAssignedToInput = {
  assignedAt?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  cargoType?: Maybe<NullableEnumCargoTypeFieldUpdateOperationsInput>;
  comments?: Maybe<NullableStringFieldUpdateOperationsInput>;
  completedAt?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  distance?: Maybe<NullableIntFieldUpdateOperationsInput>;
  ourRate?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  range?: Maybe<NullableStringFieldUpdateOperationsInput>;
  requestedAt?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  status?: Maybe<NullableEnumOrderStatusFieldUpdateOperationsInput>;
  theirRate?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  truckType?: Maybe<NullableEnumTruckTypeFieldUpdateOperationsInput>;
  units?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  waypoints?: Maybe<Scalars['Json']>;
  weight?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  receipts?: Maybe<Array<Maybe<Scalars['String']>>>;
  carriedBy?: Maybe<EmployeeUpdateOneWithoutAssignedInput>;
  destination?: Maybe<ShedUpdateOneWithoutAsDestinationInput>;
  invoices?: Maybe<InvoiceUpdateManyWithoutItemsInput>;
  origin?: Maybe<ShedUpdateOneWithoutAsOriginInput>;
  requestedBy?: Maybe<CompanyUpdateOneWithoutRequestedInput>;
};

export type OrderUncheckedUpdateWithoutAssignedToInput = {
  id?: Maybe<IntFieldUpdateOperationsInput>;
  assignedAt?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  cargoType?: Maybe<NullableEnumCargoTypeFieldUpdateOperationsInput>;
  comments?: Maybe<NullableStringFieldUpdateOperationsInput>;
  completedAt?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  distance?: Maybe<NullableIntFieldUpdateOperationsInput>;
  ourRate?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  range?: Maybe<NullableStringFieldUpdateOperationsInput>;
  requestedAt?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  status?: Maybe<NullableEnumOrderStatusFieldUpdateOperationsInput>;
  theirRate?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  truckType?: Maybe<NullableEnumTruckTypeFieldUpdateOperationsInput>;
  units?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  waypoints?: Maybe<Scalars['Json']>;
  weight?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  carriedById?: Maybe<NullableIntFieldUpdateOperationsInput>;
  destinationId?: Maybe<NullableIntFieldUpdateOperationsInput>;
  originId?: Maybe<NullableIntFieldUpdateOperationsInput>;
  requestedById?: Maybe<NullableIntFieldUpdateOperationsInput>;
  receipts?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type OrderUncheckedUpdateManyWithoutAssignedInput = {
  id?: Maybe<IntFieldUpdateOperationsInput>;
  assignedAt?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  cargoType?: Maybe<NullableEnumCargoTypeFieldUpdateOperationsInput>;
  comments?: Maybe<NullableStringFieldUpdateOperationsInput>;
  completedAt?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  distance?: Maybe<NullableIntFieldUpdateOperationsInput>;
  ourRate?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  range?: Maybe<NullableStringFieldUpdateOperationsInput>;
  requestedAt?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  status?: Maybe<NullableEnumOrderStatusFieldUpdateOperationsInput>;
  theirRate?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  truckType?: Maybe<NullableEnumTruckTypeFieldUpdateOperationsInput>;
  units?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  waypoints?: Maybe<Scalars['Json']>;
  weight?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  carriedById?: Maybe<NullableIntFieldUpdateOperationsInput>;
  destinationId?: Maybe<NullableIntFieldUpdateOperationsInput>;
  originId?: Maybe<NullableIntFieldUpdateOperationsInput>;
  requestedById?: Maybe<NullableIntFieldUpdateOperationsInput>;
  receipts?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type PaymentUpdateWithoutSourceInput = {
  date?: Maybe<DateTimeFieldUpdateOperationsInput>;
  amount?: Maybe<FloatFieldUpdateOperationsInput>;
  reference?: Maybe<BillUpdateOneWithoutPaymentsInput>;
};

export type PaymentUncheckedUpdateWithoutSourceInput = {
  id?: Maybe<IntFieldUpdateOperationsInput>;
  date?: Maybe<DateTimeFieldUpdateOperationsInput>;
  amount?: Maybe<FloatFieldUpdateOperationsInput>;
  referenceId?: Maybe<NullableIntFieldUpdateOperationsInput>;
};

export type PaymentUncheckedUpdateManyWithoutPaymentsInput = {
  id?: Maybe<IntFieldUpdateOperationsInput>;
  date?: Maybe<DateTimeFieldUpdateOperationsInput>;
  amount?: Maybe<FloatFieldUpdateOperationsInput>;
  referenceId?: Maybe<NullableIntFieldUpdateOperationsInput>;
};

export type InvoiceUpdateWithoutCompanyInput = {
  date?: Maybe<DateTimeFieldUpdateOperationsInput>;
  total?: Maybe<FloatFieldUpdateOperationsInput>;
  items?: Maybe<OrderUpdateManyWithoutInvoicesInput>;
  bill?: Maybe<BillUpdateOneWithoutItemsInput>;
};

export type InvoiceUncheckedUpdateWithoutCompanyInput = {
  id?: Maybe<IntFieldUpdateOperationsInput>;
  date?: Maybe<DateTimeFieldUpdateOperationsInput>;
  total?: Maybe<FloatFieldUpdateOperationsInput>;
  billId?: Maybe<NullableIntFieldUpdateOperationsInput>;
};

export type InvoiceUncheckedUpdateManyWithoutInvoicesInput = {
  id?: Maybe<IntFieldUpdateOperationsInput>;
  date?: Maybe<DateTimeFieldUpdateOperationsInput>;
  total?: Maybe<FloatFieldUpdateOperationsInput>;
  billId?: Maybe<NullableIntFieldUpdateOperationsInput>;
};

export type BillUpdateWithoutCompanyInput = {
  date?: Maybe<DateTimeFieldUpdateOperationsInput>;
  amount?: Maybe<FloatFieldUpdateOperationsInput>;
  vat?: Maybe<FloatFieldUpdateOperationsInput>;
  items?: Maybe<InvoiceUpdateManyWithoutBillInput>;
  payments?: Maybe<PaymentUpdateManyWithoutReferenceInput>;
};

export type BillUncheckedUpdateWithoutCompanyInput = {
  id?: Maybe<IntFieldUpdateOperationsInput>;
  date?: Maybe<DateTimeFieldUpdateOperationsInput>;
  amount?: Maybe<FloatFieldUpdateOperationsInput>;
  vat?: Maybe<FloatFieldUpdateOperationsInput>;
  items?: Maybe<InvoiceUncheckedUpdateManyWithoutBillInput>;
  payments?: Maybe<PaymentUncheckedUpdateManyWithoutReferenceInput>;
};

export type BillUncheckedUpdateManyWithoutBillsInput = {
  id?: Maybe<IntFieldUpdateOperationsInput>;
  date?: Maybe<DateTimeFieldUpdateOperationsInput>;
  amount?: Maybe<FloatFieldUpdateOperationsInput>;
  vat?: Maybe<FloatFieldUpdateOperationsInput>;
};

export type OrderUpdateWithoutCarriedByInput = {
  assignedAt?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  cargoType?: Maybe<NullableEnumCargoTypeFieldUpdateOperationsInput>;
  comments?: Maybe<NullableStringFieldUpdateOperationsInput>;
  completedAt?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  distance?: Maybe<NullableIntFieldUpdateOperationsInput>;
  ourRate?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  range?: Maybe<NullableStringFieldUpdateOperationsInput>;
  requestedAt?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  status?: Maybe<NullableEnumOrderStatusFieldUpdateOperationsInput>;
  theirRate?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  truckType?: Maybe<NullableEnumTruckTypeFieldUpdateOperationsInput>;
  units?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  waypoints?: Maybe<Scalars['Json']>;
  weight?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  receipts?: Maybe<Array<Maybe<Scalars['String']>>>;
  assignedTo?: Maybe<CompanyUpdateOneWithoutAssignedInput>;
  destination?: Maybe<ShedUpdateOneWithoutAsDestinationInput>;
  invoices?: Maybe<InvoiceUpdateManyWithoutItemsInput>;
  origin?: Maybe<ShedUpdateOneWithoutAsOriginInput>;
  requestedBy?: Maybe<CompanyUpdateOneWithoutRequestedInput>;
};

export type OrderUncheckedUpdateWithoutCarriedByInput = {
  id?: Maybe<IntFieldUpdateOperationsInput>;
  assignedAt?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  cargoType?: Maybe<NullableEnumCargoTypeFieldUpdateOperationsInput>;
  comments?: Maybe<NullableStringFieldUpdateOperationsInput>;
  completedAt?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  distance?: Maybe<NullableIntFieldUpdateOperationsInput>;
  ourRate?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  range?: Maybe<NullableStringFieldUpdateOperationsInput>;
  requestedAt?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  status?: Maybe<NullableEnumOrderStatusFieldUpdateOperationsInput>;
  theirRate?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  truckType?: Maybe<NullableEnumTruckTypeFieldUpdateOperationsInput>;
  units?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  waypoints?: Maybe<Scalars['Json']>;
  weight?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  assignedToId?: Maybe<NullableIntFieldUpdateOperationsInput>;
  destinationId?: Maybe<NullableIntFieldUpdateOperationsInput>;
  originId?: Maybe<NullableIntFieldUpdateOperationsInput>;
  requestedById?: Maybe<NullableIntFieldUpdateOperationsInput>;
  receipts?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type InvoiceUpdateWithoutItemsInput = {
  date?: Maybe<DateTimeFieldUpdateOperationsInput>;
  total?: Maybe<FloatFieldUpdateOperationsInput>;
  company?: Maybe<CompanyUpdateOneRequiredWithoutInvoicesInput>;
  bill?: Maybe<BillUpdateOneWithoutItemsInput>;
};

export type InvoiceUncheckedUpdateWithoutItemsInput = {
  id?: Maybe<IntFieldUpdateOperationsInput>;
  date?: Maybe<DateTimeFieldUpdateOperationsInput>;
  companyId?: Maybe<IntFieldUpdateOperationsInput>;
  total?: Maybe<FloatFieldUpdateOperationsInput>;
  billId?: Maybe<NullableIntFieldUpdateOperationsInput>;
};

export type OrderUpdateWithoutInvoicesInput = {
  assignedAt?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  cargoType?: Maybe<NullableEnumCargoTypeFieldUpdateOperationsInput>;
  comments?: Maybe<NullableStringFieldUpdateOperationsInput>;
  completedAt?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  distance?: Maybe<NullableIntFieldUpdateOperationsInput>;
  ourRate?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  range?: Maybe<NullableStringFieldUpdateOperationsInput>;
  requestedAt?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  status?: Maybe<NullableEnumOrderStatusFieldUpdateOperationsInput>;
  theirRate?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  truckType?: Maybe<NullableEnumTruckTypeFieldUpdateOperationsInput>;
  units?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  waypoints?: Maybe<Scalars['Json']>;
  weight?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  receipts?: Maybe<Array<Maybe<Scalars['String']>>>;
  assignedTo?: Maybe<CompanyUpdateOneWithoutAssignedInput>;
  carriedBy?: Maybe<EmployeeUpdateOneWithoutAssignedInput>;
  destination?: Maybe<ShedUpdateOneWithoutAsDestinationInput>;
  origin?: Maybe<ShedUpdateOneWithoutAsOriginInput>;
  requestedBy?: Maybe<CompanyUpdateOneWithoutRequestedInput>;
};

export type OrderUncheckedUpdateWithoutInvoicesInput = {
  id?: Maybe<IntFieldUpdateOperationsInput>;
  assignedAt?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  cargoType?: Maybe<NullableEnumCargoTypeFieldUpdateOperationsInput>;
  comments?: Maybe<NullableStringFieldUpdateOperationsInput>;
  completedAt?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  distance?: Maybe<NullableIntFieldUpdateOperationsInput>;
  ourRate?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  range?: Maybe<NullableStringFieldUpdateOperationsInput>;
  requestedAt?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  status?: Maybe<NullableEnumOrderStatusFieldUpdateOperationsInput>;
  theirRate?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  truckType?: Maybe<NullableEnumTruckTypeFieldUpdateOperationsInput>;
  units?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  waypoints?: Maybe<Scalars['Json']>;
  weight?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  assignedToId?: Maybe<NullableIntFieldUpdateOperationsInput>;
  carriedById?: Maybe<NullableIntFieldUpdateOperationsInput>;
  destinationId?: Maybe<NullableIntFieldUpdateOperationsInput>;
  originId?: Maybe<NullableIntFieldUpdateOperationsInput>;
  requestedById?: Maybe<NullableIntFieldUpdateOperationsInput>;
  receipts?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type OrderUncheckedUpdateManyWithoutItemsInput = {
  id?: Maybe<IntFieldUpdateOperationsInput>;
  assignedAt?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  cargoType?: Maybe<NullableEnumCargoTypeFieldUpdateOperationsInput>;
  comments?: Maybe<NullableStringFieldUpdateOperationsInput>;
  completedAt?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  distance?: Maybe<NullableIntFieldUpdateOperationsInput>;
  ourRate?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  range?: Maybe<NullableStringFieldUpdateOperationsInput>;
  requestedAt?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  status?: Maybe<NullableEnumOrderStatusFieldUpdateOperationsInput>;
  theirRate?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  truckType?: Maybe<NullableEnumTruckTypeFieldUpdateOperationsInput>;
  units?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  waypoints?: Maybe<Scalars['Json']>;
  weight?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  assignedToId?: Maybe<NullableIntFieldUpdateOperationsInput>;
  carriedById?: Maybe<NullableIntFieldUpdateOperationsInput>;
  destinationId?: Maybe<NullableIntFieldUpdateOperationsInput>;
  originId?: Maybe<NullableIntFieldUpdateOperationsInput>;
  requestedById?: Maybe<NullableIntFieldUpdateOperationsInput>;
  receipts?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type InvoiceUpdateWithoutBillInput = {
  date?: Maybe<DateTimeFieldUpdateOperationsInput>;
  total?: Maybe<FloatFieldUpdateOperationsInput>;
  company?: Maybe<CompanyUpdateOneRequiredWithoutInvoicesInput>;
  items?: Maybe<OrderUpdateManyWithoutInvoicesInput>;
};

export type InvoiceUncheckedUpdateWithoutBillInput = {
  id?: Maybe<IntFieldUpdateOperationsInput>;
  date?: Maybe<DateTimeFieldUpdateOperationsInput>;
  companyId?: Maybe<IntFieldUpdateOperationsInput>;
  total?: Maybe<FloatFieldUpdateOperationsInput>;
};

export type InvoiceUncheckedUpdateManyWithoutItemsInput = {
  id?: Maybe<IntFieldUpdateOperationsInput>;
  date?: Maybe<DateTimeFieldUpdateOperationsInput>;
  companyId?: Maybe<IntFieldUpdateOperationsInput>;
  total?: Maybe<FloatFieldUpdateOperationsInput>;
};

export type PaymentUpdateWithoutReferenceInput = {
  date?: Maybe<DateTimeFieldUpdateOperationsInput>;
  amount?: Maybe<FloatFieldUpdateOperationsInput>;
  source?: Maybe<CompanyUpdateOneRequiredWithoutPaymentsInput>;
};

export type PaymentUncheckedUpdateWithoutReferenceInput = {
  id?: Maybe<IntFieldUpdateOperationsInput>;
  date?: Maybe<DateTimeFieldUpdateOperationsInput>;
  sourceId?: Maybe<IntFieldUpdateOperationsInput>;
  amount?: Maybe<FloatFieldUpdateOperationsInput>;
};

export type OrderUpdateWithoutOriginInput = {
  assignedAt?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  cargoType?: Maybe<NullableEnumCargoTypeFieldUpdateOperationsInput>;
  comments?: Maybe<NullableStringFieldUpdateOperationsInput>;
  completedAt?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  distance?: Maybe<NullableIntFieldUpdateOperationsInput>;
  ourRate?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  range?: Maybe<NullableStringFieldUpdateOperationsInput>;
  requestedAt?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  status?: Maybe<NullableEnumOrderStatusFieldUpdateOperationsInput>;
  theirRate?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  truckType?: Maybe<NullableEnumTruckTypeFieldUpdateOperationsInput>;
  units?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  waypoints?: Maybe<Scalars['Json']>;
  weight?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  receipts?: Maybe<Array<Maybe<Scalars['String']>>>;
  assignedTo?: Maybe<CompanyUpdateOneWithoutAssignedInput>;
  carriedBy?: Maybe<EmployeeUpdateOneWithoutAssignedInput>;
  destination?: Maybe<ShedUpdateOneWithoutAsDestinationInput>;
  invoices?: Maybe<InvoiceUpdateManyWithoutItemsInput>;
  requestedBy?: Maybe<CompanyUpdateOneWithoutRequestedInput>;
};

export type OrderUncheckedUpdateWithoutOriginInput = {
  id?: Maybe<IntFieldUpdateOperationsInput>;
  assignedAt?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  cargoType?: Maybe<NullableEnumCargoTypeFieldUpdateOperationsInput>;
  comments?: Maybe<NullableStringFieldUpdateOperationsInput>;
  completedAt?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  distance?: Maybe<NullableIntFieldUpdateOperationsInput>;
  ourRate?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  range?: Maybe<NullableStringFieldUpdateOperationsInput>;
  requestedAt?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  status?: Maybe<NullableEnumOrderStatusFieldUpdateOperationsInput>;
  theirRate?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  truckType?: Maybe<NullableEnumTruckTypeFieldUpdateOperationsInput>;
  units?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  waypoints?: Maybe<Scalars['Json']>;
  weight?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  assignedToId?: Maybe<NullableIntFieldUpdateOperationsInput>;
  carriedById?: Maybe<NullableIntFieldUpdateOperationsInput>;
  destinationId?: Maybe<NullableIntFieldUpdateOperationsInput>;
  requestedById?: Maybe<NullableIntFieldUpdateOperationsInput>;
  receipts?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type OrderUncheckedUpdateManyWithoutAsOriginInput = {
  id?: Maybe<IntFieldUpdateOperationsInput>;
  assignedAt?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  cargoType?: Maybe<NullableEnumCargoTypeFieldUpdateOperationsInput>;
  comments?: Maybe<NullableStringFieldUpdateOperationsInput>;
  completedAt?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  distance?: Maybe<NullableIntFieldUpdateOperationsInput>;
  ourRate?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  range?: Maybe<NullableStringFieldUpdateOperationsInput>;
  requestedAt?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  status?: Maybe<NullableEnumOrderStatusFieldUpdateOperationsInput>;
  theirRate?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  truckType?: Maybe<NullableEnumTruckTypeFieldUpdateOperationsInput>;
  units?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  waypoints?: Maybe<Scalars['Json']>;
  weight?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  assignedToId?: Maybe<NullableIntFieldUpdateOperationsInput>;
  carriedById?: Maybe<NullableIntFieldUpdateOperationsInput>;
  destinationId?: Maybe<NullableIntFieldUpdateOperationsInput>;
  requestedById?: Maybe<NullableIntFieldUpdateOperationsInput>;
  receipts?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type OrderUpdateWithoutDestinationInput = {
  assignedAt?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  cargoType?: Maybe<NullableEnumCargoTypeFieldUpdateOperationsInput>;
  comments?: Maybe<NullableStringFieldUpdateOperationsInput>;
  completedAt?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  distance?: Maybe<NullableIntFieldUpdateOperationsInput>;
  ourRate?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  range?: Maybe<NullableStringFieldUpdateOperationsInput>;
  requestedAt?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  status?: Maybe<NullableEnumOrderStatusFieldUpdateOperationsInput>;
  theirRate?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  truckType?: Maybe<NullableEnumTruckTypeFieldUpdateOperationsInput>;
  units?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  waypoints?: Maybe<Scalars['Json']>;
  weight?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  receipts?: Maybe<Array<Maybe<Scalars['String']>>>;
  assignedTo?: Maybe<CompanyUpdateOneWithoutAssignedInput>;
  carriedBy?: Maybe<EmployeeUpdateOneWithoutAssignedInput>;
  invoices?: Maybe<InvoiceUpdateManyWithoutItemsInput>;
  origin?: Maybe<ShedUpdateOneWithoutAsOriginInput>;
  requestedBy?: Maybe<CompanyUpdateOneWithoutRequestedInput>;
};

export type OrderUncheckedUpdateWithoutDestinationInput = {
  id?: Maybe<IntFieldUpdateOperationsInput>;
  assignedAt?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  cargoType?: Maybe<NullableEnumCargoTypeFieldUpdateOperationsInput>;
  comments?: Maybe<NullableStringFieldUpdateOperationsInput>;
  completedAt?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  distance?: Maybe<NullableIntFieldUpdateOperationsInput>;
  ourRate?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  range?: Maybe<NullableStringFieldUpdateOperationsInput>;
  requestedAt?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  status?: Maybe<NullableEnumOrderStatusFieldUpdateOperationsInput>;
  theirRate?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  truckType?: Maybe<NullableEnumTruckTypeFieldUpdateOperationsInput>;
  units?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  waypoints?: Maybe<Scalars['Json']>;
  weight?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  assignedToId?: Maybe<NullableIntFieldUpdateOperationsInput>;
  carriedById?: Maybe<NullableIntFieldUpdateOperationsInput>;
  originId?: Maybe<NullableIntFieldUpdateOperationsInput>;
  requestedById?: Maybe<NullableIntFieldUpdateOperationsInput>;
  receipts?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type OrderUncheckedUpdateManyWithoutAsDestinationInput = {
  id?: Maybe<IntFieldUpdateOperationsInput>;
  assignedAt?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  cargoType?: Maybe<NullableEnumCargoTypeFieldUpdateOperationsInput>;
  comments?: Maybe<NullableStringFieldUpdateOperationsInput>;
  completedAt?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  distance?: Maybe<NullableIntFieldUpdateOperationsInput>;
  ourRate?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  range?: Maybe<NullableStringFieldUpdateOperationsInput>;
  requestedAt?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  status?: Maybe<NullableEnumOrderStatusFieldUpdateOperationsInput>;
  theirRate?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  truckType?: Maybe<NullableEnumTruckTypeFieldUpdateOperationsInput>;
  units?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  waypoints?: Maybe<Scalars['Json']>;
  weight?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  assignedToId?: Maybe<NullableIntFieldUpdateOperationsInput>;
  carriedById?: Maybe<NullableIntFieldUpdateOperationsInput>;
  originId?: Maybe<NullableIntFieldUpdateOperationsInput>;
  requestedById?: Maybe<NullableIntFieldUpdateOperationsInput>;
  receipts?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type AggregateUser = {
  __typename?: 'AggregateUser';
  count?: Maybe<UserCountAggregateOutputType>;
  min?: Maybe<UserMinAggregateOutputType>;
  max?: Maybe<UserMaxAggregateOutputType>;
};

export type AggregateCompany = {
  __typename?: 'AggregateCompany';
  count?: Maybe<CompanyCountAggregateOutputType>;
  avg?: Maybe<CompanyAvgAggregateOutputType>;
  sum?: Maybe<CompanySumAggregateOutputType>;
  min?: Maybe<CompanyMinAggregateOutputType>;
  max?: Maybe<CompanyMaxAggregateOutputType>;
};

export type AggregateEmployee = {
  __typename?: 'AggregateEmployee';
  count?: Maybe<EmployeeCountAggregateOutputType>;
  avg?: Maybe<EmployeeAvgAggregateOutputType>;
  sum?: Maybe<EmployeeSumAggregateOutputType>;
  min?: Maybe<EmployeeMinAggregateOutputType>;
  max?: Maybe<EmployeeMaxAggregateOutputType>;
};

export type AggregateOrder = {
  __typename?: 'AggregateOrder';
  count?: Maybe<OrderCountAggregateOutputType>;
  avg?: Maybe<OrderAvgAggregateOutputType>;
  sum?: Maybe<OrderSumAggregateOutputType>;
  min?: Maybe<OrderMinAggregateOutputType>;
  max?: Maybe<OrderMaxAggregateOutputType>;
};

export type AggregateInvoice = {
  __typename?: 'AggregateInvoice';
  count?: Maybe<InvoiceCountAggregateOutputType>;
  avg?: Maybe<InvoiceAvgAggregateOutputType>;
  sum?: Maybe<InvoiceSumAggregateOutputType>;
  min?: Maybe<InvoiceMinAggregateOutputType>;
  max?: Maybe<InvoiceMaxAggregateOutputType>;
};

export type AggregateBill = {
  __typename?: 'AggregateBill';
  count?: Maybe<BillCountAggregateOutputType>;
  avg?: Maybe<BillAvgAggregateOutputType>;
  sum?: Maybe<BillSumAggregateOutputType>;
  min?: Maybe<BillMinAggregateOutputType>;
  max?: Maybe<BillMaxAggregateOutputType>;
};

export type AggregatePayment = {
  __typename?: 'AggregatePayment';
  count?: Maybe<PaymentCountAggregateOutputType>;
  avg?: Maybe<PaymentAvgAggregateOutputType>;
  sum?: Maybe<PaymentSumAggregateOutputType>;
  min?: Maybe<PaymentMinAggregateOutputType>;
  max?: Maybe<PaymentMaxAggregateOutputType>;
};

export type AggregateShed = {
  __typename?: 'AggregateShed';
  count?: Maybe<ShedCountAggregateOutputType>;
  avg?: Maybe<ShedAvgAggregateOutputType>;
  sum?: Maybe<ShedSumAggregateOutputType>;
  min?: Maybe<ShedMinAggregateOutputType>;
  max?: Maybe<ShedMaxAggregateOutputType>;
};

export type UserCountAggregateOutputType = {
  __typename?: 'UserCountAggregateOutputType';
  id?: Maybe<Scalars['Int']>;
  email?: Maybe<Scalars['Int']>;
  password?: Maybe<Scalars['Int']>;
  role?: Maybe<Scalars['Int']>;
  status?: Maybe<Scalars['Int']>;
  _all: Scalars['Int'];
};

export type UserMinAggregateOutputType = {
  __typename?: 'UserMinAggregateOutputType';
  id?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  password?: Maybe<Scalars['String']>;
  role?: Maybe<Role>;
  status?: Maybe<Status>;
};

export type UserMaxAggregateOutputType = {
  __typename?: 'UserMaxAggregateOutputType';
  id?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  password?: Maybe<Scalars['String']>;
  role?: Maybe<Role>;
  status?: Maybe<Status>;
};

export type CompanyCountAggregateOutputType = {
  __typename?: 'CompanyCountAggregateOutputType';
  id: Scalars['Int'];
  name?: Maybe<Scalars['Int']>;
  cuit?: Maybe<Scalars['Int']>;
  isCustomer?: Maybe<Scalars['Int']>;
  isSupplier?: Maybe<Scalars['Int']>;
  type?: Maybe<Scalars['Int']>;
  _all: Scalars['Int'];
};

export type CompanyAvgAggregateOutputType = {
  __typename?: 'CompanyAvgAggregateOutputType';
  id: Scalars['Float'];
};

export type CompanySumAggregateOutputType = {
  __typename?: 'CompanySumAggregateOutputType';
  id: Scalars['Int'];
};

export type CompanyMinAggregateOutputType = {
  __typename?: 'CompanyMinAggregateOutputType';
  id: Scalars['Int'];
  name?: Maybe<Scalars['String']>;
  cuit?: Maybe<Scalars['String']>;
  isCustomer?: Maybe<Scalars['Boolean']>;
  isSupplier?: Maybe<Scalars['Boolean']>;
  type?: Maybe<CompanyType>;
};

export type CompanyMaxAggregateOutputType = {
  __typename?: 'CompanyMaxAggregateOutputType';
  id: Scalars['Int'];
  name?: Maybe<Scalars['String']>;
  cuit?: Maybe<Scalars['String']>;
  isCustomer?: Maybe<Scalars['Boolean']>;
  isSupplier?: Maybe<Scalars['Boolean']>;
  type?: Maybe<CompanyType>;
};

export type EmployeeCountAggregateOutputType = {
  __typename?: 'EmployeeCountAggregateOutputType';
  id: Scalars['Int'];
  name?: Maybe<Scalars['Int']>;
  dni?: Maybe<Scalars['Int']>;
  companyId: Scalars['Int'];
  status?: Maybe<Scalars['Int']>;
  _all: Scalars['Int'];
};

export type EmployeeAvgAggregateOutputType = {
  __typename?: 'EmployeeAvgAggregateOutputType';
  id: Scalars['Float'];
  companyId: Scalars['Float'];
};

export type EmployeeSumAggregateOutputType = {
  __typename?: 'EmployeeSumAggregateOutputType';
  id: Scalars['Int'];
  companyId: Scalars['Int'];
};

export type EmployeeMinAggregateOutputType = {
  __typename?: 'EmployeeMinAggregateOutputType';
  id: Scalars['Int'];
  name?: Maybe<Scalars['String']>;
  dni?: Maybe<Scalars['String']>;
  companyId: Scalars['Int'];
  status?: Maybe<EmployeeStatus>;
};

export type EmployeeMaxAggregateOutputType = {
  __typename?: 'EmployeeMaxAggregateOutputType';
  id: Scalars['Int'];
  name?: Maybe<Scalars['String']>;
  dni?: Maybe<Scalars['String']>;
  companyId: Scalars['Int'];
  status?: Maybe<EmployeeStatus>;
};

export type OrderCountAggregateOutputType = {
  __typename?: 'OrderCountAggregateOutputType';
  id: Scalars['Int'];
  assignedAt?: Maybe<Scalars['Int']>;
  cargoType?: Maybe<Scalars['Int']>;
  comments?: Maybe<Scalars['Int']>;
  completedAt?: Maybe<Scalars['Int']>;
  distance?: Maybe<Scalars['Int']>;
  ourRate?: Maybe<Scalars['Int']>;
  range?: Maybe<Scalars['Int']>;
  receipts?: Maybe<Scalars['Int']>;
  requestedAt?: Maybe<Scalars['Int']>;
  status?: Maybe<Scalars['Int']>;
  theirRate?: Maybe<Scalars['Int']>;
  truckType?: Maybe<Scalars['Int']>;
  units?: Maybe<Scalars['Int']>;
  waypoints?: Maybe<Scalars['Int']>;
  weight?: Maybe<Scalars['Int']>;
  assignedToId?: Maybe<Scalars['Int']>;
  carriedById?: Maybe<Scalars['Int']>;
  destinationId?: Maybe<Scalars['Int']>;
  originId?: Maybe<Scalars['Int']>;
  requestedById?: Maybe<Scalars['Int']>;
  _all: Scalars['Int'];
};

export type OrderAvgAggregateOutputType = {
  __typename?: 'OrderAvgAggregateOutputType';
  id: Scalars['Float'];
  distance?: Maybe<Scalars['Float']>;
  ourRate?: Maybe<Scalars['Float']>;
  theirRate?: Maybe<Scalars['Float']>;
  units?: Maybe<Scalars['Float']>;
  weight?: Maybe<Scalars['Float']>;
  assignedToId?: Maybe<Scalars['Float']>;
  carriedById?: Maybe<Scalars['Float']>;
  destinationId?: Maybe<Scalars['Float']>;
  originId?: Maybe<Scalars['Float']>;
  requestedById?: Maybe<Scalars['Float']>;
};

export type OrderSumAggregateOutputType = {
  __typename?: 'OrderSumAggregateOutputType';
  id: Scalars['Int'];
  distance?: Maybe<Scalars['Int']>;
  ourRate?: Maybe<Scalars['Float']>;
  theirRate?: Maybe<Scalars['Float']>;
  units?: Maybe<Scalars['Float']>;
  weight?: Maybe<Scalars['Float']>;
  assignedToId?: Maybe<Scalars['Int']>;
  carriedById?: Maybe<Scalars['Int']>;
  destinationId?: Maybe<Scalars['Int']>;
  originId?: Maybe<Scalars['Int']>;
  requestedById?: Maybe<Scalars['Int']>;
};

export type OrderMinAggregateOutputType = {
  __typename?: 'OrderMinAggregateOutputType';
  id: Scalars['Int'];
  assignedAt?: Maybe<Scalars['DateTime']>;
  cargoType?: Maybe<CargoType>;
  comments?: Maybe<Scalars['String']>;
  completedAt?: Maybe<Scalars['DateTime']>;
  distance?: Maybe<Scalars['Int']>;
  ourRate?: Maybe<Scalars['Float']>;
  range?: Maybe<Scalars['String']>;
  requestedAt?: Maybe<Scalars['DateTime']>;
  status?: Maybe<OrderStatus>;
  theirRate?: Maybe<Scalars['Float']>;
  truckType?: Maybe<TruckType>;
  units?: Maybe<Scalars['Float']>;
  weight?: Maybe<Scalars['Float']>;
  assignedToId?: Maybe<Scalars['Int']>;
  carriedById?: Maybe<Scalars['Int']>;
  destinationId?: Maybe<Scalars['Int']>;
  originId?: Maybe<Scalars['Int']>;
  requestedById?: Maybe<Scalars['Int']>;
};

export type OrderMaxAggregateOutputType = {
  __typename?: 'OrderMaxAggregateOutputType';
  id: Scalars['Int'];
  assignedAt?: Maybe<Scalars['DateTime']>;
  cargoType?: Maybe<CargoType>;
  comments?: Maybe<Scalars['String']>;
  completedAt?: Maybe<Scalars['DateTime']>;
  distance?: Maybe<Scalars['Int']>;
  ourRate?: Maybe<Scalars['Float']>;
  range?: Maybe<Scalars['String']>;
  requestedAt?: Maybe<Scalars['DateTime']>;
  status?: Maybe<OrderStatus>;
  theirRate?: Maybe<Scalars['Float']>;
  truckType?: Maybe<TruckType>;
  units?: Maybe<Scalars['Float']>;
  weight?: Maybe<Scalars['Float']>;
  assignedToId?: Maybe<Scalars['Int']>;
  carriedById?: Maybe<Scalars['Int']>;
  destinationId?: Maybe<Scalars['Int']>;
  originId?: Maybe<Scalars['Int']>;
  requestedById?: Maybe<Scalars['Int']>;
};

export type InvoiceCountAggregateOutputType = {
  __typename?: 'InvoiceCountAggregateOutputType';
  id: Scalars['Int'];
  date?: Maybe<Scalars['Int']>;
  companyId: Scalars['Int'];
  total: Scalars['Int'];
  billId?: Maybe<Scalars['Int']>;
  _all: Scalars['Int'];
};

export type InvoiceAvgAggregateOutputType = {
  __typename?: 'InvoiceAvgAggregateOutputType';
  id: Scalars['Float'];
  companyId: Scalars['Float'];
  total: Scalars['Float'];
  billId?: Maybe<Scalars['Float']>;
};

export type InvoiceSumAggregateOutputType = {
  __typename?: 'InvoiceSumAggregateOutputType';
  id: Scalars['Int'];
  companyId: Scalars['Int'];
  total: Scalars['Float'];
  billId?: Maybe<Scalars['Int']>;
};

export type InvoiceMinAggregateOutputType = {
  __typename?: 'InvoiceMinAggregateOutputType';
  id: Scalars['Int'];
  date?: Maybe<Scalars['DateTime']>;
  companyId: Scalars['Int'];
  total: Scalars['Float'];
  billId?: Maybe<Scalars['Int']>;
};

export type InvoiceMaxAggregateOutputType = {
  __typename?: 'InvoiceMaxAggregateOutputType';
  id: Scalars['Int'];
  date?: Maybe<Scalars['DateTime']>;
  companyId: Scalars['Int'];
  total: Scalars['Float'];
  billId?: Maybe<Scalars['Int']>;
};

export type BillCountAggregateOutputType = {
  __typename?: 'BillCountAggregateOutputType';
  id: Scalars['Int'];
  date?: Maybe<Scalars['Int']>;
  companyId: Scalars['Int'];
  amount: Scalars['Int'];
  vat: Scalars['Int'];
  _all: Scalars['Int'];
};

export type BillAvgAggregateOutputType = {
  __typename?: 'BillAvgAggregateOutputType';
  id: Scalars['Float'];
  companyId: Scalars['Float'];
  amount: Scalars['Float'];
  vat: Scalars['Float'];
};

export type BillSumAggregateOutputType = {
  __typename?: 'BillSumAggregateOutputType';
  id: Scalars['Int'];
  companyId: Scalars['Int'];
  amount: Scalars['Float'];
  vat: Scalars['Float'];
};

export type BillMinAggregateOutputType = {
  __typename?: 'BillMinAggregateOutputType';
  id: Scalars['Int'];
  date?: Maybe<Scalars['DateTime']>;
  companyId: Scalars['Int'];
  amount: Scalars['Float'];
  vat: Scalars['Float'];
};

export type BillMaxAggregateOutputType = {
  __typename?: 'BillMaxAggregateOutputType';
  id: Scalars['Int'];
  date?: Maybe<Scalars['DateTime']>;
  companyId: Scalars['Int'];
  amount: Scalars['Float'];
  vat: Scalars['Float'];
};

export type PaymentCountAggregateOutputType = {
  __typename?: 'PaymentCountAggregateOutputType';
  id: Scalars['Int'];
  date?: Maybe<Scalars['Int']>;
  sourceId: Scalars['Int'];
  amount: Scalars['Int'];
  referenceId?: Maybe<Scalars['Int']>;
  _all: Scalars['Int'];
};

export type PaymentAvgAggregateOutputType = {
  __typename?: 'PaymentAvgAggregateOutputType';
  id: Scalars['Float'];
  sourceId: Scalars['Float'];
  amount: Scalars['Float'];
  referenceId?: Maybe<Scalars['Float']>;
};

export type PaymentSumAggregateOutputType = {
  __typename?: 'PaymentSumAggregateOutputType';
  id: Scalars['Int'];
  sourceId: Scalars['Int'];
  amount: Scalars['Float'];
  referenceId?: Maybe<Scalars['Int']>;
};

export type PaymentMinAggregateOutputType = {
  __typename?: 'PaymentMinAggregateOutputType';
  id: Scalars['Int'];
  date?: Maybe<Scalars['DateTime']>;
  sourceId: Scalars['Int'];
  amount: Scalars['Float'];
  referenceId?: Maybe<Scalars['Int']>;
};

export type PaymentMaxAggregateOutputType = {
  __typename?: 'PaymentMaxAggregateOutputType';
  id: Scalars['Int'];
  date?: Maybe<Scalars['DateTime']>;
  sourceId: Scalars['Int'];
  amount: Scalars['Float'];
  referenceId?: Maybe<Scalars['Int']>;
};

export type ShedCountAggregateOutputType = {
  __typename?: 'ShedCountAggregateOutputType';
  id: Scalars['Int'];
  name?: Maybe<Scalars['Int']>;
  latitude: Scalars['Int'];
  longitude: Scalars['Int'];
  _all: Scalars['Int'];
};

export type ShedAvgAggregateOutputType = {
  __typename?: 'ShedAvgAggregateOutputType';
  id: Scalars['Float'];
  latitude: Scalars['Float'];
  longitude: Scalars['Float'];
};

export type ShedSumAggregateOutputType = {
  __typename?: 'ShedSumAggregateOutputType';
  id: Scalars['Int'];
  latitude: Scalars['Float'];
  longitude: Scalars['Float'];
};

export type ShedMinAggregateOutputType = {
  __typename?: 'ShedMinAggregateOutputType';
  id: Scalars['Int'];
  name?: Maybe<Scalars['String']>;
  latitude: Scalars['Float'];
  longitude: Scalars['Float'];
};

export type ShedMaxAggregateOutputType = {
  __typename?: 'ShedMaxAggregateOutputType';
  id: Scalars['Int'];
  name?: Maybe<Scalars['String']>;
  latitude: Scalars['Float'];
  longitude: Scalars['Float'];
};

export type Query = {
  __typename?: 'Query';
  findUniqueCompany?: Maybe<Company>;
  findManyCompany: Array<Company>;
  findUniqueEmployee?: Maybe<Employee>;
  findManyEmployee: Array<Employee>;
  findUniqueInvoice?: Maybe<Invoice>;
  findManyInvoice: Array<Invoice>;
  findUniqueOrder?: Maybe<Order>;
  findManyOrder: Array<Order>;
  findSimilarOrder?: Maybe<Order>;
  findUniqueShed?: Maybe<Shed>;
  findManyShed: Array<Shed>;
  me?: Maybe<User>;
};


export type QueryFindUniqueCompanyArgs = {
  where: CompanyWhereUniqueInput;
};


export type QueryFindManyCompanyArgs = {
  where?: Maybe<CompanyWhereInput>;
  orderBy?: Maybe<Array<Maybe<CompanyOrderByInput>>>;
  cursor?: Maybe<CompanyWhereUniqueInput>;
  distinct?: Maybe<CompanyScalarFieldEnum>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
};


export type QueryFindUniqueEmployeeArgs = {
  where: EmployeeWhereUniqueInput;
};


export type QueryFindManyEmployeeArgs = {
  where?: Maybe<EmployeeWhereInput>;
  orderBy?: Maybe<Array<Maybe<EmployeeOrderByInput>>>;
  cursor?: Maybe<EmployeeWhereUniqueInput>;
  distinct?: Maybe<EmployeeScalarFieldEnum>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
};


export type QueryFindUniqueInvoiceArgs = {
  where: InvoiceWhereUniqueInput;
};


export type QueryFindManyInvoiceArgs = {
  where?: Maybe<InvoiceWhereInput>;
  orderBy?: Maybe<Array<Maybe<InvoiceOrderByInput>>>;
  cursor?: Maybe<InvoiceWhereUniqueInput>;
  distinct?: Maybe<InvoiceScalarFieldEnum>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
};


export type QueryFindUniqueOrderArgs = {
  where: OrderWhereUniqueInput;
};


export type QueryFindManyOrderArgs = {
  where?: Maybe<OrderWhereInput>;
  orderBy?: Maybe<Array<Maybe<OrderOrderByInput>>>;
  cursor?: Maybe<OrderWhereUniqueInput>;
  distinct?: Maybe<OrderScalarFieldEnum>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
};


export type QueryFindSimilarOrderArgs = {
  origin: Scalars['Int'];
  destination: Scalars['Int'];
};


export type QueryFindUniqueShedArgs = {
  where: ShedWhereUniqueInput;
};


export type QueryFindManyShedArgs = {
  where?: Maybe<ShedWhereInput>;
  orderBy?: Maybe<Array<Maybe<ShedOrderByInput>>>;
  cursor?: Maybe<ShedWhereUniqueInput>;
  distinct?: Maybe<ShedScalarFieldEnum>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
};

export type Mutation = {
  __typename?: 'Mutation';
  createOneCompany: Company;
  updateOneCompany: Company;
  createOneEmployee: Employee;
  updateOneEmployee: Employee;
  createOneInvoice: Invoice;
  updateOneInvoice: Invoice;
  deleteOneInvoice?: Maybe<Invoice>;
  createOneOrder: Order;
  updateOneOrder: Order;
  createOneShed: Shed;
  updateOneShed: Shed;
  login?: Maybe<User>;
  logout: Scalars['Boolean'];
};


export type MutationCreateOneCompanyArgs = {
  data: CompanyCreateInput;
};


export type MutationUpdateOneCompanyArgs = {
  where: CompanyWhereUniqueInput;
  data: CompanyUpdateInput;
};


export type MutationCreateOneEmployeeArgs = {
  data: EmployeeCreateInput;
};


export type MutationUpdateOneEmployeeArgs = {
  where: EmployeeWhereUniqueInput;
  data: EmployeeUpdateInput;
};


export type MutationCreateOneInvoiceArgs = {
  data: InvoiceCreateInput;
};


export type MutationUpdateOneInvoiceArgs = {
  where: InvoiceWhereUniqueInput;
  data: InvoiceUpdateInput;
};


export type MutationDeleteOneInvoiceArgs = {
  where: InvoiceWhereUniqueInput;
};


export type MutationCreateOneOrderArgs = {
  data: OrderCreateInput;
};


export type MutationUpdateOneOrderArgs = {
  where: OrderWhereUniqueInput;
  data: OrderUpdateInput;
};


export type MutationCreateOneShedArgs = {
  data: ShedCreateInput;
};


export type MutationUpdateOneShedArgs = {
  where: ShedWhereUniqueInput;
  data: ShedUpdateInput;
};


export type MutationLoginArgs = {
  email: Scalars['EmailAddress'];
  password: Scalars['String'];
};

export type Subscription = {
  __typename?: 'Subscription';
  orderAdded: Order;
  orderChanged?: Maybe<Order>;
};

export type CompanyFragment = (
  { __typename?: 'Company' }
  & Pick<Company, 'id' | 'name' | 'cuit' | 'isCustomer' | 'isSupplier' | 'type'>
);

export type FindUniqueCompanyQueryVariables = Exact<{
  where: CompanyWhereUniqueInput;
}>;


export type FindUniqueCompanyQuery = (
  { __typename?: 'Query' }
  & { company?: Maybe<(
    { __typename?: 'Company' }
    & { employees: Array<(
      { __typename?: 'Employee' }
      & Pick<Employee, 'id' | 'dni' | 'name'>
    )> }
    & CompanyFragment
  )> }
);

export type FindManyCompanyQueryVariables = Exact<{
  where?: Maybe<CompanyWhereInput>;
  orderBy?: Maybe<Array<Maybe<CompanyOrderByInput>> | Maybe<CompanyOrderByInput>>;
  cursor?: Maybe<CompanyWhereUniqueInput>;
  distinct?: Maybe<CompanyScalarFieldEnum>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
}>;


export type FindManyCompanyQuery = (
  { __typename?: 'Query' }
  & { companies: Array<(
    { __typename?: 'Company' }
    & CompanyFragment
  )> }
);

export type CreateOneCompanyMutationVariables = Exact<{
  data: CompanyCreateInput;
}>;


export type CreateOneCompanyMutation = (
  { __typename?: 'Mutation' }
  & { company: (
    { __typename?: 'Company' }
    & { employees: Array<(
      { __typename?: 'Employee' }
      & Pick<Employee, 'id' | 'dni' | 'name'>
    )> }
    & CompanyFragment
  ) }
);

export type UpdateOneCompanyMutationVariables = Exact<{
  where: CompanyWhereUniqueInput;
  data: CompanyUpdateInput;
}>;


export type UpdateOneCompanyMutation = (
  { __typename?: 'Mutation' }
  & { company: (
    { __typename?: 'Company' }
    & { employees: Array<(
      { __typename?: 'Employee' }
      & Pick<Employee, 'id' | 'dni' | 'name'>
    )> }
    & CompanyFragment
  ) }
);

export type EmployeeFragment = (
  { __typename?: 'Employee' }
  & Pick<Employee, 'id' | 'name' | 'dni' | 'status'>
  & { worksFor: (
    { __typename?: 'Company' }
    & CompanyFragment
  ) }
);

export type FindManyEmployeeQueryVariables = Exact<{
  where?: Maybe<EmployeeWhereInput>;
  orderBy?: Maybe<Array<Maybe<EmployeeOrderByInput>> | Maybe<EmployeeOrderByInput>>;
  cursor?: Maybe<EmployeeWhereUniqueInput>;
  distinct?: Maybe<EmployeeScalarFieldEnum>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
}>;


export type FindManyEmployeeQuery = (
  { __typename?: 'Query' }
  & { employees: Array<(
    { __typename?: 'Employee' }
    & EmployeeFragment
  )> }
);

export type OrderFragment = (
  { __typename?: 'Order' }
  & Pick<Order, 'id' | 'assignedAt' | 'originId' | 'destinationId' | 'cargoType' | 'comments' | 'completedAt' | 'distance' | 'ourRate' | 'ourTotal' | 'range' | 'receipts' | 'requestedAt' | 'status' | 'theirRate' | 'theirTotal' | 'truckType' | 'units' | 'weight' | 'assignedToId' | 'carriedById' | 'requestedById'>
  & { origin?: Maybe<(
    { __typename?: 'Shed' }
    & ShedFragment
  )>, destination?: Maybe<(
    { __typename?: 'Shed' }
    & ShedFragment
  )>, waypoints?: Maybe<Array<(
    { __typename?: 'Waypoint' }
    & WaypointFragment
  )>>, assignedTo?: Maybe<(
    { __typename?: 'Company' }
    & CompanyFragment
  )>, carriedBy?: Maybe<(
    { __typename?: 'Employee' }
    & EmployeeFragment
  )>, requestedBy?: Maybe<(
    { __typename?: 'Company' }
    & CompanyFragment
  )> }
);

export type FindManyOrderQueryVariables = Exact<{
  where?: Maybe<OrderWhereInput>;
  orderBy?: Maybe<Array<Maybe<OrderOrderByInput>> | Maybe<OrderOrderByInput>>;
  cursor?: Maybe<OrderWhereUniqueInput>;
  distinct?: Maybe<OrderScalarFieldEnum>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
}>;


export type FindManyOrderQuery = (
  { __typename?: 'Query' }
  & { orders: Array<(
    { __typename?: 'Order' }
    & OrderFragment
  )> }
);

export type FindUniqueOrderQueryVariables = Exact<{
  where: OrderWhereUniqueInput;
}>;


export type FindUniqueOrderQuery = (
  { __typename?: 'Query' }
  & { order?: Maybe<(
    { __typename?: 'Order' }
    & OrderFragment
  )> }
);

export type FindSimilarOrderQueryVariables = Exact<{
  origin: Scalars['Int'];
  destination: Scalars['Int'];
}>;


export type FindSimilarOrderQuery = (
  { __typename?: 'Query' }
  & { order?: Maybe<(
    { __typename?: 'Order' }
    & { waypoints?: Maybe<Array<(
      { __typename?: 'Waypoint' }
      & WaypointFragment
    )>> }
  )> }
);

export type CreateOneOrderMutationVariables = Exact<{
  data: OrderCreateInput;
}>;


export type CreateOneOrderMutation = (
  { __typename?: 'Mutation' }
  & { order: (
    { __typename?: 'Order' }
    & OrderFragment
  ) }
);

export type UpdateOneOrderMutationVariables = Exact<{
  data: OrderUpdateInput;
  where: OrderWhereUniqueInput;
}>;


export type UpdateOneOrderMutation = (
  { __typename?: 'Mutation' }
  & { order: (
    { __typename?: 'Order' }
    & OrderFragment
  ) }
);

export type OrderAddedSubscriptionVariables = Exact<{ [key: string]: never; }>;


export type OrderAddedSubscription = (
  { __typename?: 'Subscription' }
  & { orderAdded: (
    { __typename?: 'Order' }
    & OrderFragment
  ) }
);

export type OrderChangedSubscriptionVariables = Exact<{ [key: string]: never; }>;


export type OrderChangedSubscription = (
  { __typename?: 'Subscription' }
  & { orderChanged?: Maybe<(
    { __typename?: 'Order' }
    & OrderFragment
  )> }
);

export type ShedFragment = (
  { __typename?: 'Shed' }
  & Pick<Shed, 'id' | 'name' | 'latitude' | 'longitude'>
);

export type FindManyShedQueryVariables = Exact<{
  where?: Maybe<ShedWhereInput>;
  orderBy?: Maybe<Array<Maybe<ShedOrderByInput>> | Maybe<ShedOrderByInput>>;
  cursor?: Maybe<ShedWhereUniqueInput>;
  distinct?: Maybe<ShedScalarFieldEnum>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
}>;


export type FindManyShedQuery = (
  { __typename?: 'Query' }
  & { sheds: Array<(
    { __typename?: 'Shed' }
    & ShedFragment
  )> }
);

export type CreateOneShedMutationVariables = Exact<{
  data: ShedCreateInput;
}>;


export type CreateOneShedMutation = (
  { __typename?: 'Mutation' }
  & { shed: (
    { __typename?: 'Shed' }
    & ShedFragment
  ) }
);

export type UpdateOneShedMutationVariables = Exact<{
  data: ShedUpdateInput;
  where: ShedWhereUniqueInput;
}>;


export type UpdateOneShedMutation = (
  { __typename?: 'Mutation' }
  & { shed: (
    { __typename?: 'Shed' }
    & ShedFragment
  ) }
);

export type UserFragment = (
  { __typename?: 'User' }
  & Pick<User, 'id' | 'email' | 'role'>
);

export type MeQueryVariables = Exact<{ [key: string]: never; }>;


export type MeQuery = (
  { __typename?: 'Query' }
  & { me?: Maybe<(
    { __typename?: 'User' }
    & UserFragment
  )> }
);

export type LoginMutationVariables = Exact<{
  email: Scalars['EmailAddress'];
  password: Scalars['String'];
}>;


export type LoginMutation = (
  { __typename?: 'Mutation' }
  & { currentUser?: Maybe<(
    { __typename?: 'User' }
    & UserFragment
  )> }
);

export type LogoutMutationVariables = Exact<{ [key: string]: never; }>;


export type LogoutMutation = (
  { __typename?: 'Mutation' }
  & { isLoggedOut: Mutation['logout'] }
);

export type WaypointFragment = (
  { __typename?: 'Waypoint' }
  & Pick<Waypoint, 'stopover'>
  & { location: (
    { __typename?: 'Location' }
    & Pick<Location, 'lat' | 'lng'>
  ) }
);

export const ShedFragmentDoc = gql`
    fragment Shed on Shed {
  id
  name
  latitude
  longitude
}
    `;
export const WaypointFragmentDoc = gql`
    fragment Waypoint on Waypoint {
  stopover
  location {
    lat
    lng
  }
}
    `;
export const CompanyFragmentDoc = gql`
    fragment Company on Company {
  id
  name
  cuit
  isCustomer
  isSupplier
  type
}
    `;
export const EmployeeFragmentDoc = gql`
    fragment Employee on Employee {
  id
  name
  dni
  status
  worksFor {
    ...Company
  }
}
    ${CompanyFragmentDoc}`;
export const OrderFragmentDoc = gql`
    fragment Order on Order {
  id
  assignedAt
  originId
  origin {
    ...Shed
  }
  destinationId
  destination {
    ...Shed
  }
  cargoType
  comments
  completedAt
  distance
  ourRate
  ourTotal
  range
  receipts
  requestedAt
  status
  theirRate
  theirTotal
  truckType
  units
  waypoints {
    ...Waypoint
  }
  weight
  assignedToId
  assignedTo {
    ...Company
  }
  carriedById
  carriedBy {
    ...Employee
  }
  requestedById
  requestedBy {
    ...Company
  }
}
    ${ShedFragmentDoc}
${WaypointFragmentDoc}
${CompanyFragmentDoc}
${EmployeeFragmentDoc}`;
export const UserFragmentDoc = gql`
    fragment User on User {
  id
  email
  role
}
    `;
export const FindUniqueCompanyDocument = gql`
    query FindUniqueCompany($where: CompanyWhereUniqueInput!) {
  company: findUniqueCompany(where: $where) {
    ...Company
    employees {
      id
      dni
      name
    }
  }
}
    ${CompanyFragmentDoc}`;

/**
 * __useFindUniqueCompanyQuery__
 *
 * To run a query within a React component, call `useFindUniqueCompanyQuery` and pass it any options that fit your needs.
 * When your component renders, `useFindUniqueCompanyQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFindUniqueCompanyQuery({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function useFindUniqueCompanyQuery(baseOptions: Apollo.QueryHookOptions<FindUniqueCompanyQuery, FindUniqueCompanyQueryVariables>) {
        return Apollo.useQuery<FindUniqueCompanyQuery, FindUniqueCompanyQueryVariables>(FindUniqueCompanyDocument, baseOptions);
      }
export function useFindUniqueCompanyLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<FindUniqueCompanyQuery, FindUniqueCompanyQueryVariables>) {
          return Apollo.useLazyQuery<FindUniqueCompanyQuery, FindUniqueCompanyQueryVariables>(FindUniqueCompanyDocument, baseOptions);
        }
export type FindUniqueCompanyQueryHookResult = ReturnType<typeof useFindUniqueCompanyQuery>;
export type FindUniqueCompanyLazyQueryHookResult = ReturnType<typeof useFindUniqueCompanyLazyQuery>;
export type FindUniqueCompanyQueryResult = Apollo.QueryResult<FindUniqueCompanyQuery, FindUniqueCompanyQueryVariables>;
export const FindManyCompanyDocument = gql`
    query FindManyCompany($where: CompanyWhereInput, $orderBy: [CompanyOrderByInput], $cursor: CompanyWhereUniqueInput, $distinct: CompanyScalarFieldEnum, $skip: Int, $take: Int) {
  companies: findManyCompany(
    where: $where
    orderBy: $orderBy
    cursor: $cursor
    distinct: $distinct
    skip: $skip
    take: $take
  ) {
    ...Company
  }
}
    ${CompanyFragmentDoc}`;

/**
 * __useFindManyCompanyQuery__
 *
 * To run a query within a React component, call `useFindManyCompanyQuery` and pass it any options that fit your needs.
 * When your component renders, `useFindManyCompanyQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFindManyCompanyQuery({
 *   variables: {
 *      where: // value for 'where'
 *      orderBy: // value for 'orderBy'
 *      cursor: // value for 'cursor'
 *      distinct: // value for 'distinct'
 *      skip: // value for 'skip'
 *      take: // value for 'take'
 *   },
 * });
 */
export function useFindManyCompanyQuery(baseOptions?: Apollo.QueryHookOptions<FindManyCompanyQuery, FindManyCompanyQueryVariables>) {
        return Apollo.useQuery<FindManyCompanyQuery, FindManyCompanyQueryVariables>(FindManyCompanyDocument, baseOptions);
      }
export function useFindManyCompanyLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<FindManyCompanyQuery, FindManyCompanyQueryVariables>) {
          return Apollo.useLazyQuery<FindManyCompanyQuery, FindManyCompanyQueryVariables>(FindManyCompanyDocument, baseOptions);
        }
export type FindManyCompanyQueryHookResult = ReturnType<typeof useFindManyCompanyQuery>;
export type FindManyCompanyLazyQueryHookResult = ReturnType<typeof useFindManyCompanyLazyQuery>;
export type FindManyCompanyQueryResult = Apollo.QueryResult<FindManyCompanyQuery, FindManyCompanyQueryVariables>;
export const CreateOneCompanyDocument = gql`
    mutation CreateOneCompany($data: CompanyCreateInput!) {
  company: createOneCompany(data: $data) {
    ...Company
    employees {
      id
      dni
      name
    }
  }
}
    ${CompanyFragmentDoc}`;
export type CreateOneCompanyMutationFn = Apollo.MutationFunction<CreateOneCompanyMutation, CreateOneCompanyMutationVariables>;

/**
 * __useCreateOneCompanyMutation__
 *
 * To run a mutation, you first call `useCreateOneCompanyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateOneCompanyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createOneCompanyMutation, { data, loading, error }] = useCreateOneCompanyMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useCreateOneCompanyMutation(baseOptions?: Apollo.MutationHookOptions<CreateOneCompanyMutation, CreateOneCompanyMutationVariables>) {
        return Apollo.useMutation<CreateOneCompanyMutation, CreateOneCompanyMutationVariables>(CreateOneCompanyDocument, baseOptions);
      }
export type CreateOneCompanyMutationHookResult = ReturnType<typeof useCreateOneCompanyMutation>;
export type CreateOneCompanyMutationResult = Apollo.MutationResult<CreateOneCompanyMutation>;
export type CreateOneCompanyMutationOptions = Apollo.BaseMutationOptions<CreateOneCompanyMutation, CreateOneCompanyMutationVariables>;
export const UpdateOneCompanyDocument = gql`
    mutation UpdateOneCompany($where: CompanyWhereUniqueInput!, $data: CompanyUpdateInput!) {
  company: updateOneCompany(where: $where, data: $data) {
    ...Company
    employees {
      id
      dni
      name
    }
  }
}
    ${CompanyFragmentDoc}`;
export type UpdateOneCompanyMutationFn = Apollo.MutationFunction<UpdateOneCompanyMutation, UpdateOneCompanyMutationVariables>;

/**
 * __useUpdateOneCompanyMutation__
 *
 * To run a mutation, you first call `useUpdateOneCompanyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateOneCompanyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateOneCompanyMutation, { data, loading, error }] = useUpdateOneCompanyMutation({
 *   variables: {
 *      where: // value for 'where'
 *      data: // value for 'data'
 *   },
 * });
 */
export function useUpdateOneCompanyMutation(baseOptions?: Apollo.MutationHookOptions<UpdateOneCompanyMutation, UpdateOneCompanyMutationVariables>) {
        return Apollo.useMutation<UpdateOneCompanyMutation, UpdateOneCompanyMutationVariables>(UpdateOneCompanyDocument, baseOptions);
      }
export type UpdateOneCompanyMutationHookResult = ReturnType<typeof useUpdateOneCompanyMutation>;
export type UpdateOneCompanyMutationResult = Apollo.MutationResult<UpdateOneCompanyMutation>;
export type UpdateOneCompanyMutationOptions = Apollo.BaseMutationOptions<UpdateOneCompanyMutation, UpdateOneCompanyMutationVariables>;
export const FindManyEmployeeDocument = gql`
    query FindManyEmployee($where: EmployeeWhereInput, $orderBy: [EmployeeOrderByInput], $cursor: EmployeeWhereUniqueInput, $distinct: EmployeeScalarFieldEnum, $skip: Int, $take: Int) {
  employees: findManyEmployee(
    where: $where
    orderBy: $orderBy
    cursor: $cursor
    distinct: $distinct
    skip: $skip
    take: $take
  ) {
    ...Employee
  }
}
    ${EmployeeFragmentDoc}`;

/**
 * __useFindManyEmployeeQuery__
 *
 * To run a query within a React component, call `useFindManyEmployeeQuery` and pass it any options that fit your needs.
 * When your component renders, `useFindManyEmployeeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFindManyEmployeeQuery({
 *   variables: {
 *      where: // value for 'where'
 *      orderBy: // value for 'orderBy'
 *      cursor: // value for 'cursor'
 *      distinct: // value for 'distinct'
 *      skip: // value for 'skip'
 *      take: // value for 'take'
 *   },
 * });
 */
export function useFindManyEmployeeQuery(baseOptions?: Apollo.QueryHookOptions<FindManyEmployeeQuery, FindManyEmployeeQueryVariables>) {
        return Apollo.useQuery<FindManyEmployeeQuery, FindManyEmployeeQueryVariables>(FindManyEmployeeDocument, baseOptions);
      }
export function useFindManyEmployeeLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<FindManyEmployeeQuery, FindManyEmployeeQueryVariables>) {
          return Apollo.useLazyQuery<FindManyEmployeeQuery, FindManyEmployeeQueryVariables>(FindManyEmployeeDocument, baseOptions);
        }
export type FindManyEmployeeQueryHookResult = ReturnType<typeof useFindManyEmployeeQuery>;
export type FindManyEmployeeLazyQueryHookResult = ReturnType<typeof useFindManyEmployeeLazyQuery>;
export type FindManyEmployeeQueryResult = Apollo.QueryResult<FindManyEmployeeQuery, FindManyEmployeeQueryVariables>;
export const FindManyOrderDocument = gql`
    query FindManyOrder($where: OrderWhereInput, $orderBy: [OrderOrderByInput], $cursor: OrderWhereUniqueInput, $distinct: OrderScalarFieldEnum, $skip: Int, $take: Int) {
  orders: findManyOrder(
    where: $where
    orderBy: $orderBy
    cursor: $cursor
    distinct: $distinct
    skip: $skip
    take: $take
  ) {
    ...Order
  }
}
    ${OrderFragmentDoc}`;

/**
 * __useFindManyOrderQuery__
 *
 * To run a query within a React component, call `useFindManyOrderQuery` and pass it any options that fit your needs.
 * When your component renders, `useFindManyOrderQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFindManyOrderQuery({
 *   variables: {
 *      where: // value for 'where'
 *      orderBy: // value for 'orderBy'
 *      cursor: // value for 'cursor'
 *      distinct: // value for 'distinct'
 *      skip: // value for 'skip'
 *      take: // value for 'take'
 *   },
 * });
 */
export function useFindManyOrderQuery(baseOptions?: Apollo.QueryHookOptions<FindManyOrderQuery, FindManyOrderQueryVariables>) {
        return Apollo.useQuery<FindManyOrderQuery, FindManyOrderQueryVariables>(FindManyOrderDocument, baseOptions);
      }
export function useFindManyOrderLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<FindManyOrderQuery, FindManyOrderQueryVariables>) {
          return Apollo.useLazyQuery<FindManyOrderQuery, FindManyOrderQueryVariables>(FindManyOrderDocument, baseOptions);
        }
export type FindManyOrderQueryHookResult = ReturnType<typeof useFindManyOrderQuery>;
export type FindManyOrderLazyQueryHookResult = ReturnType<typeof useFindManyOrderLazyQuery>;
export type FindManyOrderQueryResult = Apollo.QueryResult<FindManyOrderQuery, FindManyOrderQueryVariables>;
export const FindUniqueOrderDocument = gql`
    query FindUniqueOrder($where: OrderWhereUniqueInput!) {
  order: findUniqueOrder(where: $where) {
    ...Order
  }
}
    ${OrderFragmentDoc}`;

/**
 * __useFindUniqueOrderQuery__
 *
 * To run a query within a React component, call `useFindUniqueOrderQuery` and pass it any options that fit your needs.
 * When your component renders, `useFindUniqueOrderQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFindUniqueOrderQuery({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function useFindUniqueOrderQuery(baseOptions: Apollo.QueryHookOptions<FindUniqueOrderQuery, FindUniqueOrderQueryVariables>) {
        return Apollo.useQuery<FindUniqueOrderQuery, FindUniqueOrderQueryVariables>(FindUniqueOrderDocument, baseOptions);
      }
export function useFindUniqueOrderLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<FindUniqueOrderQuery, FindUniqueOrderQueryVariables>) {
          return Apollo.useLazyQuery<FindUniqueOrderQuery, FindUniqueOrderQueryVariables>(FindUniqueOrderDocument, baseOptions);
        }
export type FindUniqueOrderQueryHookResult = ReturnType<typeof useFindUniqueOrderQuery>;
export type FindUniqueOrderLazyQueryHookResult = ReturnType<typeof useFindUniqueOrderLazyQuery>;
export type FindUniqueOrderQueryResult = Apollo.QueryResult<FindUniqueOrderQuery, FindUniqueOrderQueryVariables>;
export const FindSimilarOrderDocument = gql`
    query FindSimilarOrder($origin: Int!, $destination: Int!) {
  order: findSimilarOrder(origin: $origin, destination: $destination) {
    waypoints {
      ...Waypoint
    }
  }
}
    ${WaypointFragmentDoc}`;

/**
 * __useFindSimilarOrderQuery__
 *
 * To run a query within a React component, call `useFindSimilarOrderQuery` and pass it any options that fit your needs.
 * When your component renders, `useFindSimilarOrderQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFindSimilarOrderQuery({
 *   variables: {
 *      origin: // value for 'origin'
 *      destination: // value for 'destination'
 *   },
 * });
 */
export function useFindSimilarOrderQuery(baseOptions: Apollo.QueryHookOptions<FindSimilarOrderQuery, FindSimilarOrderQueryVariables>) {
        return Apollo.useQuery<FindSimilarOrderQuery, FindSimilarOrderQueryVariables>(FindSimilarOrderDocument, baseOptions);
      }
export function useFindSimilarOrderLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<FindSimilarOrderQuery, FindSimilarOrderQueryVariables>) {
          return Apollo.useLazyQuery<FindSimilarOrderQuery, FindSimilarOrderQueryVariables>(FindSimilarOrderDocument, baseOptions);
        }
export type FindSimilarOrderQueryHookResult = ReturnType<typeof useFindSimilarOrderQuery>;
export type FindSimilarOrderLazyQueryHookResult = ReturnType<typeof useFindSimilarOrderLazyQuery>;
export type FindSimilarOrderQueryResult = Apollo.QueryResult<FindSimilarOrderQuery, FindSimilarOrderQueryVariables>;
export const CreateOneOrderDocument = gql`
    mutation CreateOneOrder($data: OrderCreateInput!) {
  order: createOneOrder(data: $data) {
    ...Order
  }
}
    ${OrderFragmentDoc}`;
export type CreateOneOrderMutationFn = Apollo.MutationFunction<CreateOneOrderMutation, CreateOneOrderMutationVariables>;

/**
 * __useCreateOneOrderMutation__
 *
 * To run a mutation, you first call `useCreateOneOrderMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateOneOrderMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createOneOrderMutation, { data, loading, error }] = useCreateOneOrderMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useCreateOneOrderMutation(baseOptions?: Apollo.MutationHookOptions<CreateOneOrderMutation, CreateOneOrderMutationVariables>) {
        return Apollo.useMutation<CreateOneOrderMutation, CreateOneOrderMutationVariables>(CreateOneOrderDocument, baseOptions);
      }
export type CreateOneOrderMutationHookResult = ReturnType<typeof useCreateOneOrderMutation>;
export type CreateOneOrderMutationResult = Apollo.MutationResult<CreateOneOrderMutation>;
export type CreateOneOrderMutationOptions = Apollo.BaseMutationOptions<CreateOneOrderMutation, CreateOneOrderMutationVariables>;
export const UpdateOneOrderDocument = gql`
    mutation UpdateOneOrder($data: OrderUpdateInput!, $where: OrderWhereUniqueInput!) {
  order: updateOneOrder(data: $data, where: $where) {
    ...Order
  }
}
    ${OrderFragmentDoc}`;
export type UpdateOneOrderMutationFn = Apollo.MutationFunction<UpdateOneOrderMutation, UpdateOneOrderMutationVariables>;

/**
 * __useUpdateOneOrderMutation__
 *
 * To run a mutation, you first call `useUpdateOneOrderMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateOneOrderMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateOneOrderMutation, { data, loading, error }] = useUpdateOneOrderMutation({
 *   variables: {
 *      data: // value for 'data'
 *      where: // value for 'where'
 *   },
 * });
 */
export function useUpdateOneOrderMutation(baseOptions?: Apollo.MutationHookOptions<UpdateOneOrderMutation, UpdateOneOrderMutationVariables>) {
        return Apollo.useMutation<UpdateOneOrderMutation, UpdateOneOrderMutationVariables>(UpdateOneOrderDocument, baseOptions);
      }
export type UpdateOneOrderMutationHookResult = ReturnType<typeof useUpdateOneOrderMutation>;
export type UpdateOneOrderMutationResult = Apollo.MutationResult<UpdateOneOrderMutation>;
export type UpdateOneOrderMutationOptions = Apollo.BaseMutationOptions<UpdateOneOrderMutation, UpdateOneOrderMutationVariables>;
export const OrderAddedDocument = gql`
    subscription OrderAdded {
  orderAdded {
    ...Order
  }
}
    ${OrderFragmentDoc}`;

/**
 * __useOrderAddedSubscription__
 *
 * To run a query within a React component, call `useOrderAddedSubscription` and pass it any options that fit your needs.
 * When your component renders, `useOrderAddedSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOrderAddedSubscription({
 *   variables: {
 *   },
 * });
 */
export function useOrderAddedSubscription(baseOptions?: Apollo.SubscriptionHookOptions<OrderAddedSubscription, OrderAddedSubscriptionVariables>) {
        return Apollo.useSubscription<OrderAddedSubscription, OrderAddedSubscriptionVariables>(OrderAddedDocument, baseOptions);
      }
export type OrderAddedSubscriptionHookResult = ReturnType<typeof useOrderAddedSubscription>;
export type OrderAddedSubscriptionResult = Apollo.SubscriptionResult<OrderAddedSubscription>;
export const OrderChangedDocument = gql`
    subscription OrderChanged {
  orderChanged {
    ...Order
  }
}
    ${OrderFragmentDoc}`;

/**
 * __useOrderChangedSubscription__
 *
 * To run a query within a React component, call `useOrderChangedSubscription` and pass it any options that fit your needs.
 * When your component renders, `useOrderChangedSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOrderChangedSubscription({
 *   variables: {
 *   },
 * });
 */
export function useOrderChangedSubscription(baseOptions?: Apollo.SubscriptionHookOptions<OrderChangedSubscription, OrderChangedSubscriptionVariables>) {
        return Apollo.useSubscription<OrderChangedSubscription, OrderChangedSubscriptionVariables>(OrderChangedDocument, baseOptions);
      }
export type OrderChangedSubscriptionHookResult = ReturnType<typeof useOrderChangedSubscription>;
export type OrderChangedSubscriptionResult = Apollo.SubscriptionResult<OrderChangedSubscription>;
export const FindManyShedDocument = gql`
    query FindManyShed($where: ShedWhereInput, $orderBy: [ShedOrderByInput], $cursor: ShedWhereUniqueInput, $distinct: ShedScalarFieldEnum, $skip: Int, $take: Int) {
  sheds: findManyShed(
    where: $where
    orderBy: $orderBy
    cursor: $cursor
    distinct: $distinct
    skip: $skip
    take: $take
  ) {
    ...Shed
  }
}
    ${ShedFragmentDoc}`;

/**
 * __useFindManyShedQuery__
 *
 * To run a query within a React component, call `useFindManyShedQuery` and pass it any options that fit your needs.
 * When your component renders, `useFindManyShedQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFindManyShedQuery({
 *   variables: {
 *      where: // value for 'where'
 *      orderBy: // value for 'orderBy'
 *      cursor: // value for 'cursor'
 *      distinct: // value for 'distinct'
 *      skip: // value for 'skip'
 *      take: // value for 'take'
 *   },
 * });
 */
export function useFindManyShedQuery(baseOptions?: Apollo.QueryHookOptions<FindManyShedQuery, FindManyShedQueryVariables>) {
        return Apollo.useQuery<FindManyShedQuery, FindManyShedQueryVariables>(FindManyShedDocument, baseOptions);
      }
export function useFindManyShedLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<FindManyShedQuery, FindManyShedQueryVariables>) {
          return Apollo.useLazyQuery<FindManyShedQuery, FindManyShedQueryVariables>(FindManyShedDocument, baseOptions);
        }
export type FindManyShedQueryHookResult = ReturnType<typeof useFindManyShedQuery>;
export type FindManyShedLazyQueryHookResult = ReturnType<typeof useFindManyShedLazyQuery>;
export type FindManyShedQueryResult = Apollo.QueryResult<FindManyShedQuery, FindManyShedQueryVariables>;
export const CreateOneShedDocument = gql`
    mutation CreateOneShed($data: ShedCreateInput!) {
  shed: createOneShed(data: $data) {
    ...Shed
  }
}
    ${ShedFragmentDoc}`;
export type CreateOneShedMutationFn = Apollo.MutationFunction<CreateOneShedMutation, CreateOneShedMutationVariables>;

/**
 * __useCreateOneShedMutation__
 *
 * To run a mutation, you first call `useCreateOneShedMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateOneShedMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createOneShedMutation, { data, loading, error }] = useCreateOneShedMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useCreateOneShedMutation(baseOptions?: Apollo.MutationHookOptions<CreateOneShedMutation, CreateOneShedMutationVariables>) {
        return Apollo.useMutation<CreateOneShedMutation, CreateOneShedMutationVariables>(CreateOneShedDocument, baseOptions);
      }
export type CreateOneShedMutationHookResult = ReturnType<typeof useCreateOneShedMutation>;
export type CreateOneShedMutationResult = Apollo.MutationResult<CreateOneShedMutation>;
export type CreateOneShedMutationOptions = Apollo.BaseMutationOptions<CreateOneShedMutation, CreateOneShedMutationVariables>;
export const UpdateOneShedDocument = gql`
    mutation UpdateOneShed($data: ShedUpdateInput!, $where: ShedWhereUniqueInput!) {
  shed: updateOneShed(data: $data, where: $where) {
    ...Shed
  }
}
    ${ShedFragmentDoc}`;
export type UpdateOneShedMutationFn = Apollo.MutationFunction<UpdateOneShedMutation, UpdateOneShedMutationVariables>;

/**
 * __useUpdateOneShedMutation__
 *
 * To run a mutation, you first call `useUpdateOneShedMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateOneShedMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateOneShedMutation, { data, loading, error }] = useUpdateOneShedMutation({
 *   variables: {
 *      data: // value for 'data'
 *      where: // value for 'where'
 *   },
 * });
 */
export function useUpdateOneShedMutation(baseOptions?: Apollo.MutationHookOptions<UpdateOneShedMutation, UpdateOneShedMutationVariables>) {
        return Apollo.useMutation<UpdateOneShedMutation, UpdateOneShedMutationVariables>(UpdateOneShedDocument, baseOptions);
      }
export type UpdateOneShedMutationHookResult = ReturnType<typeof useUpdateOneShedMutation>;
export type UpdateOneShedMutationResult = Apollo.MutationResult<UpdateOneShedMutation>;
export type UpdateOneShedMutationOptions = Apollo.BaseMutationOptions<UpdateOneShedMutation, UpdateOneShedMutationVariables>;
export const MeDocument = gql`
    query Me {
  me {
    ...User
  }
}
    ${UserFragmentDoc}`;

/**
 * __useMeQuery__
 *
 * To run a query within a React component, call `useMeQuery` and pass it any options that fit your needs.
 * When your component renders, `useMeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMeQuery({
 *   variables: {
 *   },
 * });
 */
export function useMeQuery(baseOptions?: Apollo.QueryHookOptions<MeQuery, MeQueryVariables>) {
        return Apollo.useQuery<MeQuery, MeQueryVariables>(MeDocument, baseOptions);
      }
export function useMeLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<MeQuery, MeQueryVariables>) {
          return Apollo.useLazyQuery<MeQuery, MeQueryVariables>(MeDocument, baseOptions);
        }
export type MeQueryHookResult = ReturnType<typeof useMeQuery>;
export type MeLazyQueryHookResult = ReturnType<typeof useMeLazyQuery>;
export type MeQueryResult = Apollo.QueryResult<MeQuery, MeQueryVariables>;
export const LoginDocument = gql`
    mutation Login($email: EmailAddress!, $password: String!) {
  currentUser: login(email: $email, password: $password) {
    ...User
  }
}
    ${UserFragmentDoc}`;
export type LoginMutationFn = Apollo.MutationFunction<LoginMutation, LoginMutationVariables>;

/**
 * __useLoginMutation__
 *
 * To run a mutation, you first call `useLoginMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLoginMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [loginMutation, { data, loading, error }] = useLoginMutation({
 *   variables: {
 *      email: // value for 'email'
 *      password: // value for 'password'
 *   },
 * });
 */
export function useLoginMutation(baseOptions?: Apollo.MutationHookOptions<LoginMutation, LoginMutationVariables>) {
        return Apollo.useMutation<LoginMutation, LoginMutationVariables>(LoginDocument, baseOptions);
      }
export type LoginMutationHookResult = ReturnType<typeof useLoginMutation>;
export type LoginMutationResult = Apollo.MutationResult<LoginMutation>;
export type LoginMutationOptions = Apollo.BaseMutationOptions<LoginMutation, LoginMutationVariables>;
export const LogoutDocument = gql`
    mutation Logout {
  isLoggedOut: logout
}
    `;
export type LogoutMutationFn = Apollo.MutationFunction<LogoutMutation, LogoutMutationVariables>;

/**
 * __useLogoutMutation__
 *
 * To run a mutation, you first call `useLogoutMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLogoutMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [logoutMutation, { data, loading, error }] = useLogoutMutation({
 *   variables: {
 *   },
 * });
 */
export function useLogoutMutation(baseOptions?: Apollo.MutationHookOptions<LogoutMutation, LogoutMutationVariables>) {
        return Apollo.useMutation<LogoutMutation, LogoutMutationVariables>(LogoutDocument, baseOptions);
      }
export type LogoutMutationHookResult = ReturnType<typeof useLogoutMutation>;
export type LogoutMutationResult = Apollo.MutationResult<LogoutMutation>;
export type LogoutMutationOptions = Apollo.BaseMutationOptions<LogoutMutation, LogoutMutationVariables>;