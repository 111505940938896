import QueueLink from "apollo-link-queue";
import { ApolloClient, ApolloLink, InMemoryCache, split } from "@apollo/client";
import { BatchHttpLink } from "@apollo/client/link/batch-http";
import { RetryLink } from "@apollo/client/link/retry";
import { WebSocketLink } from "@apollo/client/link/ws";
import { createPersistedQueryLink } from "@apollo/client/link/persisted-queries";
import { getMainDefinition } from "@apollo/client/utilities";
import { sha256 } from "crypto-hash";

import { GRAPHQL_URL } from "./constants";

const retryLink = new RetryLink();

const batchLink = new BatchHttpLink({
  credentials: "include",
  uri: GRAPHQL_URL,
});

const wsLink = new WebSocketLink({
  uri: GRAPHQL_URL.replace("http", "ws"),
  options: {
    reconnect: true,
  },
});

const splitLink = split(
  ({ query }) => {
    const definition = getMainDefinition(query);
    return (
      definition.kind === "OperationDefinition" &&
      definition.operation === "subscription"
    );
  },
  wsLink,
  batchLink
);

const persistedLink = createPersistedQueryLink({ sha256 });
const offlineLink = new QueueLink();

window.addEventListener("online", () => offlineLink.open());
window.addEventListener("offline", () => offlineLink.close());

const cache = new InMemoryCache({
  typePolicies: {
    Query: {
      fields: {
        findManyOrder: {
          keyArgs: ["type"],
          merge(existing = [], incoming, { args, readField }) {
            if (args?.cursor?.id) {
              return [...existing, ...incoming];
            }

            return incoming;
          },
        },
      },
    },
  },
});

export const client = new ApolloClient({
  cache,
  credentials: "include",
  link: ApolloLink.from([persistedLink, retryLink, offlineLink, splitLink]),
});
