import { Outlet } from "react-router-dom";
import { Suspense } from "react";

import { Fallback } from "../Fallback";
import { Role } from "../../generated/graphql";
import { Sidebar } from "../Sidebar";
import { SlimDashboard } from "../SlimDashboard";
import { useAuth } from "../../AuthContext";

export const Dashboard: React.FC = () => {
  const { isLoggedIn, currentUser } = useAuth();

  if (isLoggedIn && currentUser?.role === Role.User) {
    return <SlimDashboard />;
  }

  return (
    <div className="fixed inset-0 flex w-screen h-screen overflow-hidden">
      <Sidebar />
      <main className="relative flex-1 max-h-full overflow-y-auto">
        <Suspense fallback={<Fallback />}>
          <Outlet />
        </Suspense>
      </main>
    </div>
  );
};
